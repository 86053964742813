import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';
import {
    ActionButtonContainer,
    MainActionButton,
    MobileMenuContainer,
    PopOver,
    PopupMenuContainer,
    RegisterButton,
    primaryButtonStyle,
    secondaryButtonStyle,
    CloseMenuButton,
    MenuOptionsWithHover,
    OptionsContainer,
    MenuPerfil,
} from './ActionButton.styled';
import { AccessPermissionContext } from 'core/context/AccessPermissionContext';
import { GTM_Menu } from 'components/GoogleTagManager';
import { useOutsideRef } from 'hooks/useOutsideRef';
import { CheckedGoToContext } from 'pages/painelSocioemocional/home/context/CheckedGoToContext';
import ModalCheckGoToPage from 'components/modal/ModalCheckGoToPage';
import { Avatar, Button, Popover, Skeleton } from 'antd';
import { KeycloakService } from 'core/http/service/Keycloak.service';
import UsuarioDTO from 'models/UsuarioDTO';
import { useDispatch } from 'react-redux';
import PerfilEditDTO from 'models/perfil/PerfilEditDTO';
import { ProfileService } from 'core/http/service/Profile.service';
import ImageProfile from 'pages/editProfile/ImageProfile';
import { PreviewImage, PreviewImageHeader } from 'pages/editProfile/ImageProfile.styled';
import { MdOutlineKeyboardArrowDown, MdOutlineLogout, MdOutlineViewKanban } from 'react-icons/md';
import useUserData from 'hooks/useUserData';
import { TbUserEdit } from 'react-icons/tb';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { RoleEnum } from 'enums/RoleEnum';
import LogoAdmin from '../../../images/logo/helmet.svg';
import MeuPainel from '../components/MeuPainel';
import MeuPerfil from '../components/MeuPerfil';

interface IActionButton {
    close?: (value: boolean) => void;
}

const kcService = new KeycloakService();
const service = new ProfileService();

const ActionButton: FunctionComponent<IActionButton> = ({ ...props }: IActionButton) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [notImagem, setNotImagem] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [cargo, setCargo] = useState<string>('');
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const isLoggedin: Boolean = useAppSelector(state => state.profileReducer.isLoggedin);
    const [userAdmin, setUserAdmin] = useState<boolean>(false);

    const [openMeuPainel, setOpenMeuPainel] = useState<boolean>(false);
    const [openMeuPerfil, setOpenMeuPerfil] = useState<boolean>(false);

    const { loadData } = useUserData();

    const loadUser = async () => {
        if (isLoggedin) {
            const response: any = await kcService.getUsuarioLogado();
            if (response.status === 200) {
                const responseUsuario = new UsuarioDTO().fromJSON(response.data);
                const { perfilUsuario } = await loadData();
                if (responseUsuario) {
                    let { data } = await service.getUserEdit(responseUsuario.username);
                    const dados = new PerfilEditDTO().fromJSON(data);
                    setPreviewImage(dados.imageBase64);
                    setFirstName(dados.nome);
                    setLastName(dados.sobrenome);
                    setCargo(perfilUsuario?.ocupacao ?? '');

                    if (!dados.imageBase64) {
                        setNotImagem(true);
                    }
                }
            }
        }
    };

    const realodInfos = async (nome: string, sobrenome: string) => {
        setFirstName(nome);
        setLastName(sobrenome);

        const { perfilUsuario } = await loadData();
        let { data } = await service.getUserEdit(perfilUsuario?.username ?? '');
        const dados = new PerfilEditDTO().fromJSON(data);
        setPreviewImage(dados.imageBase64);

        if (!dados.imageBase64) {
            setNotImagem(true);
        }
    };

    useEffect(() => {
        loadUser();
    }, []);

    useEffect(() => {
        setUserAdmin(usuarioLogado.roles.includes(RoleEnum.Administrador));
    }, [usuarioLogado]);

    const { isVerifyGoToPage, showModal, setShowModal, goToPage } = useContext(CheckedGoToContext.Context);

    const history = useHistory();
    const { keycloak } = useKeycloak();

    const logout = async () => {
        isVerifyGoToPage('/logout');
    };

    const gotoEditPage = () => {
        isVerifyGoToPage('/editarPerfil');
    };

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    const register = () => {
        const url = keycloak.createRegisterUrl({
            redirectUri: window.location.origin + '/login',
        });
        location.assign(url);
    };

    const login = () => {
        history.push('/login');
    };

    const isMobile = () => {
        return IsCustomMaxWidth(1250);
    };

    const popOverRef = useRef(null);
    useOutsideRef(popOverRef, () => {
        setOpenMenu(false);
    });

    const desktopActionButton = () => {
        return (
            <>
                <MeuPainel open={openMeuPainel} onClose={() => setOpenMeuPainel(false)} />
                <MeuPerfil
                    open={openMeuPerfil}
                    onClose={() => setOpenMeuPerfil(false)}
                    reloadInfo={(nome, sobrenome) => realodInfos(nome, sobrenome)}
                />
                <ActionButtonContainer>
                    {!keycloak.authenticated ? (
                        <div style={{ marginLeft: '50px' }}>
                            <MainActionButton
                                onClick={() => {
                                    GTM_Menu('menu_header', 'login_btn');
                                    login();
                                }}
                            >
                                Entrar
                            </MainActionButton>
                            <Button
                                type="link"
                                onClick={() => {
                                    GTM_Menu('menu_header', 'registrar_btn');
                                    register();
                                }}
                            >
                                Primeiro acesso?
                            </Button>
                            {/* <RegisterButton
                            onClick={() => {
                                GTM_Menu('menu_header', 'registrar_btn');
                                register();
                            }}
                        >
                            Cadastre-se aqui
                        </RegisterButton> */}
                        </div>
                    ) : (
                        <Popover
                            placement="bottom"
                            zIndex={1000}
                            content={
                                <MenuPerfil.Opcoes.Container>
                                    <MenuPerfil.Opcoes.Content
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'editar_perfil_btn');
                                            setOpenMeuPerfil(true);
                                        }}
                                    >
                                        <TbUserEdit size={22} /> Meu Perfil
                                    </MenuPerfil.Opcoes.Content>
                                    <MenuPerfil.Opcoes.Content onClick={() => setOpenMeuPainel(true)}>
                                        <MdOutlineViewKanban size={22} /> Meu Painel
                                    </MenuPerfil.Opcoes.Content>
                                    <MenuPerfil.Opcoes.Content
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'logout_btn');
                                            logout();
                                        }}
                                    >
                                        <MdOutlineLogout size={22} /> Sair
                                    </MenuPerfil.Opcoes.Content>
                                </MenuPerfil.Opcoes.Container>
                            }
                            title="Opções"
                        >
                            <MenuPerfil.Container>
                                <MenuPerfil.Content>
                                    {previewImage || userAdmin || notImagem ? (
                                        <Avatar src={userAdmin ? LogoAdmin : previewImage} size={50} shape={userAdmin ? 'square' : 'circle'}>
                                            {notImagem && firstName.charAt(0).toUpperCase()}
                                        </Avatar>
                                    ) : (
                                        <Skeleton.Avatar size={50} shape="circle" />
                                    )}
                                    {firstName || userAdmin ? (
                                        <MenuPerfil.Column>
                                            {userAdmin ? (
                                                <>
                                                    <MenuPerfil.Name>Inst. Ayrton Senna</MenuPerfil.Name>
                                                    <MenuPerfil.Cargo>Administrador</MenuPerfil.Cargo>
                                                </>
                                            ) : (
                                                <>
                                                    <MenuPerfil.Name>{firstName + ' ' + lastName}</MenuPerfil.Name>
                                                    <MenuPerfil.Cargo>{cargo}</MenuPerfil.Cargo>
                                                </>
                                            )}
                                        </MenuPerfil.Column>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                            <Skeleton.Input active size={'small'} />
                                            <Skeleton.Input active size={'small'} />
                                        </div>
                                    )}

                                    <MdOutlineKeyboardArrowDown size={22} />
                                </MenuPerfil.Content>
                            </MenuPerfil.Container>

                            {/* <MainActionButton onClick={toggleMenu}>Opções</MainActionButton>
                        {openMenu && (
                            <PopOver ref={popOverRef} style={{ top: '65px' }}>
                                <OptionsContainer>
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'editar_perfil_btn');
                                            gotoEditPage();
                                        }}
                                    >
                                        Editar perfil
                                    </MenuOptionsWithHover>
                                    <MenuOptionsWithHover
                                        onClick={() => {
                                            GTM_Menu('menu_header', 'logout_btn');
                                            logout();
                                        }}
                                    >
                                        Sair da humane
                                    </MenuOptionsWithHover>
                                </OptionsContainer>
                            </PopOver>
                        )} */}
                        </Popover>
                    )}
                </ActionButtonContainer>
            </>
        );
    };

    const mobileActionButton = () => {
        return (
            <ActionButtonContainer>
                <MeuPainel open={openMeuPainel} onClose={() => setOpenMeuPainel(false)} />
                <MeuPerfil
                    open={openMeuPerfil}
                    onClose={() => setOpenMeuPerfil(false)}
                    reloadInfo={(nome, sobrenome) => realodInfos(nome, sobrenome)}
                />
                {!keycloak.authenticated ? (
                    <MobileMenuContainer>
                        <RegisterButton
                            style={primaryButtonStyle()}
                            onClick={() => {
                                GTM_Menu('menu_header', 'registrar_btn_mobile');
                                register();
                            }}
                        >
                            Cadastre-se
                        </RegisterButton>
                        <MainActionButton
                            style={secondaryButtonStyle()}
                            onClick={() => {
                                GTM_Menu('menu_header', 'login_btn_mobile');
                                login();
                            }}
                        >
                            Entrar
                        </MainActionButton>
                        <CloseMenuButton
                            onClick={() => {
                                if (props.close) {
                                    props.close(false);
                                }
                            }}
                        >
                            Fechar menu
                        </CloseMenuButton>
                    </MobileMenuContainer>
                ) : (
                    <MobileMenuContainer>
                        <MenuPerfil.Container style={{ marginLeft: 0 }}>
                            <MenuPerfil.Content style={{ flexDirection: 'column' }}>
                                {previewImage || userAdmin || notImagem ? (
                                    <Avatar src={userAdmin ? LogoAdmin : previewImage} size={50} shape={userAdmin ? 'square' : 'circle'}>
                                        {notImagem && firstName.charAt(0).toUpperCase()}
                                    </Avatar>
                                ) : (
                                    <Skeleton.Avatar size={50} shape="circle" />
                                )}
                                {firstName || userAdmin ? (
                                    <MenuPerfil.Column>
                                        {userAdmin ? (
                                            <>
                                                <MenuPerfil.Name style={{ color: 'white' }}>Inst. Ayrton Senna</MenuPerfil.Name>
                                                <MenuPerfil.Cargo style={{ color: 'white' }}>Administrador</MenuPerfil.Cargo>
                                            </>
                                        ) : (
                                            <>
                                                <MenuPerfil.Name style={{ color: 'white' }}>{firstName + ' ' + lastName}</MenuPerfil.Name>
                                                <MenuPerfil.Cargo style={{ color: 'white' }}>{cargo}</MenuPerfil.Cargo>
                                            </>
                                        )}
                                    </MenuPerfil.Column>
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                        <Skeleton.Input active size={'small'} />
                                        <Skeleton.Input active size={'small'} />
                                    </div>
                                )}
                            </MenuPerfil.Content>
                        </MenuPerfil.Container>
                        <RegisterButton
                            style={{ ...primaryButtonStyle(), marginLeft: 0 }}
                            onClick={() => {
                                GTM_Menu('menu_header', 'meu_painel_btn_mobile');
                                setOpenMenu(false);
                                setOpenMeuPainel(true);
                            }}
                        >
                            Meu Painel
                        </RegisterButton>
                        <RegisterButton
                            style={{ ...primaryButtonStyle(), marginLeft: 0 }}
                            onClick={() => {
                                GTM_Menu('menu_header', 'editar_perfil_btn_mobile');
                                setOpenMeuPerfil(true);
                            }}
                        >
                            Editar perfil
                        </RegisterButton>
                        <MainActionButton
                            style={secondaryButtonStyle()}
                            onClick={() => {
                                GTM_Menu('menu_header', 'logout_btn_mobile');
                                logout();
                            }}
                        >
                            Sair da humane
                        </MainActionButton>
                        <CloseMenuButton
                            onClick={() => {
                                if (props.close) {
                                    props.close(false);
                                }
                            }}
                        >
                            Fechar menu
                        </CloseMenuButton>
                    </MobileMenuContainer>
                )}
                <ModalCheckGoToPage showModal={showModal} setShowModal={setShowModal} goToPage={goToPage} />
            </ActionButtonContainer>
        );
    };

    return <>{isMobile() ? mobileActionButton() : desktopActionButton()}</>;
};

export default ActionButton;
