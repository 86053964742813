import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Colors from 'enums/Colors';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { ComponentMarkText } from 'models/componentInteraction/ComponentMarkText';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';

const Styled = {
    Container: styled.div`
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing: 0%;
        color: ${Colors.Blue};
    `,

    RowBetween: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    SubTitle: styled.h2`
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: 0%;
        color: ${Colors.Blue};
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,

    TextMark: {
        Container: styled.div<{ isVisible: boolean; delay: number }>`
            display: flex;
            gap: 16px;
            min-height: 80px;
            width: 668px;
            opacity: 0;
            transform: translateX(50px);
            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
            transition-delay: ${({ delay }) => delay}ms;

            ${({ isVisible }) => isVisible && `
                opacity: 1;
                transform: translateX(0);
            `}
        `,

        MarkNumber: styled.div<{ color: string }>`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px !important;
            height: 35px !important;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background-color: ${({ color }) => (color ? color : Colors.Grey10)};
            font-family: 'Barlow';
            font-weight: 600;
            font-size: 18px;
            color: ${({ color }) => (color !== Colors.Grey10 ? Colors.White : '')};
        `,

        Bullet: styled.div<{ color: string }>`
            background-color: ${({ color }) => (color ? color : Colors.Grey10)};
            width: 35px !important;
            height: 35px !important;
            aspect-ratio: 1/1;
            border-radius: 50%;
            padding: 10px;
            gap: 16px;
        `,

        Paragraf: styled.p`
            font-family: 'Barlow';
            font-weight: 400;
            font-size: 18px;
            line-height: 25.2px;
            letter-spacing: 0%;
        `,
    },
};

interface ITextMark {
    isBullet: boolean;
    textMark: ComponentMarkText;
    color: string;
    delay: number;
    isVisible: boolean;
}

const TextMark = ({ isBullet, textMark, color, delay, isVisible }: ITextMark) => {
    return (
        <Styled.TextMark.Container isVisible={isVisible} delay={delay}>
            {!isBullet ? (
                <Styled.TextMark.MarkNumber color={color}>{textMark.ordem}</Styled.TextMark.MarkNumber>
            ) : (
                <Styled.TextMark.Bullet color={color} />
            )}
            <TextDangerouslySetInnerHtml style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: textMark.text }} />
        </Styled.TextMark.Container>
    );
};

interface IComponentMarkPublic {
    dados: ComponentInteractionModel;
}

const ComponentMarkPublic = ({ dados }: IComponentMarkPublic) => {
    const [visibleIndexes, setVisibleIndexes] = useState<number[]>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const totalItems = dados.marks?.marks.length || 0;

                        // Ativar um por um com delay diferente para cada um
                        dados.marks?.marks.forEach((_, index) => {
                            setTimeout(() => {
                                setVisibleIndexes((prev) => [...prev, index]);
                            }, index * 300); // Delay progressivo de 300ms para cada item
                        });

                        observer.disconnect(); // Evita chamar novamente após a primeira ativação
                    }
                });
            },
            { threshold: 0.2 } // Ativa quando 20% do container entra na tela
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => observer.disconnect(); // Cleanup
    }, [dados]);

    return (
        <Styled.Container ref={containerRef}>
            {dados.marks &&
                dados.marks.marks.map((text, i) => (
                    <TextMark
                        key={i}
                        color={dados.cor ?? Colors.Grey10}
                        isBullet={dados.marks?.isBullet ?? false}
                        textMark={text}
                        delay={i * 300} // Delay progressivo
                        isVisible={visibleIndexes.includes(i)} // Ativa apenas os visíveis
                    />
                ))}
        </Styled.Container>
    );
};

export default ComponentMarkPublic;
