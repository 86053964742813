import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { hotjar } from "react-hotjar";
import { isProduction } from "constants/EnvironmentData";

function TrackHotjar() {
    const location = useLocation();

    useEffect(() => {
        if (isProduction()) {
            if (!hotjar.initialized()) {
                hotjar.initialize({id: 2903374, sv: 6}); 
            }
            hotjar.stateChange(location.pathname); 
        }
    }, [location]);

    return null;
}

export default TrackHotjar;
