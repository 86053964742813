import { useEffect, useState } from 'react';
import styled from 'styled-components';
import React from 'react';
import Colors from 'enums/Colors';
import { MdAdd, MdClose, MdDelete, MdDeleteForever } from 'react-icons/md';
import ComponentHostpotModel from 'models/componentInteraction/ComponentHostPot';
import { Popover } from 'antd';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import { FAD_Button_Outline } from 'pages/ferramentaAutoria/components/FadButtons';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { ComponentInteractionAnexoService } from 'core/http/service/componetInteraction/ComponentInteractionAnexo.service';
import { ComponentInteractionAnexo } from 'models/componentInteraction/ComponentInteractionAnexo';
import { ComponentHostpotService } from 'core/http/service/componetInteraction/ComponentHostpot.service';

const Container = styled.div`
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ImageContainer = styled.div`
    position: relative;

    border: 3px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    max-width: 920px;
    width: 100%;
`;

const StyledImage = styled.img`
    width: 100%;
`;

const HotspotButton = styled.button`
    position: absolute;
    background-color: ${Colors.White};
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 2px solid ${Colors.Blue};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; /* Remove o padding excessivo */
`;

interface IComponentHostPotAdmin {
    dados: ComponentInteractionModel;
    handleAttComponent: (c: ComponentInteractionModel) => void;
    hotspots: ComponentHostpotModel[];
    setHotspots: (hot: ComponentHostpotModel[]) => void;
}

const ComponentHostPotAdmin = ({ dados, handleAttComponent, hotspots, setHotspots }: IComponentHostPotAdmin) => {
    const [image, setImage] = useState<string | null>(dados.anexo?.base64 ?? '');
    const [loadingHotspots, setLoadingHotspots] = useState<{ [key: number]: boolean }>({});
    const [loadedHotspots, setLoadedHotspots] = useState<{ [key: number]: boolean }>({});

    const handleOpenPopover = (hotspotCodigo: number) => {
        // Se o hotspot já foi carregado antes, não ativa o loading novamente
        if (loadedHotspots[hotspotCodigo]) return;

        setLoadingHotspots(prev => ({ ...prev, [hotspotCodigo]: true }));

        setTimeout(() => {
            setLoadingHotspots(prev => ({ ...prev, [hotspotCodigo]: false }));
            setLoadedHotspots(prev => ({ ...prev, [hotspotCodigo]: true })); // Marca como carregado
        }, 5000);
    };

    const [possibilityNewHotSpot, setPossibilityNewHotSpot] = useState<boolean>(false);

    //service
    const componentInteractionAnexoService = new ComponentInteractionAnexoService();
    const serviceHostpot = new ComponentHostpotService();

    const uploadedImagem = async (img: string) => {
        const anexo = new ComponentInteractionAnexo().fromJSON({
            id: dados.anexo?.id ?? -1,
            filepath: dados.anexo?.filepath ?? '',
            codigoComponent: dados.id,
            base64: img,
        });

        const { data, status } = await componentInteractionAnexoService.insertAnexo(anexo, dados.id);

        if (status === 200) {
            handleAttComponent({ ...dados, anexo: data } as ComponentInteractionModel);
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setImage(e.target?.result as string);
                uploadedImagem(e.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTextHostPot = (index: number, value: string) => {
        const hotspotSelected = hotspots.map((h, i) => (i === index ? { ...h, text: value } : h)) as ComponentHostpotModel[];

        setHotspots(hotspotSelected);
    };

    const handleImageClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!image || !possibilityNewHotSpot) return;

        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const id = Array.from({ length: 8 }, () => Math.floor(Math.random() * 10)).join('');

        const novoHostPot = new ComponentHostpotModel().fromJSON({ codigo: parseInt(id), text: '', x: x, y: y });

        const comp2 = { ...dados } as ComponentInteractionModel;
        comp2.hostpots = [...hotspots, novoHostPot];

        handleAttComponent(comp2);

        setPossibilityNewHotSpot(false);
    };

    const removeHotspot = async (id: number) => {
        await serviceHostpot.deleteById(id);

        const listHost = hotspots.filter((hotspot: ComponentHostpotModel) => hotspot.codigo != id);
        const comp2 = { ...dados } as ComponentInteractionModel;
        comp2.hostpots = listHost;

        handleAttComponent(comp2);
    };

    useEffect(() => {
        setImage(dados.anexo?.base64 ?? '');
        setHotspots(dados.hostpots ?? []);
    }, [dados]);

    return (
        <Container>
            <ImageContainer
                style={{ borderColor: possibilityNewHotSpot ? Colors.Blue : '' }}
                onClick={e => {
                    handleImageClick(e);
                }}
            >
                {image && <StyledImage src={image} alt="Uploaded" />}
                {hotspots.map((hotspot, index) => (
                    <Popover
                        key={index}
                        overlayStyle={{ width: '500px' }}
                        content={
                            <div style={{ position: 'relative' }}>
                                <MdDeleteForever
                                    style={{ position: 'absolute', left: '90%', top: '-30px' }}
                                    color={Colors.Red}
                                    size={22}
                                    cursor={'pointer'}
                                    onClick={() => removeHotspot(hotspot.codigo)}
                                />

                                {loadingHotspots[hotspot.codigo] ? (
                                    <>Carregando...</>
                                ) : (
                                    <ReactQuill
                                        style={{ width: '460px', maxHeight: '200px', overflowY: 'auto' }}
                                        theme="snow"
                                        placeholder="Digite aqui a descrição do cartão frontal."
                                        modules={editorConfig.modules2}
                                        formats={editorConfig.formats2}
                                        value={hotspot.text}
                                        onChange={val => handleTextHostPot(index, val)}
                                    />
                                )}
                            </div>
                        }
                        title="Conteúdo Hotspot"
                        trigger="click"
                        onOpenChange={visible => {
                            if (visible) handleOpenPopover(hotspot.codigo);
                        }}
                    >
                        <HotspotButton
                            style={{ left: hotspot.x, top: hotspot.y }}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <MdAdd color={Colors.Blue} />
                        </HotspotButton>
                    </Popover>
                ))}
            </ImageContainer>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            <FAD_Button_Outline text="Adicionar um novo hotspot" onClick={() => setPossibilityNewHotSpot(true)} styles={{ width: 200 }} />
        </Container>
    );
};

export default ComponentHostPotAdmin;
