import React from 'react';
import Styled from './Apresentation.styled';
import Imagem from '../../assets/foto_01.svg';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import keycloak from 'core/config/keycloak';
import token from '../../assets/token.svg';

interface IApresentation {
    setModalTokenOpen: (bool: boolean) => void;
}

const Apresentation = ({ setModalTokenOpen }: IApresentation) => {
    const isMobile = IsMobileBiggerWidth();

    return (
        <Styled.Container isMobile={isMobile}>
            <Styled.ImgBanner src={Imagem} isMobile={isMobile}></Styled.ImgBanner>
            <Styled.Content isMobile={isMobile}>
                <Styled.TextContainer isMobile={isMobile}>
                    <Styled.Title isMobile={isMobile}>{`Soluções Educacionais`}</Styled.Title>
                    <Styled.Description isMobile={isMobile}>
                        {`
                    O Instituto Ayrton Senna oferece uma oportunidade única para as redes de ensino e seus educadores lidarem com os desafios diários.
                `}
                    </Styled.Description>
                    <Styled.Description isMobile={isMobile}>
                        {`
                    Com uma equipe dedicada, sua rede terá acesso a soluções embasadas em evidências científicas e décadas de experiência na educação.
                    Imagine ter acesso a ferramentas comprovadas que podem melhorar o desempenho dos alunos e promover o desenvolvimento profissional dos educadores.
                `}
                    </Styled.Description>
                </Styled.TextContainer>
            </Styled.Content>
            {/* //<Styled.Img isMobile={IsMobileMaxWidth()} src={Imagem} /> */}
            {keycloak.authenticated && (
                <Styled.TokenButton
                    isMobile={isMobile}
                    onClick={() => {
                        keycloak.authenticated && setModalTokenOpen(true);
                    }}
                >
                    <img src={token} />
                    {!isMobile && (
                        <div>
                            <p style={{ fontSize: '17px' }}>Possui o código?</p> <p style={{ fontSize: '17px' }}>Clique aqui!</p>
                        </div>
                    )}
                </Styled.TokenButton>
            )}
        </Styled.Container>
    );
};

export default Apresentation;
