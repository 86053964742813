import React from 'react';
import Styled from './HeaderSolution.styled';
import LinhaAmarela from './../../assets/linhaamarela.svg';
import LinhaBranca from './../../assets/WhiteBgBanner.svg';
import styled from 'styled-components';

export interface IHeaderSolution {
    img: string;
    position?: string;
}

const CurvedDiv = styled.img`
    position: absolute;
    width: 100%;
    transform: rotate(0deg);
    top: 225px;

    @media screen and (max-width: 1600px) {
        top: 300px !important;
    }

    @media screen and (max-width: 1190px) {
        top: 160px !important;
    }

    @media screen and (max-width: 740px) {
        top: 120px !important;
    }

    @media screen and (max-width: 440px) {
        top: 130px !important;
    }
`;
const ImgWhite = styled.img`
    position: absolute;
    width: 100%;
    top: 265px;

    @media screen and (max-width: 1600px) {
        top: 320px !important;
    }

    @media screen and (max-width: 1190px) {
        top: 180px !important;
    }

    @media screen and (max-width: 740px) {
        top: 130px !important;
    }
    @media screen and (max-width: 440px) {
        top: 140px !important;
    }
`;

const HeaderSolution = ({ img, position }: IHeaderSolution) => {
    return (
        <div>
            <Styled.HeaderContainer>
                <Styled.Img style={{ zIndex: 1 }} src={img} positionImage={position}></Styled.Img>
                <CurvedDiv style={{ zIndex: 2, left: 2 }} src={LinhaAmarela} alt="" />
                <ImgWhite style={{ zIndex: 3, position: 'absolute', top: 250 }} src={LinhaBranca} alt="" />
            </Styled.HeaderContainer>
        </div>
    );
};

export default HeaderSolution;
