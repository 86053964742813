import Colors from 'enums/Colors';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const StyledInPractice = {
    Container: styled.div<{ isMobile: boolean }>`
        width: 100%;
        max-width: 1227px;
        display: flex;
        flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
        align-items: ${props => (props.isMobile ? 'center' : 'normal')};
        padding-top: ${props => (props.isMobile ? '30px' : '70px')};
        overflow: hidden;

        @media screen and (max-width: 1350px) and (min-width: 1200px) {
            padding-left: 15px;
        }
    `,

    ColorContainer: styled.div`
        background-color: ${Colors.BackGroundGrey};
        width: 100%;
        display: flex;
        justify-content: center;
    `,

    DescriptionContainer: styled.div`
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    CarouselContainer: styled.div<{ isMobile: boolean }>`
        width: ${props => (props.isMobile ? '80%' : '100%')};
        display: flex;
        flex-direction: row;
        margin-top: ${props => (props.isMobile ? '30px' : '0px')};
        justify-content: ${props => (props.isMobile ? 'center' : 'unset')};

        @media screen and (max-width: 1600px) {
            width: 80%;
            padding-top: 0px;
        }
        @media screen and (max-width: 1090px) {
            flex-direction: column-reverse;
            padding-top: 0px;
        }
    `,

    ImageInPractice: styled.img`
        max-width: 447px;
    `,

    ImageContainer: styled.div`
        display: flex;
    `,

    credits: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        color: ${Colors.Blue};
        margin-top: 20px;
        margin-bottom: 20px;
    `,

    Paging: styled.img`
        object-fit: cover;
        width: 100%;
    `,

    CardInPractice: {
        Container: styled.div`
            position: relative;
            object-fit: cover;
            border-radius: 20px;

            /* @media screen and (max-width: 1980px) {
                width: 700px;
            }

            @media screen and (max-width: 1500px) {
                width: 500px;
                height: 380px;
            }

            @media screen and (max-width: 1040px) {
                width: 600px;
            }

            @media screen and (max-width: 940px) {
                width: 500px;
            }

            @media screen and (max-width: 640px) {
                width: 450px;
                height: 280px;
            }

            @media screen and (max-width: 480px) {
                width: 100%;
                height: 230px;
            } */
        `,
    },

    Carrousel: styled.div`
        width: 50%;
        display: flex;
        height: 600px;
        margin-left: 120px;

        @media screen and (max-width: 1280px) {
            margin-left: 20px;
        }

        @media screen and (max-width: 1090px) {
            width: 100%;
            margin-left: 0px;
            height: auto;
            margin-bottom: 100px;
            margin-top: 20px;
        }
    `,

    CarouselCardContainer: styled.div<{ isMobile: boolean; bigSlick: boolean }>`
        width: 100%;
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .slick-dots {
            bottom: ${props => (props.isMobile ? '-100px' : props.bigSlick ? '-100px' : '-50px')};
            li {
                width: 60px;
                &.slick-active {
                    a img {
                        border: ${Colors.Blue} 2px solid; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }
    `,

    Description: styled.div`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;

        @media screen and (max-width: 1090px) {
            max-width: 80%;
        }
        @media screen and (max-width: 480px) {
            padding-left: 20px;
        }
    `,

    Img: styled.img`
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
        max-height: 425px;
    `,

    Play: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        padding: 20px;
        top: 45%;
        left: 45%;
        background-color: ${Colors.DeleteRed};
        width: 100px;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
            background-color: ${Colors.Red};
        }
    `,
};

export default StyledInPractice;
