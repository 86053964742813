import { Popover, Modal } from 'antd';
import Colors from 'enums/Colors';
import ComponentHostpotModel from 'models/componentInteraction/ComponentHostPot';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';
import { fetchS3ComponentInteraction } from 'hooks/StoreFiles';
import { MdAdd, MdClose, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FAD_Button_Outline } from 'pages/ferramentaAutoria/components/FadButtons';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import './hotspot.css';

interface IComponentHostpotPublic {
    dados: ComponentInteractionModel;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 22px 0px;
`;

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 920px;
`;

const StyledImage = styled.img`
    width: 100%;
`;

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(2.5);
        opacity: 0;
    }
    100% {
        transform: scale(5.5);
        opacity: 0;
    }
`;

const HotspotButton = styled.button<{ $isActive: boolean; $hasBeenClicked: boolean }>`
    position: absolute;
    background-color: ${({ $isActive }) => ($isActive ? Colors.Blue : Colors.White)};
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 2px solid ${({ $isActive }) => ($isActive ? Colors.White : Colors.Blue)};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    ${({ $hasBeenClicked }) =>
        !$hasBeenClicked &&
        css`
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: ${Colors.White};
                opacity: 0.5;
                animation: ${pulseAnimation} 1.5s infinite ease-out;
            }
        `}
`;

const ComponentHostpotPublic = ({ dados }: IComponentHostpotPublic) => {
    const [hotspots, setHotspots] = useState<ComponentHostpotModel[]>([]);
    const [clickedHotspots, setClickedHotspots] = useState<Set<number>>(new Set());
    const [activeHotspot, setActiveHotspot] = useState<number | null>(null);
    const [indexActiveMobile, setIndexActiveHostpot] = useState<number | null>(null);
    const [modalContent, setModalContent] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setHotspots(dados.hostpots ?? []);

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            if (imageRef.current) {
                setImageDimensions({
                    width: imageRef.current.offsetWidth,
                    height: imageRef.current.offsetHeight,
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Chama a função uma vez para definir as dimensões iniciais

        return () => window.removeEventListener('resize', handleResize);
    }, [dados]);

    const handleHotspotClick = (index: number) => {
        setClickedHotspots(prev => new Set(prev).add(index));
        setActiveHotspot(index);
    };

    const getRelativePosition = (hotspot: ComponentHostpotModel) => {
        const { width, height } = imageDimensions;
        if (width === 0 || height === 0) return { left: 0, top: 0 };

        const left = (hotspot.x / width) * 100;
        const top = (hotspot.y / height) * 100;
        return { left: `${left}%`, top: `${top}%` };
    };

    return (
        <Container>
            <ImageContainer>
                <StyledImage
                    ref={imageRef}
                    src={fetchS3ComponentInteraction(dados.anexo?.filepath ?? '')}
                    alt="Imagem hostpot"
                    onLoad={() => {
                        if (imageRef.current) {
                            setImageDimensions({
                                width: imageRef.current.offsetWidth,
                                height: imageRef.current.offsetHeight,
                            });
                        }
                    }}
                />
                {hotspots.map((hotspot, index) => {
                    const isActive = activeHotspot === index;
                    const hasBeenClicked = clickedHotspots.has(index);
                    const position = getRelativePosition(hotspot);

                    return isMobile ? (
                        <HotspotButton
                            key={index}
                            $isActive={isActive}
                            $hasBeenClicked={hasBeenClicked}
                            style={position}
                            onClick={() => setIndexActiveHostpot(index)}
                        >
                            <MdAdd color={isActive ? Colors.White : Colors.Blue} />
                        </HotspotButton>
                    ) : (
                        <Popover
                            key={index}
                            overlayStyle={{ width: '500px' }}
                            open={activeHotspot === index}
                            content={
                                <div style={{ position: 'relative' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '8px' }}>
                                        {index !== 0 && (
                                            <IoIosArrowBack
                                                className="arrows"
                                                size={24}
                                                cursor={'pointer'}
                                                onClick={() => setActiveHotspot(index - 1)}
                                            />
                                        )}
                                        {hotspots.length - 1 > index && (
                                            <IoIosArrowForward
                                                className="arrows"
                                                size={24}
                                                cursor={'pointer'}
                                                onClick={() => setActiveHotspot(index + 1)}
                                            />
                                        )}
                                    </div>
                                    <TextDangerouslySetInnerHtml
                                        style={{ maxHeight: 280, overflowY: 'scroll' }}
                                        dangerouslySetInnerHTML={{ __html: hotspot.text }}
                                    />
                                </div>
                            }
                            onOpenChange={open => {
                                if (!open) setActiveHotspot(null);
                            }}
                            title=""
                            trigger="click"
                        >
                            <HotspotButton
                                $isActive={isActive}
                                $hasBeenClicked={hasBeenClicked}
                                style={position}
                                onClick={e => {
                                    e.stopPropagation();
                                    handleHotspotClick(index);
                                }}
                            >
                                <MdAdd color={isActive ? Colors.White : Colors.Blue} />
                            </HotspotButton>
                        </Popover>
                    );
                })}
            </ImageContainer>

            {/* Modal para Mobile */}
            <Modal
    open={indexActiveMobile != null}
    footer={null}
    onCancel={() => setIndexActiveHostpot(null)}
    centered
    width="100vw"
    bodyStyle={{ height: '90vh', overflowY: 'auto', padding: '16px', paddingTop: '56px' }} // Adiciona espaço para o header fixo
>
    {indexActiveMobile != null && (
        <>
            {/* Header fixo com arrows */}
            <div 
                style={{ 
                    position: 'fixed', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    background: 'white', 
                    zIndex: 1000, 
                    padding: '16px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    height: 80,
                    gap: '8px', 
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)' 
                }}
            >
                {indexActiveMobile !== 0 && (
                    <IoIosArrowBack
                        className="arrows"
                        size={24}
                        cursor={'pointer'}
                        onClick={() => setIndexActiveHostpot(indexActiveMobile - 1)}
                    />
                )}
                {hotspots.length - 1 > indexActiveMobile && (
                    <IoIosArrowForward
                        className="arrows"
                        size={24}
                        cursor={'pointer'}
                        onClick={() => setIndexActiveHostpot(indexActiveMobile + 1)}
                    />
                )}
            </div>

            {/* Conteúdo do modal */}
            <TextDangerouslySetInnerHtml 
                dangerouslySetInnerHTML={{ __html: hotspots[indexActiveMobile].text ?? '' }} 
            />
        </>
    )}
</Modal>

        </Container>
    );
};

export default ComponentHostpotPublic;
