import { Alert, Button, ConfigProvider, FloatButton, GetRef, Input, message, Modal, Popconfirm, Tour, TourProps } from 'antd';
import { FcKey } from 'react-icons/fc';
import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { ToastError } from 'components/Toaster';
import keycloak from 'core/config/keycloak';
import useUserData from 'hooks/useUserData';
import { TokenPEService } from 'core/http/service/acessosExclusivos/TokenPE.service';
import TokenPE_DTO from 'models/acessoExclusivo/dtos/TokenPE_DTO';
import { stat } from 'fs';
import { useHistory } from 'react-router-dom';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { ListCards } from './MultiSolucoes';
import CartaoMultiSolucoes from './CartaoMultiSolucoes';
import Colors from 'enums/Colors';
import styled from 'styled-components';
import logoHumane from '../../assets/logoHumane.svg';
import { ButtonHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { MdClose } from 'react-icons/md';
import Select from 'components/Select';
import { ProfileService } from 'core/http/service/Profile.service';

const Styled = {
    Container: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
        gap: 0.5rem;
        padding: 10px;
        border-radius: 10;
    `,
};

interface IResgatedToken {
    valueModalToken: boolean;
    setHidenModalToken: (value: boolean) => void;
    hidenButtonIcon?: boolean;
    hidenModalToast?: boolean;
    openModalSolucoesExclusiva?: boolean;
    setOpenModalSolucoesExclusiva?: (value: boolean) => void;
}

const ResgatedToken = ({
    valueModalToken,
    setHidenModalToken,
    hidenButtonIcon,
    hidenModalToast,
    openModalSolucoesExclusiva,
    setOpenModalSolucoesExclusiva,
}: IResgatedToken) => {
    const { loadData } = useUserData();
    const history = useHistory();
    const ref1 = useRef<GetRef<typeof FloatButton>>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalConteudoExlusivo, setOpenModalConteudoExclusivo] = useState(false);
    const [openModalUsuarioEscola, setOpenModalUsuarioEscola] = useState(false);
    const [userSchools, setUserSchools] = useState<any[]>([]);
    const [notFoundSchool, setNotFoundSchool] = useState(false);
    const [schoolName, setSchoolName] = useState<string>('');
    const [schoolSelected, setSchoolSelected] = useState<any>({});
    const [token, setToken] = useState<string>('');
    const [solucoesExclusivas, setSolucoesExclusivas] = useState<TipoSolucoes[]>([]);

    const [isReloadOnClose, setIsReloadOnClose] = useState<boolean>(true);

    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const serviceToken = new TokenPEService();

    const profileService = new ProfileService();

    const is700x = IsCustomMaxWidth(700);

    useEffect(() => {
        if (openModalSolucoesExclusiva && solucoesExclusivas.length > 0) {
            setIsReloadOnClose(false);
            setOpenModalConteudoExclusivo(true);
        } else if (openModalSolucoesExclusiva && solucoesExclusivas.length === 0) {
            setOpenModal(true);
        }

        if (setOpenModalSolucoesExclusiva) {
            setOpenModalSolucoesExclusiva(false);
        }
    }, [openModalSolucoesExclusiva]);

    useEffect(() => {
        // Verifica se o cookie 'tourCompleted' existe
        const tourCompleted = Cookies.get('tourCompleted');
        if (!tourCompleted) {
            setOpen(true); // Se não existir, abre o tour
        }
    }, []); // Executa apenas uma vez ao montar o componente

    const success = (message: string) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const error = (message: string, delay?: number) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: delay,
        });
    };

    const handleFinish = () => {
        Cookies.set('tourCompleted', 'true', { expires: 3 });
        setOpen(false);
    };

    const handleUrl = (solucao: string) => {
        switch (solucao) {
            case 'Diálogos Socioemocionais':
                return history.push('/exclusivo/dialogos-socioemocionais');
        }
    };

    const getUserSchools = async () => {
        if (keycloak.authenticated) {
            setLoading(true);

            const { data, status }: any = await profileService.findSchools();

            if (status === 200) {
                console.log(data);
                setUserSchools(data);
                setOpenModal(false);
                setOpenModalUsuarioEscola(true);
            } else {
                getSolucoesUser();
            }

            setOpenModal(false);
            setLoading(false);
        }
    };

    const getSolucoesUser = async () => {
        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                setSolucoesExclusivas(response.data);

                if (response.data.length > 1) {
                    setOpenModalConteudoExclusivo(true);
                } else {
                    const solucao = response.data[0].nome;

                    setTimeout(() => {
                        handleUrl(solucao);
                    }, 2000);
                }
            }
        }
    };
    const getSolucoesUserDados = async () => {
        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                setSolucoesExclusivas(response.data);
            }
        }
    };

    const handleOk = async () => {
        if (token.length === 0) return error('Campo vázio ou token inválido!');
        setLoading(true);

        const { usuarioLogado } = await loadData();

        if (usuarioLogado) {
            let tokenData = new TokenPE_DTO();
            tokenData.token = token;

            try {
                const { status, data } = await serviceToken.resgate(tokenData, usuarioLogado.username);

                if (status === 200) {
                    success('Código ativado com sucesso!');
                    getUserSchools();
                    setHidenModalToken(false);
                }

                if (status === 400) {
                    error('Você já ativou um código que está atrelado a permissão de acesso que você já possui.', 5);
                }

                if (status === 404) {
                    if (data.error === 'TOKEN_IS_INVALID') {
                        error('Você inseriu um código inválido', 5);
                    } else if (data.error === 'NOT_FIND_USER') {
                        error('Você não tem um perfil cadastrado na nossa plataforma, vamos te redirecionar para preencher o perfil.', 5);
                        setTimeout(() => {
                            history.push('/profile/informacoes');
                        }, 5000);
                    } else if (data.error === 'IS_EMPTY_CATEGORY') {
                        error('Este token não está atrelado em nenhuma permissão de acesso!', 5);
                    }
                }

                setToken('');
            } catch (error) {
                console.log('🚀 ~ handleOk ~ error:', error);
            }
        }

        setLoading(false);
    };

    const handleSaveUserSchool = async () => {
        if (notFoundSchool && schoolName.length === 0) return error('Informe uma escola!');
        if (!notFoundSchool && schoolSelected.schoolName?.length === 0) return error('Informe uma escola!');
        setLoading(true);

        const { usuarioLogado } = await loadData();

        if (usuarioLogado) {
            try {
                const name = notFoundSchool ? schoolName : schoolSelected.schoolName;
                const inep = notFoundSchool ? null : schoolSelected.schoolInep;

                const { status } = await profileService.saveUserSchool(name, inep);

                if (status === 200) {
                    getSolucoesUser();
                }
                getSolucoesUser();
                setOpenModalUsuarioEscola(false);
            } catch (error) {
                console.log('🚀 ~ handleOk ~ error:', error);
            }
        }
        setLoading(false);
    };

    const handleCancel = () => {
        setOpenModal(false);
        setHidenModalToken(false);
        setToken('');
    };
    const handleCancelConteudoExclusivo = () => {
        setOpenModalConteudoExclusivo(false);

        if (isReloadOnClose) {
            window.location.reload();
        }
    };

    const handleCancelSchool = () => {
        setOpenModalUsuarioEscola(false);
        window.location.reload();
    };

    useEffect(() => {
        setOpenModal(valueModalToken);
    }, [valueModalToken]);

    useEffect(() => {
        const handleScroll = () => {
            const tourCompleted = Cookies.get('tourCompleted');
            if (!tourCompleted) {
                Cookies.set('tourCompleted', 'true', { expires: 3 });
            }
            setOpen(false);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (openModal) {
            setOpen(false);
        }
    }, [openModal]);

    useEffect(() => {
        getSolucoesUserDados();
    }, []);

    return (
        <>
            {contextHolder}
            <Modal
                confirmLoading={loading}
                width={is700x ? '90%' : '50%'}
                title={'Acesse os Conteúdos Exclusivos:'}
                open={openModalConteudoExlusivo}
                footer
                okButtonProps={{
                    disabled: true,
                }}
                cancelText="Fechar"
                onCancel={handleCancelConteudoExclusivo}
            >
                <Styled.Container>
                    {ListCards.map((dados, index) => {
                        if (solucoesExclusivas.some(obj => dados.id.some((id) => id === obj.id))) {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <CartaoMultiSolucoes
                                        marginTop="20px"
                                        mySolucoesExclusivas={solucoesExclusivas}
                                        dados={dados}
                                        isButtonOculto={true}
                                    />
                                </div>
                            );
                        }
                    })}
                </Styled.Container>
            </Modal>
            <Modal confirmLoading={loading} onCancel={handleCancel} width={700} open={openModal} footer>
                <ConfigProvider
                    input={{
                        style: {
                            height: 72,
                            fontSize: 22,
                        },
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: is700x ? '' : '28px 66px' }}>
                        <span style={{ color: Colors.SoftBlue, fontWeight: 500, fontFamily: 'Barlow', fontSize: 16 }}>Acesso a plataforma</span>
                        <img src={logoHumane} alt="logo-humane" style={{ width: '176px' }} />
                        <p
                            style={{ maxWidth: '445px' }}
                            dangerouslySetInnerHTML={{
                                __html: `
                          <strong>Você que é nosso parceiro digite o código da sua rede de ensino para seguir</strong> adiante e explorar os materiais da solução educacional e outros conteúdos exclusivos. <br/><br/>
                             <strong>Tem dificuldades? </strong>Pergunte ao responsável da sua rede ou fale conosco para suporte.
                            `,
                            }}
                        />
                        <Input placeholder="Digite o código aqui." value={token} onChange={e => setToken(e.target.value)} size={'large'} />
                        <div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'center' }}>
                            <ButtonHomeV2 onClick={handleCancel} outline>
                                Não possuo código
                            </ButtonHomeV2>
                            <ButtonHomeV2 style={{ display: 'flex', alignItems: 'center' }} disabled={loading} onClick={handleOk}>
                                {loading ? <div className="loaderConteudo" /> : 'Ativar código'}
                                {loading && 'Aguarde'}
                            </ButtonHomeV2>
                        </div>
                    </div>
                </ConfigProvider>
            </Modal>
            <Modal confirmLoading={loading} onCancel={handleCancelSchool} width={700} open={openModalUsuarioEscola} footer>
                <ConfigProvider
                    input={{
                        style: {
                            height: 72,
                            fontSize: 22,
                        },
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: is700x ? '' : '28px 66px' }}>
                        <span style={{ color: Colors.SoftBlue, fontWeight: 500, fontFamily: 'Barlow', fontSize: 16 }}>Acesso a plataforma</span>
                        <img src={logoHumane} alt="logo-humane" style={{ width: '176px' }} />
                        <p
                            style={{ maxWidth: '445px' }}
                            dangerouslySetInnerHTML={{ __html: `Selecione abaixo o nome da escola na qual você atua:` }}
                        />
                        {!notFoundSchool ? (
                            <>
                                <Select
                                    itens={userSchools.map(dados => ({
                                        label: dados.name,
                                        value: dados.inep,
                                    }))}
                                    onSelected={selected => {
                                        setSchoolSelected({ schoolName: selected.label, schoolInep: selected.value });
                                    }}
                                    isSmall={false}
                                    width="100%"
                                />
                                <a
                                    onClick={() => setNotFoundSchool(true)}
                                    style={{
                                        color: Colors.DarkBlue3,
                                        fontWeight: 500,
                                        fontFamily: 'Barlow',
                                        fontSize: 16,
                                        textAlign: 'center',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Não encontrei minha escola
                                </a>
                            </>
                        ) : (
                            <Input
                                placeholder="Digite o nome da sua escola."
                                value={schoolName}
                                onChange={e => setSchoolName(e.target.value)}
                                size={'large'}
                            />
                        )}
                        <div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'center' }}>
                            <ButtonHomeV2 onClick={handleSaveUserSchool}>
                                {loading ? <div className="loaderConteudo" /> : 'Confirmar'}
                                {loading && 'Aguarde'}
                            </ButtonHomeV2>
                        </div>
                    </div>
                </ConfigProvider>
            </Modal>
            {false && keycloak.authenticated && !hidenModalToast && !openModal && (
                <>
                    <Popconfirm
                        placement="leftTop"
                        title={'Continue como Parceiro do Instituto'}
                        description={
                            <>
                                <MdClose
                                    size={22}
                                    style={{ position: 'relative', left: '95%', top: -20 }}
                                    onClick={handleFinish}
                                    cursor={'pointer'}
                                />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: ` Digite o código da sua rede de ensino para seguir adiante e explorar os materiais da <br /> solução educacional e outros conteúdos
                                            exclusivos. <br />
                                            <strong>Tem dificuldades?</strong> Pergunte ao responsável da sua rede ou fale conosco para suporte.
                                            <br /><br />
                                            `,
                                    }}
                                />
                                <Input
                                    style={{
                                        marginBottom: '20px',
                                    }}
                                    placeholder="Digite o código aqui."
                                    value={token}
                                    onChange={e => setToken(e.target.value)}
                                    size={'large'}
                                />
                            </>
                        }
                        okText="Ativar código de acesso"
                        open={open}
                        onOpenChange={visible => setOpen(visible)}
                        okButtonProps={{
                            onClick: () => {
                                handleOk();
                                handleFinish();
                            },
                        }}
                        cancelButtonProps={{
                            onClick: () => handleFinish(),
                        }}
                        cancelText="Não possuo um código de acesso"
                    >
                        {!hidenButtonIcon && (
                            <FloatButton
                                ref={ref1}
                                icon={<FcKey size={35} style={{ position: 'relative', right: 8 }} />}
                                type="primary"
                                onClick={() => setOpenModal(true)}
                                style={{
                                    insetInlineEnd: 20,
                                    insetBlockStart: 100,
                                    width: 60,
                                    height: 60,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                }}
                            />
                        )}
                    </Popconfirm>
                </>
            )}
        </>
    );
};

export default ResgatedToken;
