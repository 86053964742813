import React, { useEffect, useState } from 'react';
import Styled from './CarouselSolucoes.styled';
import Colors from 'enums/Colors';
import Slider from 'react-slick';
import { ListCards } from 'pages/solucoes/components/solucoes/MultiSolucoes';
import { ButtonHomeV2, ButtonLinkHomeV2 } from '../button/ButtonHomeV2.styled';
import { TypeCard } from 'pages/solucoes/components/solucoes/CartaoMultiSolucoes';
import { IoAdd } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import ResgatedToken from 'pages/solucoes/components/solucoes/ResgatedToken';
import keycloak from 'core/config/keycloak';
import { KeycloakLoginOptions } from 'keycloak-js';
import { Popconfirm } from 'antd';
import TagSolucoes from 'pages/solucoes/components/solucoes/TagSolucoes';
import Logo from './assets/logo.svg';
import { IsCustomMinWidth } from 'core/util/WindowSize';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
interface IArrowButton {
    next: boolean;
}

interface ICardCarousel {
    dados: TypeCard;
}

export const ButtonLink: React.FC<{ onClick: () => void; color?: Colors; colorHover?: Colors }> = ({ onClick, color, colorHover }) => {
    return (
        <ButtonLinkHomeV2 color={color} colorHover={colorHover} onClick={onClick} type="link">
            <IoAdd /> Informações
        </ButtonLinkHomeV2>
    );
};

const CardCarousel = ({ dados }: ICardCarousel) => {
    return (
        <Styled.CardCarousel>
            <Styled.ContainerTag>
                {dados.tag.map((tag, index) => (
                    <TagSolucoes key={index} type={tag} outline />
                ))}
            </Styled.ContainerTag>
            <Styled.CardContainer.Content>
                <Styled.CardContainer.ImgContainer>
                    <Styled.CardContainer.Img src={dados.img} />
                </Styled.CardContainer.ImgContainer>
                <Styled.CardContainer.Title>{dados.titulo}</Styled.CardContainer.Title>
                <Styled.CardContainer.Description>{dados.smallDescription}</Styled.CardContainer.Description>
            </Styled.CardContainer.Content>
        </Styled.CardCarousel>
    );
};

const CarouselSolucoes = () => {
    const history = useHistory();
    const [modalOpenToken, setOpenModalToken] = useState<boolean>(false);

    const [modalOpenMenuSolutionsExclusive, setModalMenuSolutionsExclusive] = useState<boolean>(false);

    const [userIsVinculatedSolutions, setUserVinculatedSolutions] = useState<boolean>(false);

    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();

    const validateAccessToken = (goTologin: boolean) => {
        const current = location.pathname;

        if (keycloak.authenticated) {
            setOpenModalToken && setOpenModalToken(true);
        } else {
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + current,
            };

            const _url = goTologin ? keycloak.createLoginUrl(options) : keycloak.createRegisterUrl(options);
            window.location.assign(_url);
        }
    };

    const fetchUserBySolucoesExclusive = async () => {
        if (keycloak.authenticated) {
            const { data, status } = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (status === 200) {
                const solutions: TipoSolucoes[] = data;

                if (solutions.length > 0) {
                    setUserVinculatedSolutions(true);
                }
            }
        }
    };

    const NextArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={true} onClick={props.onClick}>
                <Styled.SvgIcon>
                    {IsCustomMinWidth(600) && <circle r="28" transform="matrix(-1 0 0 1 28 28)" stroke={Colors.Yellow} />}
                    <path
                        d="M0.472656 26.5214L11.1593 15.0974L0.472656 3.6734L3.76266 0.164062L17.7627 15.0974L3.76266 30.0307L0.472656 26.5214Z"
                        transform={IsCustomMinWidth(600) ? 'translate(18, 12)' : ''}
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <Styled.ArrowButton next={false} onClick={props.onClick}>
                <Styled.SvgIcon>
                    {IsCustomMinWidth(600) && <circle r="28" transform="matrix(1 1.74846e-07 1.74846e-07 -1 28 28)" stroke={Colors.Yellow} />}
                    <path
                        d="M17.9931 26.5214L7.30646 15.0974L17.9931 3.6734L14.7031 0.164062L0.703125 15.0974L14.7031 30.0307L17.9931 26.5214Z"
                        transform={IsCustomMinWidth(600) ? 'translate(15, 12)' : ''}
                        fill={Colors.Blue}
                        stroke={Colors.Blue}
                    />
                </Styled.SvgIcon>
            </Styled.ArrowButton>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,

        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,

        responsive: [
            {
                breakpoint: 3000, // Para telas menores que 1500px
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 2500, // Para telas menores que 1500px
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1919, // Para telas menores que 1500px
                settings: {
                    slidesToShow: 4,
                },
            },

            {
                breakpoint: 1420, // Para telas menores que 1500px
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1100, // Para telas menores que 1100px
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 800, // Para telas menores que 800px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        fetchUserBySolucoesExclusive();
    }, []);

    return (
        <Styled.Container>
            <ResgatedToken
                valueModalToken={modalOpenToken}
                setHidenModalToken={() => setOpenModalToken(false)}
                openModalSolucoesExclusiva={modalOpenMenuSolutionsExclusive}
                setOpenModalSolucoesExclusiva={v => setModalMenuSolutionsExclusive(v)}
                hidenButtonIcon
                hidenModalToast
            />
            <Styled.Header>
                <img src={Logo} style={{ height: 100 }} alt="" />
                <Styled.SubTitle>
                    Soluções educacionais desenvolvidas para apoiar em diferentes desafios de redes de ensino públicas ou privadas.
                </Styled.SubTitle>
            </Styled.Header>
            <div>
                <Styled.Carrousel>
                    <Styled.CarouselCardContainer style={{ margin: 0 }} className="slider-container-2">
                        <Slider {...settings}>
                            {ListCards.map((dados, index) => (
                                <CardCarousel dados={dados} key={index} />
                            ))}
                        </Slider>
                    </Styled.CarouselCardContainer>
                </Styled.Carrousel>
            </div>
            <Styled.Footer>
                <ButtonHomeV2 onClick={() => history.push('/solucoes')}>Conheça as Soluções</ButtonHomeV2>
                <Popconfirm
                    disabled={keycloak.authenticated}
                    placement="top"
                    title={<strong>Continue como Parceiro do Instituto</strong>}
                    description={<p>Para continuar como parceiro do Instituto é preciso realizar seu cadastro ou login.</p>}
                    onConfirm={() => validateAccessToken(true)}
                    onCancel={() => validateAccessToken(false)}
                    okText="Já sou cadastrado e quero fazer meu login"
                    cancelText="Quero me cadastrar"
                    cancelButtonProps={{
                        style: {
                            width: '200px',
                            height: '30px',
                        },
                    }}
                    okButtonProps={{
                        style: {
                            height: '30px',
                            backgroundColor: Colors.Blue,
                        },
                    }}
                >
             
                            {userIsVinculatedSolutions ? (
                                <ButtonHomeV2 style={{  }} onClick={() => setModalMenuSolutionsExclusive(true)} outline>
                                    Acessar conteúdos exclusivos
                                </ButtonHomeV2>
                            ) : (
                                <ButtonHomeV2 style={{ width: '188px' }} onClick={() => keycloak.authenticated && setOpenModalToken(true)} outline>
                                    Já sou parceiro
                                </ButtonHomeV2>
                            )}
                 
                </Popconfirm>
            </Styled.Footer>
        </Styled.Container>
    );
};

export default CarouselSolucoes;
