import React from 'react';
import Styled from './NewSupportMaterial.styled';
import { IsMediumSizeMaxWidth } from 'core/util/WindowSize';

interface ISupportMaterial {
    description: JSX.Element;
    img: string;
}

const NewSupportMaterial = ({ description, img }: ISupportMaterial) => {
    const isMobile = IsMediumSizeMaxWidth();

    return (
        <Styled.GlobalContainer isMobile={isMobile}>
            <Styled.Container isMobile={isMobile}>
                <Styled.Content isMobile={isMobile}>
                    <Styled.Column isMobile={isMobile}>
                        <Styled.Title>MATERIAL DE APOIO</Styled.Title>
                        <div
                            style={{
                                maxWidth: isMobile ? '100%' : '80%',
                                lineHeight: '40px',
                                fontFamily: 'Barlow',
                                fontSize: '20px',
                                marginBottom: '20px',
                                fontWeight: 400,
                            }}
                        >
                            {description}
                        </div>
                    </Styled.Column>
                </Styled.Content>

                <Styled.ContainerImage isMobile={isMobile}>
                    <img style={{ width: '100%' }} src={img} />
                </Styled.ContainerImage>
            </Styled.Container>
        </Styled.GlobalContainer>
    );
};

export default NewSupportMaterial;
