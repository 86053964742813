import { createContext } from "react";




export namespace AcessoExclusivoContext {

    export interface IContext {
        loading: boolean,
        idsMyCategorias: number[],
        getUserExistBySchool: boolean;
        setGetUserExistBySchool: (value: boolean) => void;
    }



    export const Context = createContext({} as IContext);
}