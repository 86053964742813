import CategoriaPE from 'models/acessoExclusivo/CategoriaPE';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { FadUserAvaliationsContentsModel } from 'models/ferramentaAutoria/FadAvaliationsContentModel';
import { FadClassesModel } from 'models/ferramentaAutoria/FadClassesModel';
import { FadCommentaryContentModel } from 'models/ferramentaAutoria/FadCommentaryContentModel';
import { FadCommentaryContentPrivateModel } from 'models/ferramentaAutoria/FadCommentaryContentPrivateModel';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import { FadContentsPublicModel } from 'models/ferramentaAutoria/public/FadContentsPublicModel';
import { FadCoursesPublicModel } from 'models/ferramentaAutoria/public/FadCoursesPublicModel';
import { FadModulesPublicModel } from 'models/ferramentaAutoria/public/FadModulesPublicModel';
import { createContext } from 'react';

export namespace FerramentaAutoriaContext {
    export interface IContext {
        // Defina aqui as variáveis e funções que você deseja compartilhar no contexto
        certificados: CertificacoesModel[],
        loading: boolean,
        updateCourse: (value: FadCoursesModel) => void;
        courseModel: FadCoursesModel | null;
        categoriasExclusivas: CategoriaPE[];
        setCoursePublic: (course: any) => void;
        coursePublic: FadCoursesPublicModel,
        contentView: FadContentsPublicModel | null,
        setContentView: (content: FadContentsPublicModel) => void;
        moduleView: FadModulesPublicModel | null,
        setModuleView: (value: FadModulesPublicModel) => void;
        setScrollViewContent: (value: boolean) => void;
        scrollViewContent: boolean;
        inscrito: boolean;
        setInscricao: (value: boolean) => void,
        setClassSelected: (classe: FadClassesModel) => void;
        classSelected: FadClassesModel | null,
        isCourseCloseDate: (date: string) => boolean;
        setCourseClose: (value: boolean) => void,
        courseClose: boolean;
        avaliationContent: FadUserAvaliationsContentsModel | null,
        handleAvaliationContent: (value: number) => void,
        commentarysContent: FadCommentaryContentModel[],
        setCommentaryContent: (value: FadCommentaryContentModel) => void;
        setRespostasCommentaryContent: (codigoCommentary: number, value: FadCommentaryContentModel) => void;
        emissaoRealizada: boolean;
        handleClickCertificado: () => void;
        commentarys: FadCommentaryContentPrivateModel[];
        setCommentary: (value: FadCommentaryContentPrivateModel[]) => void;
        loadingCommentary: boolean;
        palavrasProibidas: string[];
        handlePalavrasProibidas: () => void;
        attComponentsByModules: (codigoModules: number, codigoContent?: number) => void;
        setCourse: (value: string) => void;
    }

    export const Context = createContext({} as IContext);
}