import React, { useState } from 'react';
import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import Styled from '../../AlfaTeoriaPratica.styled';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';

//Imgs
import Imagem from '../../assets/AlfaTeoriaPratica/image_primary.png';
import { fetchStorageFile } from 'hooks/StoreFiles';
const LogoParceiros = 'anexo_storage_files_12438381190308585376.png_2025-03-24-1742839780307.png';

const FirstCapa = () => {
    return (
        <>
            <Styled.ContentWhite>
                <div style={{display: "flex", flexDirection: "column", marginLeft: "44px"}}>
                    <p>
                        Ministério da Cultura, Guaraná Antarctica e Alura Start apresentam <br />
                        ALFABETIZAÇÃO INTEGRAL EM APOIO AO COMPROMISSO NACIONAL CRIANÇA ALFABETIZADA
                    </p>
                    <div style={{ width: '200px', height: 24, margin: "22px 0px", backgroundColor: Colors.Yellow }} />
                    <Styled.TitleBlue style={{ color: Colors.SoftBlue, fontSize: 36, marginBottom: 2 }}>ALFABETIZAR:</Styled.TitleBlue> <br />
                    <Styled.TitleBlue style={{ fontSize: 36 }}>DAS DEFINIÇÕES ÀS BOAS PRÁTICAS</Styled.TitleBlue>
                </div>

                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <Styled.Img style={{ height: '700px', maxWidth: '900px' }} src={Imagem} />
                </div>
                <Styled.Credits>
                    Material produzido pelo Instituto Ayrton Senna | 2024. Pode ser reproduzido, desde que mantida a menção de autoria
                </Styled.Credits>
            </Styled.ContentWhite>
            <div style={{backgroundColor: Colors.SoftBlue, width: "100%", height: 22}}/>
            <Styled.ContentWhite>
                <Styled.Img src={fetchStorageFile(LogoParceiros)} />
            </Styled.ContentWhite>
        </>
    );
};

export default FirstCapa;
