type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
};

export const ListMateriasFVPortuguesApoioCultural: TItem[] = [
    {
        title: 'Materiais Didáticos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Materiais Didáticos',
                arquivos: [
                    {
                        nome: 'FV_PoemasPoesias_digital',
                        arquivo: 'Materiais/FV_PoemasPoesias_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'FV_CartaAberta_digital',
                        arquivo: 'Materiais/FV_CartaAberta_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'FV_ContosAssombracao_digital',
                        arquivo: 'Materiais/FV_ContosAssombracao_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'FV_LivroProfessor_digital',
                        arquivo: 'Materiais/FV_LivroProfessor_digital_ApoioCultural.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Teste Diagnóstico',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Teste Diagnóstico',
                arquivos: [
                    {
                        nome: 'FV_POR_TESTE_DIAGNOSTICO_ALUNO_digital',
                        arquivo: 'Materiais/FV_POR_TESTE_DIAGNOSTICO_ALUNO_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'FV_POR_TESTE_DIAGNOSTICO_ORIENTACOES_digital',
                        arquivo: 'Materiais/FV_POR_TESTE_DIAGNOSTICO_ORIENTACOES_digital_ApoioCultural.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Agenda Formativas',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Agenda Formativas',
                arquivos: [
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_1',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_1_ApoioCultural.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_2',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_2_ApoioCultural.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_3',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_3_ApoioCultural.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_4',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_4_ApoioCultural.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_digital',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_digital_ApoioCultural.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Diretrizes',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'FV_CADERNO_DIRETRIZES_2024_digital_ApoioCultural',
                        arquivo: 'anexo_storage_files_14199339850835777282.pdf_2025-03-31-1743445951914.pdf',
                    },
                ],
            },
        ],
    },
];
export const ListMateriasFVPortuguesPlanoAnual: TItem[] = [
    {
        title: 'Materiais Didáticos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Materiais Didáticos',
                arquivos: [
                    {
                        nome: 'FV_PoemasPoesias_digital_PlanoAnual',
                        arquivo: 'Materiais/FV_PoemasPoesias_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'FV_LivroProfessor_digital_PlanoAnual',
                        arquivo: 'Materiais/FV_LivroProfessor_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'FV_PoemasPoesias_digital_PlanoAnual',
                        arquivo: 'Materiais/FV_PoemasPoesias_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'FV_LivroProfessor_digital_PlanoAnual',
                        arquivo: 'Materiais/FV_LivroProfessor_digital_PlanoAnual.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Teste Diagnóstico',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Teste Diagnóstico',
                arquivos: [
                    {
                        nome: 'FV_POR_TESTE_DIAGNOSTICO_ALUNO_digital_PlanoAnual',
                        arquivo: 'Materiais/FV_POR_TESTE_DIAGNOSTICO_ALUNO_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'FV_POR_TESTE_DIAGNOSTICO_ORIENTACOES_digital_PlanoAnual',
                        arquivo: 'Materiais/FV_POR_TESTE_DIAGNOSTICO_ORIENTACOES_digital_PlanoAnual.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Agendas Formativas',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Pauta Formativa',
                arquivos: [
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_1_PlanoAnual',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_1_PlanoAnual.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_2_PlanoAnual',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_2_PlanoAnual.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_3_PlanoAnual',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_3_PlanoAnual.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_DIA_4_PlanoAnual',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_DIA_4_PlanoAnual.ppt',
                    },
                    {
                        nome: 'FV_AGENDA_FORMATIVA_LP_digital_PlanoAnual',
                        arquivo: 'Materiais/FV_AGENDA_FORMATIVA_LP_digital_PlanoAnual.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Diretrizes',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'FV_CADERNO_DIRETRIZES_2024_digital_PlanoAnual',
                        arquivo: 'anexo_storage_files_134902410592594267.pdf_2025-03-31-1743446223287.pdf',
                    },
                ],
            },
        ],
    },
];
