import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledSolucoes = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 80px;
        align-items: center;
    `,

    ContainerSolutions: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 80px;
    `,

    Title: styled.h1`
        font-size: 32px;
        color: ${Colors.Blue};
        font-weight: bold;
    `,

    Description: styled.p`
        max-width: 80%;
        font-size: 20px;
        line-height: 28px;

        @media screen and (max-width: 720px) {
            max-width: 100%;
            width: 100%;
        }
    `,
};

export default StyledSolucoes;
