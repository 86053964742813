import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import { Form } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

const loopAnimation = keyframes`
 from { transform: rotate(0deg); }
 to { transform: rotate(360deg) }
`;

const StyleNewFormSolucoes = {
    Container: styled.div<{ img: string, isMobile: boolean }>`
    background-size: cover;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-image: url(${props => props.img});
        background-position: center;
        width: 100%;
        margin-top: ${({ isMobile }) => (isMobile ? '20px' : '0px')};
        justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'none')};
    `,

ImageContainer: styled.div<{ img: string}>`
    background-size: cover;
        background-image: url(${props => props.img});
        background-position: center;
        width: 100%;
        height: 478px;
    `,

    ContainerState: styled.div<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
    display: flex;
    flex-direction: column;
    `,

    Content: styled.div<{ isMobile: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: ${({ isMobile }) => (isMobile ? 'unset' : '789px')};
        width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
        flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

        @media screen and (max-width: 940px) {
            width: 100%;
        }
    `,

    Form: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        border-radius: ${({ isMobile }) => (isMobile ? 'unset' : '24px')};
        box-shadow: ${({ isMobile }) => (isMobile ? 'unset' : '0px 4px 4px 0px #00000040')};
        padding: 32px;
        padding-top: 45px;
        background-color: #ffffff;
        position: relative;
        margin-bottom: ${({ isMobile }) => (isMobile ? '0px' : '25px')};
        margin-top: ${({ isMobile }) => (isMobile ? '0px' : '25px')};
        max-width: ${({ isMobile }) => (isMobile ? 'unset' : '504px')};

        @media screen and (max-width: 1100px) {

        }

        @media screen and (max-width: 620px) {

        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 32px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.019em;
        text-align: left;
        color: ${Colors.Blue};
        max-width: 540px;
        margin-bottom: 15px;
    `,

    Description: styled.p<{ isMobile: boolean }>`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.019em;
        text-align: left;
        color: ${({isMobile}) => isMobile ? Colors.Blue : '#201e1e'};
    `,

    LabelForm: styled.label`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        margin-bottom: 5px;
        margin-top: 20px;
        color: ${Colors.Blue};
    `,

    LoadingCitiesContainer: styled.div`
        width: 320px;
        height: 50px;
        border-bottom: 2px #5f758b solid;

        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;

        span {
            font-size: 1.5em;

            animation-name: ${loopAnimation};
            animation-timing-function: linear;
            animation-duration: 1.25s;
            animation-iteration-count: infinite;
        }
    `,

    InputForm: styled(Form.Control)`
        width: 100%;
        height: 50px;
    `,

    InputFormSelect: styled(Form.Control)`
        height: 50px;
        border: 1px solid #ced4da;
        border-radius: 5px;

        &:focus {
            outline: none;
        }
    `,
};

export default StyleNewFormSolucoes;
