import React, { MutableRefObject, useEffect, useState } from 'react';
import keycloak from 'core/config/keycloak';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import useFetch from 'hooks/useFetch';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import Styled from './OfferedSolutions.styled';
import solutionsInfo from './MultiSolucoes';
import ListCards from './../../constants/ListCartoes';
import { TagSolucoesEnum, translateEnum } from 'enums/TagSolucoesEnum';
import Colors from 'enums/Colors';
import { PrimaryButton } from 'components/UI/Buttons';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';
import { ButtonHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';
import { TypeCard } from './CartaoMultiSolucoes';
import { Popover } from 'antd';

interface IOfferedSolutions {
    ref: MutableRefObject<HTMLDivElement | null>;
}

const OfferedSolutions = ({ ref }: IOfferedSolutions) => {
    const [solucoesExclusivas, setSolucoesExclusivas] = useState<TipoSolucoes[]>([]);
    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const isMobile: boolean = IsMobileBiggerWidth();
    const history = useHistory();

    const [openPopover, setOpenPopover] = useState<number>(0);

    const handleToAccess = (card: TypeCard) => {
        if (card.id.some((c) => c !== 5 && c !== 7) ) {
            return history.push(card.linkExclusivo);
        }

        if (solucoesExclusivas.some(s => s.id === 7) && solucoesExclusivas.some(s => s.id === 5)) {
            return setOpenPopover(card.id[0]);
        }

        if (card.listLinksExclusivos) {
            if (solucoesExclusivas.some(s => s.id === 5)) {
                return history.push(card.listLinksExclusivos[0]);
            }

            if (solucoesExclusivas.some(s => s.id === 7)) {
                return history.push(card.listLinksExclusivos[1]);
            }
        }
    };

    const goToLinksExclusive = (link: string) => {
        history.push(link);
    };

    useFetch(async () => {
        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                setSolucoesExclusivas(response.data);
            }
        }
    }, []);

    const handlePopoverClose = () => {
        setOpenPopover(0);
    };

    return (
        <>
            <Styled.Container ref={ref} id="solucoes">
                <Styled.Content>
                    {ListCards.map((card, index) => (
                        <Styled.CardSolution isMobile={isMobile} key={index}>
                            {!isMobile && (
                                <Styled.TagContainer>
                                    {card.tag.map((tag: TagSolucoesEnum, index) => (
                                        <Styled.Tag key={index}>{translateEnum(tag)}</Styled.Tag>
                                    ))}
                                </Styled.TagContainer>
                            )}
                            <Styled.Logo img={card.img!} />
                            <Styled.CardTitle>{card.titulo}</Styled.CardTitle>
                            <Styled.CardDescription>{card.descricao}</Styled.CardDescription>
                            <Styled.ButtonContainer>
                                <ButtonHomeV2 onClick={() => history.push(card.link)}>Saiba mais</ButtonHomeV2>
                                {card.id.some(id => solucoesExclusivas.some(f => f.id === id)) && (
                                    <Popover
                                        key={index}
                                        open={card.id.some((c) => c === openPopover)}
                                        content={
                                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                <ButtonHomeV2
                                                    onClick={() => {
                                                        if (card.listLinksExclusivos) {
                                                            goToLinksExclusive(card.listLinksExclusivos[0]);
                                                        }
                                                    }}
                                                    name="Português"
                                                >
                                                    Português
                                                </ButtonHomeV2>
                                                <ButtonHomeV2
                                                    onClick={() => {
                                                        if (card.listLinksExclusivos) {
                                                            goToLinksExclusive(card.listLinksExclusivos[1]);
                                                        }
                                                    }}
                                                    name="Matemática"
                                                >
                                                    Matemática
                                                </ButtonHomeV2>
                                            </div>
                                        }
                                        title="Escolha um dos conteúdos exclusivos abaixo para acessar:"
                                        trigger="click"
                                        onOpenChange={handlePopoverClose}
                                    >
                                        <PrimaryButton
                                            style={{
                                                padding: '10px 10px 10px 10px',
                                                borderRadius: 4,
                                                width: 'auto',
                                                fontWeight: 500,
                                                fontSize: '15px',
                                            }}
                                            type="button"
                                            onClick={() => handleToAccess(card)}
                                        >
                                            Acessar conteúdo exclusivo
                                        </PrimaryButton>
                                    </Popover>
                                )}
                            </Styled.ButtonContainer>
                        </Styled.CardSolution>
                    ))}
                </Styled.Content>
            </Styled.Container>
        </>
    );
};

export default OfferedSolutions;
