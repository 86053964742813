import React, { Ref, useState } from 'react';
import Styled from './AboutSolutions.styled';
import Imagem from '../../assets/foto_01.svg';
import keycloak from 'core/config/keycloak';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import { Popconfirm } from 'antd';
import { KeycloakLoginOptions } from 'keycloak-js';
import Colors from 'enums/Colors';
import { ButtonHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';

interface IAboutSolutions {
    setModalTokenOpen: (bool: boolean) => void;
    setModalContentExclusive: () => void;
    scroolToSolutions: () => void;
    menuFixed?: React.ReactElement;
}

const AboutSolutions = ({ setModalTokenOpen, scroolToSolutions, setModalContentExclusive, menuFixed }: IAboutSolutions) => {
    const isMobile = IsMobileBiggerWidth();

    const validateAccessToken = (goTologin: boolean) => {
        const current = location.pathname.split('/')[1];

        const redirect = current === 'home' ? '/home' : '/solucoes';

        if (keycloak.authenticated) {
            setModalTokenOpen(true);
        } else {
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + redirect,
            };

            const _url = goTologin ? keycloak.createLoginUrl(options) : keycloak.createRegisterUrl(options);
            window.location.assign(_url);
        }
    };

    return (
        <>
            <Styled.Container>
                <Styled.Content isMobile={isMobile}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
                        <Styled.Title>{`Um pouco sobre as soluções oferecidas`}</Styled.Title>
                        <Styled.DescriptionBlack
                            dangerouslySetInnerHTML={{
                                __html: ` As nossas soluções educacionais foram desenvolvidas pensando em diferentes desafios que redes de ensino podem enfrentar,
                        como a gestão de políticas de alfabetização, a correção de fluxo, o reforço de aprendizagem e o desenvolvimento de
                        competências socioemocionais. <strong> Conheça mais abaixo: </strong>
                      `,
                            }}
                        />
                    </div>
                    {menuFixed}

                    <Styled.ContainerButton isMobile={isMobile}>
                        <Popconfirm
                            disabled={keycloak.authenticated}
                            placement="top"
                            title={<strong>Continue como Parceiro do Instituto</strong>}
                            description={<p>Para continuar como parceiro do Instituto é preciso realizar seu cadastro ou login.</p>}
                            onConfirm={() => validateAccessToken(true)}
                            onCancel={() => validateAccessToken(false)}
                            okText="Já sou cadastrado e quero fazer meu login"
                            cancelText="Quero me cadastrar"
                            cancelButtonProps={{
                                style: {
                                    width: '200px',
                                    height: '30px',
                                },
                            }}
                            okButtonProps={{
                                style: {
                                    height: '30px',
                                    backgroundColor: Colors.Blue,
                                },
                            }}
                        >
                            <Styled.ButtonSecondary
                                stylesPersonalizations={{ padding: '10px 20px' }}
                                borderRadius="5px"
                                width={keycloak.authenticated ? 'fit-content' : isMobile ? '262px' : '174px'}
                                onClick={() => {
                                    keycloak.authenticated && setModalContentExclusive();
                                }}
                            >
                                {keycloak.authenticated ? 'VER CONTEÚDOS EXCLUSIVOS' : 'JÁ SOU PARCEIRO'}
                            </Styled.ButtonSecondary>
                        </Popconfirm>
                        <ButtonHomeV2 style={{ marginTop: '10px', minHeight: '50px' }} onClick={() => scroolToSolutions()}>
                            {'VER SOLUÇÕES EDUCACIONAIS'}
                        </ButtonHomeV2>
                    </Styled.ContainerButton>
                </Styled.Content>
            </Styled.Container>
        </>
    );
};

export default AboutSolutions;
