import { Button, Image, Modal, Popover } from 'antd';
import Colors from 'enums/Colors';
import { fetchStorageFile } from 'hooks/StoreFiles';
import useFetch from 'hooks/useFetch';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import React, { useContext, useEffect, useState } from 'react';
import { CiMenuKebab } from 'react-icons/ci';
import { FiMenu } from 'react-icons/fi';
import styled from 'styled-components';
import { FAD_Button_Primary } from '../FadButtons';
import { useHistory } from 'react-router-dom';
import ModalFormCourse from '../modals/ModalFormCourse';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { TemplateCourseFadEnum } from '../courses/TemplateCourse';
import { FadCoursesAnexoService } from 'core/http/service/ferramentaAutoria/FadCoursesAnexo.service';
import { FadCoursesAnexoModel } from 'models/ferramentaAutoria/FadCourseAnexoModel';
import { TextDangerouslySetInnerHtml } from 'pages/recursos/recursos-conteudo/RecursosConteudoPage.styled';
import { colors } from 'react-select/src/theme';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import { ToastError, ToastSuccess } from 'components/Toaster';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-wrap: wrap;
    `,

    Card: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            width: 320px;
            min-height: 320px;
            gap: 16px;
            opacity: 0px;
            margin: 8px;
            border-radius: 0px 0px 8px 8px;
            -webkit-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.31);
            -moz-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.31);
            box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.31);
            /* border: 1px solid #cacaca; */
            padding-bottom: 6px;
        `,

        Row: styled.div`
            display: flex;
        `,

        Content: styled.div`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            gap: 12px;
            padding: 6px;
        `,

        Info: styled.div`
            display: flex;
            flex-direction: column;
            gap: 16px;
        `,

        RowBeet: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `,

        NameCourse: styled.h1`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 22.4px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};
        `,
        Description: styled.p``,

        Img: styled.img`
            width: 100%;
            height: 140px;
        `,

        Status: styled.div<{ isPublic: boolean }>`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 69px;
            height: 25px;
            padding: 4px 8px 4px 8px;
            gap: 16px;
            border-radius: 16px;
            opacity: 0px;
            background-color: ${({ isPublic }) => (isPublic ? '#1B7F43' : '#99E3FF')};
            color: ${({ isPublic }) => (isPublic ? Colors.White : '')};
            font-family: 'Barlow';
            font-size: 12px;
            font-weight: 600;
            line-height: 16.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-top: 10px;
        `,
    },
};

interface ICardCourse {
    dados: FadCoursesModel;
    handleEdit: () => void;
    handleNewAddNewCourseClone: (course: FadCoursesModel) => void;
}

const CardCourse = ({ dados, handleEdit, handleNewAddNewCourseClone }: ICardCourse) => {
    const [openModalClone, setModalClone] = useState<boolean>(false);

    const history = useHistory();
    const [anexo, setAnexo] = useState<FadCoursesAnexoModel | null>(null);

    const serviceCourseAnexo = new FadCoursesAnexoService();
    const serveiceCourse = new FadCoursesService();

    useFetch(async () => {
        const { data, status } = await serviceCourseAnexo.findAnexoByCourse(dados.codigo);

        if (status === 200) {
            setAnexo(data);
        }
    }, [dados]);

    const handleNav = () => {
        history.push('/admin/ferramenta-autoria/curso/' + dados.codigo);
    };

    const handleCloneCourse = async () => {
        setModalClone(true);

        try {
            const { data, status } = await serveiceCourse.cloneCourse(dados.codigo);

            if (status === 201) {
                const dataCourse = data as FadCoursesModel;
                handleNewAddNewCourseClone(dataCourse);
                ToastSuccess("Curso clonado com sucesso!")
            }
        } catch (error) {
            ToastError("Ocorreu algum erro no clone do curso!")
        }

        setModalClone(false);
    };

    return (
        <Styled.Card.Container>
            <Modal title="" centered footer={false} closeIcon={false} open={openModalClone}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: 100,
                        marginTop: 20,
                        borderRadius: 10,
                        gap: '0.5rem',
                        backgroundColor: Colors.Blue,
                    }}
                >
                    <h1 style={{ color: '#FFFFFF', fontSize: 22 }}>Aguarde, a conclusão do clone.</h1>
                    <div className="loaderCreater"></div>
                    <p style={{ color: '#FFFFFF', fontSize: 12 }}>Essa ação pode levar alguns minutos.</p>
                </div>
            </Modal>
            <Image
                width={'100%'}
                height={'140px'}
                style={{ objectFit: 'cover' }}
                src={anexo?.base64}
                loading="lazy"
                fallback={fetchStorageFile('anexo_storage_files_12734497904244904460.jpg_2024-12-23-1734954777648.jpg')}
            />
            <Styled.Card.Content>
                <Styled.Card.Info>
                    <Styled.Card.RowBeet>
                        <Styled.Card.Status isPublic={dados.isPublic}>{dados.isPublic ? 'Publicado' : 'Privado'}</Styled.Card.Status>
                        <Popover
                            content={
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Button onClick={handleCloneCourse} type="text">
                                        Clonar curso
                                    </Button>
                                    <Button onClick={handleEdit} type="text">
                                        Editar
                                    </Button>
                                </div>
                            }
                            placement="rightTop"
                            title="Opções"
                            trigger="click"
                        >
                            <FiMenu size={26} cursor={'pointer'} />
                        </Popover>
                    </Styled.Card.RowBeet>
                    <Styled.Card.NameCourse>{dados.name}</Styled.Card.NameCourse>
                    <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: dados.description }} style={{ margin: 0 }} />
                </Styled.Card.Info>
                <FAD_Button_Primary text="Acessar" onClick={handleNav} />
            </Styled.Card.Content>
        </Styled.Card.Container>
    );
};

const InitialFormCourse = FadCoursesModel.fromJSON({
    codigo: -1,
    codigoCertificado: -1,
    name: '',
    description: '',
    url: '',
    typeRedirect: TipoRedirecionamento.IGNORAR, // Assuming TipoRedirecionamentoEnum is converted to String
    template: TemplateCourseFadEnum.Lateral,
    sequentialContents: false,
    sequentialSteps: false,
    isPublic: false,
    ordem: 1,
});

interface IAdminFadAbas {
    courses: FadCoursesModel[];
}

const AdminFadAbas = ({ courses }: IAdminFadAbas) => {
    const [listCourses, setListCourses] = useState<FadCoursesModel[]>(courses);
    const [formCourse, setFormCourse] = useState<FadCoursesModel>(InitialFormCourse);
    const { updateCourse, certificados } = useContext(FerramentaAutoriaContext.Context);

    const [isModalOpenEditCourse, setModalOpenEditCourse] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleUpdate = async () => {
        setLoading(true);

        if (formCourse) {
            const novoForm = formCourse;
            novoForm.url = '/' + novoForm.url;

            const data = await updateCourse(novoForm);

            setListCourses((prev: any) =>
                prev.map((course: FadCoursesModel) => {
                    if (course.codigo === novoForm.codigo) {
                        return data;
                    }

                    return course;
                })
            );
        }

        setModalOpenEditCourse(false);
        setLoading(false);
    };

    useEffect(() => {
        setListCourses(courses);
    }, [courses]);

    return (
        <Styled.Container>
            {listCourses.map((list, index) => (
                <CardCourse
                    key={index}
                    dados={list}
                    handleEdit={() => {
                        setFormCourse(list), setModalOpenEditCourse(true);
                    }}
                    handleNewAddNewCourseClone={c => setListCourses((prev: FadCoursesModel[]) => [...prev, c])}
                />
            ))}
            <ModalFormCourse
                open={false}
                openEdit={isModalOpenEditCourse}
                handleSave={() => ''}
                handleEdit={handleUpdate}
                loading={loading}
                certificados={certificados}
                form={formCourse}
                setForm={setFormCourse}
                handleHidden={() => setModalOpenEditCourse(false)}
            />
        </Styled.Container>
    );
};

export default AdminFadAbas;
