import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import React from 'react';
import { CiText } from 'react-icons/ci';
import { FaFileAudio, FaPencilRuler } from 'react-icons/fa';
import { FaDiagramNext } from 'react-icons/fa6';
import { GrSans } from 'react-icons/gr';
import { HiOutlineChatBubbleBottomCenterText } from 'react-icons/hi2';
import { MdFormatListNumbered, MdOutlineControlPoint, MdTimeline } from 'react-icons/md';
import { PiNumberSquareNineFill, PiTabsDuotone } from 'react-icons/pi';
import { RiDragDropFill } from 'react-icons/ri';
import { TbHelpSquareFilled, TbCircle1Filled } from 'react-icons/tb';
import { TfiLayoutSliderAlt } from 'react-icons/tfi';
import { RxDividerHorizontal } from 'react-icons/rx';
import {} from 'react-icons/tb';
import { BsFillBookmarksFill } from 'react-icons/bs';

export type TListItensMenuLateral = {
    tipo: ComponentInteractionEnum;
    title: string;
    icon: any;
    category: number;
};

const ListItensMenuLateral: TListItensMenuLateral[] = [
    {
        tipo: ComponentInteractionEnum.AUDIO,
        title: 'Áudio',
        icon: <FaFileAudio size={22} />,
        category: 0,
    },
    {
        tipo: ComponentInteractionEnum.TEXT,
        title: 'Texto',
        icon: <CiText size={22} />,
        category: 1,
    },
    {
        tipo: ComponentInteractionEnum.TWO_TEXT,
        title: 'Texto em duas colunas ',
        icon: <CiText size={22} />,
        category: 1,
    },

    {
        tipo: ComponentInteractionEnum.TEXT_IMG_LEFT,
        title: 'Texto com imagem a esquerda ',
        icon: <CiText size={22} />,
        category: 1,
    },

    {
        tipo: ComponentInteractionEnum.TEXT_IMG_RIGHT,
        title: 'Texto com imagem a direita ',
        icon: <CiText size={22} />,
        category: 1,
    },

    {
        tipo: ComponentInteractionEnum.TEXT_HIGHLIGHTED,
        title: 'Texto com imagem de fundo ',
        icon: <CiText size={22} />,
        category: 1,
    },

    {
        tipo: ComponentInteractionEnum.SANFONA,
        title: 'Sanfona',
        icon: <GrSans size={22} />,
        category: 3,
    },
    {
        tipo: ComponentInteractionEnum.QUIZ,
        title: 'Quiz',
        icon: <MdFormatListNumbered size={22} />,
        category: 2,
    },
    {
        tipo: ComponentInteractionEnum.CORRESPONDENT,
        title: 'Correspondência',
        icon: <RiDragDropFill size={22} />,
        category: 2,
    },
    {
        tipo: ComponentInteractionEnum.CARROSSEL,
        title: 'Carrossel',
        icon: <TfiLayoutSliderAlt size={22} />,
        category: 3,
    },
    {
        tipo: ComponentInteractionEnum.ABAS,
        title: 'Abas',
        icon: <PiTabsDuotone size={22} />,
        category: 3,
    },
    {
        tipo: ComponentInteractionEnum.CARD_BARALHO,
        title: 'Baralho',
        icon: <TbHelpSquareFilled size={22} />,
        category: 3,
    },
    {
        tipo: ComponentInteractionEnum.REGUA_AVALIATIVA,
        title: 'Régua Avaliativa',
        icon: <FaPencilRuler size={22} />,
        category: 2,
    },
    {
        tipo: ComponentInteractionEnum.TIMELINE,
        title: 'Linha do tempo',
        icon: <MdTimeline size={22} />,
        category: 3,
    },
    {
        tipo: ComponentInteractionEnum.QUIZ_PONTUACAO,
        title: 'Quiz com pontuação',
        icon: <PiNumberSquareNineFill size={22} />,
        category: 2,
    },
    {
        tipo: ComponentInteractionEnum.CENARIO,
        title: 'Cenário',
        icon: <HiOutlineChatBubbleBottomCenterText size={22} />,
        category: 3,
    },
    {
        tipo: ComponentInteractionEnum.NEXT_INFORMATION,
        title: 'Continuar',
        icon: <FaDiagramNext size={22} />,
        category: 4,
    },
    {
        tipo: ComponentInteractionEnum.DIVIDER_LINE,
        title: 'Divisor em linha',
        icon: <RxDividerHorizontal size={22} />,
        category: 5,
    },

    {
        tipo: ComponentInteractionEnum.DIVIDER_LINE_WITH_NUMBER,
        title: 'Divisor em linha númerado',
        icon: <TbCircle1Filled size={22} />,
        category: 5,
    },
    {
        tipo: ComponentInteractionEnum.MARK_TEXT,
        title: 'Marcador de texto',
        icon: <BsFillBookmarksFill size={22} />,
        category: 6,
    },
    {
        tipo: ComponentInteractionEnum.HOSTPOT,
        title: 'Hostpot',
        icon: <MdOutlineControlPoint size={22} />,
        category: 3,
    },
];

export default ListItensMenuLateral;
