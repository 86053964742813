import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledNewSupportMaterial = {
    GlobalContainer: styled.div<{ isMobile: boolean }>`
        width: ${({ isMobile }) => (isMobile ? '90%' : '100%')};
        display: flex;
        justify-content: center;
        margin-top: ${({ isMobile }) => (isMobile ? '40px' : '0px')};
    `,

    Container: styled.div<{ isMobile: boolean }>`
        display: flex;
        margin-top: ${({ isMobile }) => (isMobile ? '0px' : '40px;')};
        justify-content: space-between;
        flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
        min-height: 550px;
        max-width: 1227px;
        gap: 0.5rem;
                @media screen and (max-width: 1300px){
          padding: 0px 5px 0px 15px
        }
    `,

    ContainerImage: styled.div<{ isMobile: boolean}>`
        margin-top: ${({ isMobile }) => (isMobile ? '40px' : '0px')};
        max-width: ${({ isMobile }) => (isMobile ? '212px' : '388px')};
        align-self: center;
        background-size: cover;
    `,

    Title: styled.h1`
        font-size: 32px;
        line-height: 150%;
        font-weight: 700;
        color: ${Colors.Blue};
    `,

    Description: styled.p`
        font-size: 20px;
        line-height: 36px;
        max-width: 80%;
        font-weight: 400;
        margin-bottom: 24px;
    `,

    Content: styled.div<{ isMobile: boolean }>`
        padding-top: ${({ isMobile }) => (isMobile ? '70px' : '0')} ;
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        gap: 0.5rem;
        margin-bottom: ${({ isMobile }) => (isMobile ? '0px' : '70px')};
        justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'center')};

        @media screen and (max-width: 940px) {
            width: 100%;
        }
    `,

    Column: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: ${({ isMobile }) => (isMobile ? '90%' : '100%')};
    `,

    Carrousel: styled.div`
        display: flex;
        margin: 40px;
    `,

};

export default StyledNewSupportMaterial;
