import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import React, { useContext, useEffect } from 'react';
import Styled from './DialogosSocioemocionais.styled';
import OverviewExclusivo from '../components/OverviewExclusivo';
import logoOverview from '../assets/dialogos/LogoOverview.svg';
import ArquivoDownload from '../assets/dialogos/Dialogos_Socioemocionais_Visao_Geral.pdf';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import Logo from '../assets/dialogos/logo_01.png';
import { ListMateriaisDialogosSocioemocionais } from './ListMateriaisDialogosSocioemocionais';
import CicloExclusivo from '../components/CicloExclusivo';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import PartnersExclusivo from '../components/PartnersExclusivo';
import ImagemTrilha from '../assets/dialogos/imagem_01.jpg';
import Banner01 from '../assets/dialogos/banner_01.jpg';
import Banner02 from '../assets/dialogos/banner_02.jpg';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import dialogosLogo from './../../../../images/solutions/logo/dialogosLogo.png';
import ModalSchool from '../components/ModalSchool';

const DialogosSocioemocionaisPrivate = () => {
    const { loading, idsMyCategorias } = useContext(AcessoExclusivoContext.Context);
    const isMobile = IsMobileBiggerWidth();

    const listBlackListDialogosDuploFoco: number[] = [1];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Styled.Container isMobile={isMobile}>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        img="anexo_storage_files_11566250207537833097.jpg_2024-11-26-1732646957514.jpg"
                        logo={dialogosLogo}
                        title="Diálogos Socioemocionais"
                        description={`
                            O Diálogos Socioemocionais, desenvolvido pelo Instituto Ayrton Senna, é uma proposta educacional que apoia educadores e
                            gestores na promoção intencional e estruturada do desenvolvimento de competências socioemocionais dos estudantes.
                            A iniciativa enfatiza práticas pedagógicas baseadas a evidências e coloca o professor como mediador ativo, utilizando práticas SAFE
                            (sequenciais, ativas, focadas e explícitas), metodologias ativas, avaliação formativa e mediação dialógica. </br> </br>
                            Para os gestores escolares e Secretarias de Educação, o programa oferece uma Sistemática de Monitoramento e
                            Acompanhamento, que utiliza avaliação formativa e análise de dados para intervenções contínuas. Assim, o Diálogos Socioemocionais
                            busca consolidar práticas de gestão e docência integradas para implementar as diretrizes da BNCC, promovendo uma educação integral
                            e o desenvolvimento socioemocional dos estudantes.
                          `}
                        arquivo={'anexo_storage_files_2839206562349981815.pdf_2024-11-27-1732735828100.pdf'}
                        nome_arquivo="Dialogos_Socioemocionais_Visao_Geral"
                    />
                    <CicloExclusivo
                        title="Trilha Formativa Diálogos Socioemocionais"
                        description={`
                    A trilha formativa do Diálogos Socioemocionais apresenta os conteúdos da solução educacional de modo que os profissionais
                    (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                    Inicie agora a sua trilha formativa!
                    `}
                        img="anexo_storage_files_16448016042645220309.jpg_2024-11-26-1732646995405.jpg"
                        itens={[
                            {
                                title: 'Trilha Formativa Duplo Foco',
                                url: '/recursos/dialogos-socioemocionais/dialogos-socioemocionais-duplo-foco',
                                isBlock: idsMyCategorias.some((n: number) => listBlackListDialogosDuploFoco.includes(n)),
                                titleIsBlock: `Essa trilha formativa não está mais disponível. O prazo para a conclusão da trilha era até <strong> 12/11/2024</strong></p>`,
                            },
                        ]}
                    />

                    <MateriaisExclusivos
                        title="Materiais especiais para download"
                        description={`  Tenha acesso aos materiais exclusivos do Diálogos Socioemocionais, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={'anexo_storage_files_14472106021717107526.svg_2024-11-26-1732639689100.svg'}
                        items={ListMateriaisDialogosSocioemocionais}
                    />
                    <ContactContainer />
                </>
            )}
        </Styled.Container>
    );
};

export default DialogosSocioemocionaisPrivate;
