import ProfileData from 'models/ProfileData';
import { GenericService } from './Generic.service';
import PerfilAtualizationDTO from 'models/perfil/PerfilAtualizationDTO';

export class ProfileService extends GenericService {
    readonly URI: string = '/v1/perfilUsuario';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public consultarParametrosCadastro() {
        return this.get('parametrosCadastro');
    }

    public async saveProfile(profileData: ProfileData) {
        return await this.post('cadastrarPerfil', profileData);
    }

    public editProfile(username: string, profileData: any) {
        return this.put('editarPerfil', { username, ...profileData });
    }

    public atualizarPendenciasPerfil(profileData: PerfilAtualizationDTO) {
        return this.put('atualizarPendencias', profileData);
    }

    public atualizarPlayAudio(playAudio: boolean) {
        return this.put('atualizarPlayAudio', { playAudio: playAudio });
    }

    public existePorCpf(cpf: string) {
        const params = {
            cpf,
        };
        return this.get('existePorCpf', params);
    }

    public findByUsername(username: string) {
        const params = {
            username,
        };
        return this.get('findByUsername', params);
    }

    public findProfileImage(idPerfil: number) {
        return this.get('findProfileImage', { idPerfil });
    }
    public findAllEstados() {
        return this.get('findAllEstados');
    }

    public findSchools() {
      return this.get('getSchools');
    }

    public saveUserSchool(schoolName: string | null, schoolInep: string | null,) {
      return this.post('registrarEscola', {schoolName, schoolInep});
  }

    public consultarCidadesPorUF(uf: string) {
        //uf = this.geonamesToIBGE(uf);

        const params = {
            uf,
        };

        return this.get('findCidadesPorUF', params);
    }

    private geonamesToIBGE = (uf: any): string => {
        const listaEstados = [
            { id: 3665474, nome: 'AC' },
            { id: 3408096, nome: 'AL' },
            { id: 3407762, nome: 'AP' },
            { id: 3665361, nome: 'AM' },
            { id: 3471168, nome: 'BA' },
            { id: 3402362, nome: 'CE' },
            { id: 3463930, nome: 'ES' },
            { id: 3463504, nome: 'DF' },
            { id: 3462372, nome: 'GO' },
            { id: 3395443, nome: 'MA' },
            { id: 3457419, nome: 'MT' },
            { id: 3457415, nome: 'MS' },
            { id: 3457153, nome: 'MG' },
            { id: 3455077, nome: 'PR' },
            { id: 3393098, nome: 'PB' },
            { id: 3393129, nome: 'PA' },
            { id: 3392268, nome: 'PE' },
            { id: 3392213, nome: 'PI' },
            { id: 3390290, nome: 'RN' },
            { id: 3451133, nome: 'RS' },
            { id: 3451189, nome: 'RJ' },
            { id: 3924825, nome: 'RO' },
            { id: 3662560, nome: 'RR' },
            { id: 3450387, nome: 'SC' },
            { id: 3447799, nome: 'SE' },
            { id: 3448433, nome: 'SP' },
            { id: 3474575, nome: 'TO' },
        ];

        return listaEstados.find(item => item.id == uf)?.nome || '';
    };

    public getInfoDashboard() {
        return this.get('infoDashboard');
    }

    public getFullDashboard(username: String | null, isGestor: Boolean, idCurso: number) {
        return this.get('fullDashboard', { username, isGestor, idCurso });
    }

    public getUserEdit(username: string) {
        return this.get('getUserEdit', { username });
    }

    public registrandoLogin(idPerfil: number | null) {
        return this.post('regitrarLogin', null, { idPerfil: idPerfil });
    }

    public getUserExistentSchoolRelations() {
        return this.get('getExistentSchoolByUser');
    }
}
