export enum Colors {
    Blue = '#003C78',
    LightBlue = '#0068B3',
    LightBlue2 = '#04B0F9',
    LightBlue3 = '#009FE3',
    DarkBlue = '#183f73',
    DarkBlue2 = '#124A88',
    DarkBlue3 = '#013162',
    DisabledBlue = '#C9D3DD',
    SoftBlue = '#00A8E9',
    SoftBlue2 = '#0066FF',
    SoftBlue3 = '#E5F6FD',
    SoftBlue4 = '#7291B0',
    SoftBlue5 = '#94ccf0',
    SoftBlue6 = '#007BD8',
    BlueGrey = '#5f758b',

    Yellow = '#FFCA00',
    Yellow2 = '#FFBD00',
    LightYellow = '#FFD500',

    DarkGrey = '#2E2E2E',
    LightGrey = '#AAAAAA',
    Grey3 = '#777777',
    Grey4 = '#888888',
    Grey5 = '#C9C9C9',
    Grey6 = '#efefef',
    Grey7 = '#828282',
    Grey8 = '#E5E5E5',
    Grey9 = '#494949',
    Grey10 = '#C3CBCD',
    Grey11 = '#fafafa',
    Grey12 = '#ADADAD',
    Grey13 = '#CBD2E0',
    DarkDarkGrey = '#737b7d',
    DarkGreyBlue = '#7B7C7D',
    GreyBlue = '#5F758B',
    BackGroundGrey= '#ECECEC',

    InativeGrey = '#B4B4B4',
    SoftBG = '#F7F7F7',

    White = '#FFFFFF',
    BackGroundWhite = '#F5F5F5',

    Brown = '#2D2403',
    Brown2 = '#DC9D00',

    LightGreen = '#01C509',
    CloneGreen = '#53AD22',
    BrightGreen = '#53AD22',

    Red = '#C00D0E',
    InfoRed = '#FF4830',
    DeleteRed = '#ED3D31',
    WarningRed = '#ff6565',

    PureOrange = '#F79C00',
    VividOrange = '#FBB202',

    UncleanWhite = '#FCFCFC',

    BrightBlueToStrongBlueGradient = 'linear-gradient(318.36deg, #0B78C8 0%, #47AEFC 100%)',
    VividOrangeToPureOrangeGradient = 'linear-gradient(318.36deg, #E8950D 0%, #FFB800 100%)',
}

export default Colors;

export namespace ColorsContants {
    export const disabledOpacity: number = 0.4;
}
