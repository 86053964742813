import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadUserAvaliationsModel {
    codigo: number;
    codigoComponent: number;
    codigoClasse: number;
    average: number;
    created: string | null;
}

export class FadAvaliationsModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoComponent: number = -1;

    @jsonProperty(Number)
    codigoClasse: number = -1;

    @jsonProperty(Number)
    average: number = -1;

    @jsonProperty(String)
    created: string | null = null;

    fromJSON(json: IFadUserAvaliationsModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoComponent = json.codigoComponent;
        this.codigoClasse = json.codigoClasse;
        this.average = json.average;
        this.created = json.created;
        return this;
    }
}
