type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
};

export const ListMateriasSeLigaApoioCultural: TItem[] = [
    {
        title: 'Materiais Didáticos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Materiais Didáticos',
                arquivos: [
                    {
                        nome: 'SeLiga_LP_digital_ApoioCultural',
                        arquivo: 'anexo_storage_files_12157423065647790932.pdf_2025-03-27-1743098188176.pdf',
                    },
                    {
                        nome: 'SL_LivroProfessor_MP_001-120_PDF-Digital',
                        arquivo: 'Materiais/SL_LivroProfessor_MP_001-120_PDF-Digital_ARQ_SELIGA.pdf',
                    },
                    { nome: 'SL_Mat_Aluno_001-344_PDF-Digital', arquivo: 'Materiais/SL_Mat_Aluno_001-344_PDF-Digital_ARQ_SELIGA.pdf' },
                    {
                        nome: 'Orientacao_Teste_Diagnostico_SL_e_AB_matematica',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_SL_e_AB_matematica_ARQ_SELIGA.pdf',
                    },
                    {
                        nome: 'Teste_Diagnostico_de_matematica_SL_e_AB',
                        arquivo: 'Materiais/Teste_Diagnostico_de_matematica_SL_e_AB_ARQ_SELIGA.pdf',
                    },
                ],
            },
        ],
    },

    {
        title: 'Teste Diagnóstico',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Teste Diagnóstico',
                arquivos: [
                    {
                        nome: 'Orientacao_Teste_Diagnostico_ SL_e_AB_digital_ApoioCultural',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_ SL_e_AB_digital_ApoioCultural_ARQ_SELIGA.pdf',
                    },
                    {
                        nome: 'Teste_Diagnostico_de_alfabetizacao_SL_e_AB_digital_ApoioCultural',
                        arquivo: 'Materiais/Teste_Diagnostico_de_alfabetizacao_SL_e_AB_digital_ApoioCultural_ARQ_SELIGA.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Pauta Formativa',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Pauta Formativa',
                arquivos: [
                    {
                        nome: 'PAUTA_FORMATIVA_Se_Liga_digital_ApoioCultural',
                        arquivo: 'Materiais/PAUTA_FORMATIVA_Se_Liga_digital_ApoioCultural_ARQ_SELIGA.pdf',
                    },
                    {
                        nome: 'PPT_DE_APOIO_SE LIGA_ApoioCultural_ARQ_SELIGA',
                        arquivo: 'anexo_storage_files_4894171044023755561.pptx_2025-03-28-1743171853297.pptx',
                    },
                ],
            },
        ],
    },
    {
        title: 'Subsídios',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'Se_Liga_Subsidios_para_o_inicio_do_programa_digital_ApoioCultural',
                        arquivo: 'Materiais/Se_Liga_Subsidios_para_o_inicio_do_programa_digital_ApoioCultural_ARQ_SELIGA.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sistemática de Acompanhamento',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Sistemática de Acompanhamento',
                arquivos: [
                    {
                        nome: 'Sistematica_de_Acompanhamento_SL_AC_digital_ApoioCultural',
                        arquivo: 'Materiais/Sistematica_de_Acompanhamento_SL_AC_digital_ApoioCultural_ARQ_SELIGA.pdf',
                    },
                    {
                        nome: 'Cartazes_Se_Liga_e_Acelera_A3_PF',
                        arquivo: 'Materiais/Cartazes_Se_Liga_e_Acelera_A3_PF_ARQ_SELIGA.pdf',
                    },
                    {
                        nome: 'Tabelas_Se Liga e Acelera_A4_PF',
                        arquivo: 'anexo_storage_files_16422537677049298333.pdf_2025-03-27-1743098293198.pdf',
                    },
                ],
            },
        ],
    },
];
export const ListMateriasSeLigaPlanoAnual: TItem[] = [
    {
        title: 'Materiais Didáticos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Materiais Didáticos',
                arquivos: [
                    {
                        nome: 'SeLiga_LP_digital_PlanoAnual',
                        arquivo: 'anexo_storage_files_10231003907816357361.pdf_2025-03-27-1743100216553.pdf',
                    },
                    {
                        nome: 'SL_LivroProfessor_MP_001-120_PDF-Digital',
                        arquivo: 'Materiais/SL_LivroProfessor_MP_001-120_PDF-Digital_ARQ_SELIGA.pdf',
                    },
                    {
                        nome: 'SL_Mat_Aluno_001-344_PDF-Digital',
                        arquivo: 'Materiais/SL_LivroProfessor_MP_001-120_PDF-Digital_ARQ_SELIGA_ANUAL.pdf',
                    },
                ],
            },
        ],
    },

    {
        title: 'Teste Diagnóstico',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Teste Diagnóstico',
                arquivos: [
                    {
                        nome: 'Teste_Diagnostico_de_alfabetizacao_SL_e_AB_digital_PlanoAnual',
                        arquivo: 'Materiais/Teste_Diagnostico_de_alfabetizacao_SL_e_AB_digital_PlanoAnual_ARQ_SELIGA_ANUAL.pdf',
                    },
                    {
                        nome: 'Orientacao_Teste_Diagnostico_ SL_e_AB_digital_PlanoAnual_ARQ_SELIGA_ANUAL',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_ SL_e_AB_digital_PlanoAnual_ARQ_SELIGA_ANUAL.pdf',
                    },
                    {
                        nome: 'Orientacao_Teste_Diagnostico_SL_e_AB_matematica',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_SL_e_AB_matematica_ARQ_SELIGA_ANUAL.pdf',
                    },
                    {
                        nome: 'Teste_Diagnostico_de_matematica_SL_e_AB',
                        arquivo: 'Materiais/Teste_Diagnostico_de_matematica_SL_e_AB_ARQ_SELIGA_ANUAL.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Pauta Formativa',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Pauta Formativa',
                arquivos: [
                    {
                        nome: 'PAUTA_FORMATIVA_Se_Liga_digital_PlanoAnual',
                        arquivo: 'Materiais/PAUTA_FORMATIVA_Se_Liga_digital_PlanoAnual_ARQ_SELIGA_ANUAL.pdf',
                    },
                    {
                        nome: 'PPT_DE_APOIO_SE_LIGA_PlanoAnual',
                        arquivo: 'Materiais/PPT_DE_APOIO_SE_LIGA_PlanoAnual_ARQ_SELIGA_ANUAL.ppt',
                    },
                ],
            },
        ],
    },
    {
        title: 'Subsídios',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'Se_Liga_Subsidios_para_o_inicio_do_programa_digital_PlanoAnual',
                        arquivo: 'Materiais/Se_Liga_Subsidios_para_o_inicio_do_programa_digital_PlanoAnual_ARQ_SELIGA_ANUAL.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sistemática de Acompanhamento',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'Sistematica_de_Acompanhamento_SL_AC_digital_PlanoAnual',
                        arquivo: 'Materiais/Sistematica_de_Acompanhamento_SL_AC_digital_PlanoAnual_ARQ_SELIGA_ANUAL.pdf',
                    },
                    { nome: 'Cartazes_Se_Liga_e_Acelera_A3_PF', arquivo: 'Materiais/Cartazes_Se_Liga_e_Acelera_A3_PF_ARQ_SELIGA.pdf' },
                    {
                        nome: 'Tabelas_Se Liga e Acelera_A4_PF',
                        arquivo: 'anexo_storage_files_16422537677049298333.pdf_2025-03-27-1743098293198.pdf',
                    },
                ],
            },
        ],
    },
];
