import React, { useContext, useEffect } from 'react';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import GlobalStyled from '../../AcessoExlusivo.style';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import OverviewExclusivo from '../components/OverviewExclusivo';
import CicloExclusivo from '../components/CicloExclusivo';
import formulaLogo from './../../../../images/solutions/logo/formulaDaVitoriaLogo.png';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import { ListMateriasFVPortuguesApoioCultural, ListMateriasFVPortuguesPlanoAnual } from './ListMateriasFVPortugues';
import ContactContainer from 'pages/home/components/contact/ContactContainer';

const FormulaDaVitoriaPortuguesPrivate = () => {
    const { loading, idsMyCategorias } = useContext(AcessoExclusivoContext.Context);
    const isMobile = IsMobileBiggerWidth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GlobalStyled.GlobalContainer isMobile={isMobile}>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        title="Fórmula da Vitória Língua Portuguesa"
                        img="ExclusivoFormulaPortImagem1.jpg"
                        logo={formulaLogo}
                        //arquivo={'anexo_storage_files_2839206562349981815.pdf_2024-11-27-1732735828100.pdf'}
                        nome_arquivo="Formula_Vitoria_Port_Visao_Geral"
                        description={`
                          O Fórmula da Vitória Língua Portuguesa tem como objetivo promover o desenvolvimento integral dos alunos que chegaram até os anos finais do Ensino Fundamental com deficiências básicas de aprendizagem em Língua Portuguesa.
                          </br> </br>
                          A solução educacional, de Língua Portuguesa, almeja: </br>
                          <ol style="list-style: inside disc;">
                            <li>Qualificar a linguagem oral e escrita dos alunos em contextos variados de interlocução;</li>
                            <li>Ampliar a capacidade linguística-discursiva dos alunos por meio do domínio de elementos regulares e constitutivos dos gêneros textuais.</li>
                          </ol>
                          </br>
                          Acesse o documento de visão geral para saber mais.
                        `}
                    />
                    <CicloExclusivo
                        title="Trilha Formativa Fórmula da Vitória Língua Portuguesa"
                        description={`
                          A trilha formativa da Fórmula da Vitória Língua Portuguesa apresenta os conteúdos da solução educacional de modo que os profissionais
                          (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                          Inicie agora a sua trilha formativa!
                        `}
                        img="ExclusivoFormulaPortImagem2.jpg"
                        itens={[
                            {
                                title: 'Trilha Formativa Fórmula da Vitória Língua Portuguesa',
                                url: '',
                                isBlock: true,
                                titleIsBlock: `Essa trilha formativa não está disponível.`,
                            },
                        ]}
                    />
                    <MateriaisExclusivos
                        title="Materiais especiais para download"
                        description={`  Tenha acesso aos materiais exclusivos do Fórmula da Vitória Português, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={'anexo_storage_files_500146459835730599.jpeg_2025-03-31-1743446437587.jpeg'}
                        items={
                            idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2))
                                ? ListMateriasFVPortuguesApoioCultural
                                : ListMateriasFVPortuguesPlanoAnual
                        }
                    />
                    <ContactContainer />
                </>
            )}
        </GlobalStyled.GlobalContainer>
    );
};

export default FormulaDaVitoriaPortuguesPrivate;
