import Colors from 'enums/Colors';
import styled from 'styled-components';

const NewMenuFixedStyled = {
    Container: styled.div<{ open: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        top: 91.5%;
        width: 100%;
        background-color: #ffffff;
        min-height: 80px;
        position: fixed;
        box-shadow: 0px 2px 4px -1px #00000033;
        box-shadow: 0px 4px 5px 0px #00000024;
        box-shadow: 0px 1px 10px 0px #0000001f;

        transition: all 0.5s;
        opacity: ${({ open }) => (open ? 1 : 0)};

        @media screen and (max-width: 1000px) {
          padding-bottom: 10px;
            top: 86%;
        }
        @media screen and (max-width: 620px) {
            align-items: start;
            justify-content: center;
            padding: 0px;
            top: 86%;
        }
       
    `,

    ContainerButton: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
        align-items: ${({ isMobile }) => (isMobile ? 'center' : 'normal')};
        justify-content: flex-start;
        column-gap: 25px;
        width: 1280px;

        @media screen and (max-width: 1280px) {
            width: 840px;
        }

        /* position: sticky;
        position: -webkit-sticky;
        z-index: 20;
        top: 100px; */
    `,

    ButtonSecondary: styled.button<{ light?: boolean; borderRadius?: string; width?: string; stylesPersonalizations?: React.CSSProperties }>`
        background-color: ${({ light }) => (light ? Colors.White : Colors.Yellow)};
        color: ${({ light }) => (light ? Colors.Yellow : Colors.Blue)};
        font-weight: 500;
        width: ${({ width }) => (width ? width : '280px')};
        min-height: 50px;
        border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '10px')};
        font-size: 15px;
        border: none;
        margin-top: 10px;
        ${({ stylesPersonalizations }) => ({ ...stylesPersonalizations })}

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

     ButtonPrimary: styled.button<{ light?: boolean, stylesPersonalizations?: React.CSSProperties }>`
            background-color: ${({ light }) => (light ? Colors.White : Colors.Blue)};
            color: ${({ light }) => (light ? Colors.Blue : Colors.White)};
            font-family: 'Barlow';
            width: 280px;
            min-height: 50px;
            font-weight: 500;
            border-radius: 10px;
            border: none;
            font-size: 15px;
            margin-top: 10px;
            ${({ stylesPersonalizations }) => ({ ...stylesPersonalizations })}
            &:hover {
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        `,
};

export default NewMenuFixedStyled;
