import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";
import { ComponentMarkText } from "./ComponentMarkText";


interface IComponentMark {
    codigo: number;
    isBullet: boolean;
    codigoComponentInteraction?: number;
    marks: ComponentMarkText[]
}

export class ComponentMark extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number, null)
    codigoComponentInteraction: number | null = -1;

    @jsonProperty(Boolean)
    isBullet: boolean = false;

    marks: ComponentMarkText[] = []

    fromJSON(json: IComponentMark, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.isBullet = json.isBullet;
        this.marks = json.marks;
        this.codigoComponentInteraction = json.codigoComponentInteraction ?? null;

        return this;
    }
}