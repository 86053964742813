import React, { useEffect } from 'react';
import { Provider as RollbarProvider } from '@rollbar/react';

import ReactDOM from 'react-dom';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from 'core/config/keycloak';
import './index.css';
import Routes from './Routes';
import PdpReducer from './store/reducers/Pdp';
import DiarioBordoReducer from 'store/reducers/Diary';
import ProfileReducer from './store/reducers/Profile';
import HomePageReducer from './store/reducers/HomePage';
import AuthenticationReducer from './store/reducers/Authentication';
import { AppStorage } from 'storage/AppStorage';
import { getEnvironmentType, isDevelopment } from 'constants/EnvironmentData';
import TagManager from 'react-gtm-module';
import { idGoogleTagManager } from 'constants/EnvironmentData';
import { Events } from 'components/GoogleTagManager';
import HomeProvider from 'pages/home/context/PesquisaProvider';
import TrackHotjar from 'hooks/TrackerHotjar';

//ROLLBAR
const rollbarConfig = {
    accessToken: 'ba3443856ea2480d81bc22394b4b0f27',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: getEnvironmentType(),
    },
};

//KEYCLOAK
const initOptionsKeycloak = {
    onload: 'login-required',
    checkLoginIframe: false,
};

const eventHandler = async (event: unknown, error: unknown) => {
    if (event === 'onAuthRefreshSuccess') {
    } else if (event === 'onAuthError') {
        keycloak.logout();
    }
};

//PERSIST
const persistConfig = {
    key: 'root',
    storage,
};

//GOOGLE TAG MANAGER
TagManager.initialize({
    gtmId: idGoogleTagManager(),
    events: Events(),
});

if (isDevelopment()) {
    const body = document.querySelector('body');

    const callback = (_: MutationRecord[], __: MutationObserver) => {
        if (body) {
            const frames = body?.getElementsByTagName('iframe');
            const ghostFrame = frames.item(frames.length - 1);

            if (ghostFrame && ghostFrame.style.position == 'fixed') {
                ghostFrame.remove();
                console.warn('Removeu frame fanstasma');
            }
        }
    };

    const observer = new MutationObserver(callback);
    const config = { attributes: false, childList: true, subtree: false };
    if (body) observer.observe(body, config);
}

const persistedCombinedReducer = persistCombineReducers(persistConfig, {
    pdpReducer: PdpReducer,
    profileReducer: ProfileReducer,
    homePageReducer: HomePageReducer,
    diarioReducer: DiarioBordoReducer,
    authenticationReducer: AuthenticationReducer,
});

const reduxStore = createStore(persistedCombinedReducer, applyMiddleware(ReduxThunk));
const persistor = persistStore(reduxStore);

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

ReactDOM.render(
    <>
        <React.StrictMode>
            <ReduxProvider store={reduxStore}>
                <PersistGate persistor={persistor}>
                    <RollbarProvider config={rollbarConfig}>
                        <ReactKeycloakProvider
                            initOptions={initOptionsKeycloak}
                            onTokens={async ({ token }) => {
                                AppStorage.saveAccessToken(token ?? '');
                            }}
                            onEvent={eventHandler}
                            authClient={keycloak}
                        >
                            <BrowserRouter>
                                <TrackHotjar />

                                <HomeProvider>
                                    <Routes />
                                </HomeProvider>
                            </BrowserRouter>
                        </ReactKeycloakProvider>
                    </RollbarProvider>
                </PersistGate>
            </ReduxProvider>
        </React.StrictMode>
    </>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
