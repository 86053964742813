import React, { useEffect } from 'react';
import Styled from './NewOverview.styled';
import { IsMediumSizeMaxWidth } from 'core/util/WindowSize';
import { isString } from 'lodash';

type TOverview = {
    title: string;
    descricao: string;
    objetivo: string;
    img: string;
    publicoAlvo: string;
    avaliacao: string;
    elementos: string;
    formacao: string;
    atores: string;
    acompanhamento: string;
    isBlue?: boolean;
    heightImg?: string;
    linkExclusivo?: string;
};
interface IOverview {
    dados: TOverview;
    name?: string;
    subname?: string;
}

const NewOverview = ({ dados, name, subname }: IOverview) => {
    const isMobile = IsMediumSizeMaxWidth();

    useEffect(() => {
        console.log(isMobile);
    }, [isMobile]);

    return (
        <div style={{ zIndex: 10 }}>
            <Styled.TitleContainer isMobile={isMobile}>
                <Styled.Img src={dados.img} />
                <Styled.NameContainer>
                    {name && <Styled.Name> {name} </Styled.Name>}
                    <Styled.SubName hasName={isString(name)} dangerouslySetInnerHTML={{__html: subname ?? ""}}/> 
                </Styled.NameContainer>
            </Styled.TitleContainer>

            <Styled.Container isMobile={isMobile}>
                <Styled.ContainerGroupContent isMobile={isMobile}>
                    <Styled.ContainerContent>
                        <Styled.Title>Como nasceu a solução?</Styled.Title>
                        <Styled.Description dangerouslySetInnerHTML={{ __html: dados.descricao }}></Styled.Description>
                    </Styled.ContainerContent>
                    <Styled.ContainerContent>
                        <Styled.Title>Objetivo</Styled.Title>
                        <Styled.Description dangerouslySetInnerHTML={{ __html: dados.objetivo }}></Styled.Description>
                    </Styled.ContainerContent>
                    <Styled.ContainerContent>
                        <Styled.Title>Acompanhamento</Styled.Title>
                        <Styled.Description dangerouslySetInnerHTML={{ __html: dados.acompanhamento }}></Styled.Description>
                    </Styled.ContainerContent>
                    <Styled.ContainerContent>
                        <Styled.Title>Público Alvo</Styled.Title>
                        <Styled.Description dangerouslySetInnerHTML={{ __html: dados.publicoAlvo }}></Styled.Description>
                    </Styled.ContainerContent>
                </Styled.ContainerGroupContent>
                <Styled.ContainerGroupContent isMobile={isMobile}>
                    <Styled.ContainerContent>
                        <Styled.Title>Formação</Styled.Title>
                        <Styled.Description dangerouslySetInnerHTML={{ __html: dados.formacao }}></Styled.Description>
                    </Styled.ContainerContent>
                    <Styled.ContainerContent>
                        <Styled.Title>Atores</Styled.Title>
                        <Styled.Description dangerouslySetInnerHTML={{ __html: dados.atores }}></Styled.Description>
                    </Styled.ContainerContent>
                    <Styled.ContainerContent>
                        <Styled.Title>Elementos</Styled.Title>
                        <Styled.Description dangerouslySetInnerHTML={{ __html: dados.elementos }}></Styled.Description>
                    </Styled.ContainerContent>
                </Styled.ContainerGroupContent>
            </Styled.Container>
        </div>
    );
};

export default NewOverview;
