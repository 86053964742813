import React, { useContext, useEffect } from 'react';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import GlobalStyled from './../../AcessoExlusivo.style';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import OverviewExclusivo from '../components/OverviewExclusivo';
import CicloExclusivo from '../components/CicloExclusivo';
import seLigaLogo from './../../../../images/solutions/logo/seLigaLogo.png';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import { ListMateriasSeLigaApoioCultural, ListMateriasSeLigaPlanoAnual } from './ListMateriasSeLiga';
import ContactContainer from 'pages/home/components/contact/ContactContainer';

const SeLigaPrivate = () => {
    const { loading, idsMyCategorias } = useContext(AcessoExclusivoContext.Context);
    const isMobile = IsMobileBiggerWidth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GlobalStyled.GlobalContainer isMobile={isMobile}>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        title="Se Liga"
                        img="ExclusivoSeLigaImagem1.jpg"
                        logo={seLigaLogo}
                        arquivo={
                            idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2))
                                ? ''
                                : 'anexo_storage_files_14780625101477645676.pdf_2025-03-31-1743451962761.pdf'
                        }
                        nome_arquivo="Se_Liga_Visao_Geral"
                        description={`
                          O Se Liga tem como objetivo alfabetizar alunos com distorção idade série, matriculados nas turmas do 3º ao 5º anos do Ensino Fundamental, oferecendo-lhes condições necessárias para o prosseguimento no fluxo escolar.  </br></br>
                          Cinco componentes essenciais do processo de alfabetização no Se Liga são:  </br>
                          <ol style="list-style: inside decimal;">
                            <li>A consciência fonológica</li>
                            <li>O princípio alfabético</li>
                            <li>A fluência na leitura</li>
                            <li>O vocabulário</li>
                            <li>A compreensão </li>
                          </ol>
                        ${idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2)) ? '' : 'Acesse o documento de visão geral para saber mais.'}  
                        `}
                        isButtonHidden={idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2)) ? true : false}
                    />
                    {/* ultima frase    .*/}

                    <CicloExclusivo
                        title="Curso Se Liga"
                        description={`
                         O curso Se Liga apresenta os conteúdos da solução educacional de modo que os profissionais (professores, coordenadores pedagógicos, diretores e/ou técnicos da rede pública de ensino) 
                         se apropriem dos conteúdos essenciais para a implementação da proposta. <br/> <br/> Inicie agora o curso!
                        `}
                        img="ExclusivoSeLigaImagem2.jpg"
                        itens={[
                            {
                                title: 'Curso Se Liga',
                                url: '/cursos/seliga/se-liga-na-alfabetizacao',
                                isBlock: false,
                                titleIsBlock: `Essa trilha formativa não está disponível.`,
                            },
                        ]}
                    />

                    <MateriaisExclusivos
                        title="Materiais especiais para download"
                        description={`  Tenha acesso aos materiais exclusivos do Se Liga, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={'anexo_storage_files_6046829472028057879.jpg_2025-03-27-1743101575344.jpg'}
                        items={
                            idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2))
                                ? ListMateriasSeLigaApoioCultural
                                : ListMateriasSeLigaPlanoAnual
                        }
                    />
                    <ContactContainer />
                </>
            )}
        </GlobalStyled.GlobalContainer>
    );
};

export default SeLigaPrivate;
