import React, { useEffect, useState } from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import Imagem from '../../assets/imagemTeste.svg';
import ImagemAcelera from '../../assets/aceleraBrasil/logo.png';

//Material
import imagem_01 from '../../assets/aceleraBrasil/material/imagem_01.png';
import aceleraBanner from '../../assets/aceleraBrasil/aceleraBanner.jpg';

//Material InPratice
import imagem_inpratice_01 from '../../assets/aceleraBrasil/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/aceleraBrasil/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/aceleraBrasil/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/aceleraBrasil/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/aceleraBrasil/inPratice/img_05.jpg';
import arrow from './../../assets/arrow.svg';

import ContactContainer from 'pages/home/components/contact/ContactContainer';
import HeaderSolution from 'pages/solucoes/components/newPageSolutionsComponents/HeaderSolution';
import NewOverview from 'pages/solucoes/components/newPageSolutionsComponents/NewOverview';
import NewInPractice from 'pages/solucoes/components/newPageSolutionsComponents/NewInPractice';
import NewSupportMaterial from 'pages/solucoes/components/newPageSolutionsComponents/NewSupportMaterial';
import NewFormSolucoes from 'pages/solucoes/components/newPageSolutionsComponents/NewFormSolucoes';
import useFetch from 'hooks/useFetch';
import { validAccessExclusivo } from 'pages/solucoes/helpers/FunctionsHelpersSolucoes';
import ResgatedToken from 'pages/solucoes/components/solucoes/ResgatedToken';

const AceleraBrasil = () => {
    const [openModalToken, setOpenModalToken] = useState<boolean>(false);
    const [modalContentExclusive, setModalContentExclusive] = useState<boolean>(false);

    const [access, setAccess] = useState<boolean>(false);

    useFetch(async () => {
        const status = await validAccessExclusivo();
        setAccess(status);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <HeaderSolution img={aceleraBanner} />
            <GlobalStyled.Container>
                <NewOverview
                    name={'ACELERA'}
                    subname={'BRASIL'}
                    dados={{
                        title: 'Acelera Brasil',
                        descricao: `
                          O Acelera Brasil foi o primeiro programa de educação formal implementado pelo Instituto Ayrton Senna em municípios brasileiros, 
                          que tiveram início em 1997 com o objetivo de combater a distorção idade-série e corrigir o fluxo escolar dos alunos. 
                          A solução educacional passou por um processo de atualização em 2023, considerando o cenário atual da educação e a integração com a BNCC.
                        `,
                        objetivo: `
                         O objetivo do Acelera Brasil é corrigir ou reduzir a distorção idade-ano com
                            a aceleração no fluxo escolar de estudantes matriculados do 3º ao 5º
                            ano, prioritariamente (salto possível de até dois anos escolares).
                         `,
                        publicoAlvo: '3º ao 5º do Ensino Fundamental.',
                        avaliacao: 'Testes Diagnósticos de Alfabetização (enturmação).',
                        formacao: `
                        Encontros formativos presenciais para professores e mediadores (carga
                        horária – 24 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos, ancorados em indicadores e
                            metas previamente definidas.
                        `,
                        atores: `
                        Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem); </br>
                        Mediador, Coordenador Pedagógico / Diretor (gestão da rotina escolar); </br> Secretaria
                        de Educação (gestão da política educacional).
                        `,
                        elementos: `
                        <ol>
                            <li>1º - Material didático do aluno e do professor, com fundamentos da
                            proposta e orientações específicas para o trabalho aula a aula, e base em currículo
                            para promover o desenvolvimento integral;</li>
                            <li>2º - Coordenação e gerenciamento;</li>
                            <li>3º - Formação presencial e a distância (EAD), inicial e continuada;</li>
                            <li>4º - Sistema de
                            monitoramento e avaliação, gerenciamento dos indicadores de aprendizagem;</li>
                            <li>5º - Apoio de agentes técnicos.</li>
                        </ol>
                        `,
                        img: ImagemAcelera,
                    }}
                />
                <NewInPractice
                    items={[
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_05,
                            credits: {
                                credits: 'Flávio Araújo',
                                local: 'Santarém',
                                ano: '2018',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Acelera Brasil acontece na prática?<br/><br/>
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.<br/><br/>
                    Sua rede pode ser a próxima a implementar a solução Acelera Brasil e ter as imagens compartilhadas aqui na humane. Que tal? 
                    `}
                    linkExclusivo="/exclusivo/acelera-brasil"
                    openModalToken={() => setOpenModalToken(true)}
                />
                <NewSupportMaterial
                    description={
                        <>
                            Na implementação do Acelera Brasil, a rede parceira recebe os seguintes materiais:
                            <br />
                            <ul style={{ listStyle: `inside url(${arrow})` }}>
                                <li>
                                    <strong>Cinco livros para o professor</strong>: Livros 1, 2, 3 e 4 do aluno e o Livro do Professor;
                                </li>
                                <li>
                                    <strong>Quatro livros para os estudantes</strong>: Livros 1, 2, 3 e 4;
                                </li>
                                <li>
                                    <strong>Subsídios para o professor</strong> para o período que antecede o início das aulas;
                                </li>
                                <li>
                                    <strong>Orientações para aplicação de Teste Diagnóstico;</strong>
                                </li>
                                <li>
                                    <strong>Sistemática de Acompanhamento</strong> para monitorar e gerenciar o ensino e a aprendizagem por meio
                                    de dados.
                                </li>
                            </ul>
                        </>
                    }
                    img={imagem_01}
                />

                <NewFormSolucoes
                    tipoSolucao={TipoSolucoesEnum.ACELERA_BRASIL}
                    preDefinition
                    linkExclusivo="/exclusivo/acelera-brasil"
                    accessExclusivo={access}
                    setOpenModalToken={() => setOpenModalToken(true)}
                />
            </GlobalStyled.Container>
            <ResgatedToken
                valueModalToken={openModalToken}
                setHidenModalToken={setOpenModalToken}
                openModalSolucoesExclusiva={modalContentExclusive}
                setOpenModalSolucoesExclusiva={setModalContentExclusive}
            />
            <ContactContainer />
        </div>
    );
};

export default AceleraBrasil;
