import { ComponentMarkText } from 'models/componentInteraction/ComponentMarkText';
import { GenericService } from '../Generic.service';

export class ComponentMarksService extends GenericService {
    readonly URI: string = '/v1/componentMark';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public novoTextMark(data: ComponentMarkText) {
        return this.post('novoMark', data);
    }

    public deleteById(idItem: number) {
        return this.delete(idItem);
    }
}
