import AmpliarImg from 'components/ampliarImg/AmpliarImg';
import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';
import React, { useState } from 'react';

import Styled from '../../AlfaTeoriaPratica.styled';

//IMG

import Img_01 from '../../assets/AlfaTeoriaPratica/img_01.jpg';
import { IModuloPage } from 'pages/ebooks/AlfaTeoriaPratica';
import DadosSequenciasImg from '../../assets/AlfaTeoriaPratica/dados_consequencias.png';
import Timeline01 from '../../assets/AlfaTeoriaPratica/timeline_01.png';
import Timeline02 from '../../assets/AlfaTeoriaPratica/timeline_02.png';

//Graficos
import Graf_01 from '../../assets/AlfaTeoriaPratica/grafico_01.png';
import Graf_02 from '../../assets/AlfaTeoriaPratica/grafico_02.png';
import Graf_03 from '../../assets/AlfaTeoriaPratica/grafico_03.png';
import Graf_04 from '../../assets/AlfaTeoriaPratica/grafico_04.png';

const InfoGraf_01 = 'anexo_storage_files_7309082749753816098.png_2025-03-24-1742825768453.png';

//Metricas
import Metrica_01 from '../../assets/AlfaTeoriaPratica/metrica_01.png';

import { Divider } from 'antd';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { fetchStorageFile } from 'hooks/StoreFiles';

const ATP_Modulo01 = ({ setImgAmpliar }: IModuloPage) => {
    return (
        <>
            <CapaEbook
                numberModulo="01"
                titulo={`
                    Caminhos da alfabetização no <br />
                    Brasil: uma análise histórica
            `}
                description={`
                    Beatriz Maria Campbell Alquéres, Brenda Prata, <br />
                    Daiane Zanon, Gabriela Cáceres, Inês Kisil Miskalo e <br />
                    Silvia Aparecida de Jesus Lima
            `}
            />
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                isQuote={{
                    have: true,
                }}
                footer={{
                    numberSummary: 9,
                }}
            >
                <Styled.Img src={Img_01} alt="" onClick={() => setImgAmpliar(Img_01)} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 10,
                }}
            >
                <Styled.TitleYellow>O histórico da alfabetização no Brasil</Styled.TitleYellow>
                <Styled.Description
                    dangerouslySetInnerHTML={{
                        __html: `
                    A alfabetização é um pilar fundamental do desenvolvimento humano e
                    social. Estar alfabetizado – o que passa por aprender a ler, escrever, comunicar
                    e interpretar textos de forma crítica – é condição primordial para o exercício pleno e independente da cidadania. <br/><br/>
                    No Brasil, a trajetória das políticas de alfabetização ao longo das décadas
                    é marcada por avanços, mas também por desafios significativos. Se por
                    um lado superamos a profunda desigualdade de acesso à escola, por outro
                    não fomos capazes de garantir uma alfabetização efetiva e equitativa para
                    todas as crianças. Este capítulo apresenta um panorama da alfabetização
                    no Brasil, por meio da análise de dados e das principais iniciativas governamentais que moldaram nossa trajetória até então. <br/><br/>
                    No passado, o acesso à educação pública no Brasil se restringiu a certas
                    classes, de modo que o aprendizado efetivo era um privilégio de poucos.
                    Com o aumento da urbanização, surgiram os primeiros esforços para ampliar
                    o acesso à educação e reduzir o analfabetismo. No entanto, esses esforços
                    iniciais enfrentaram muitas dificuldades, incluindo a falta de infraestrutura
                    e recursos adequados, além do próprio contexto cultural e social, que não
                    priorizava a educação para todos. Como resultado, o Brasil experimentou
                    um crescimento lento na taxa de alfabetização e até hoje não conseguiu
                    erradicar o analfabetismo.
                    
                    `,
                    }}
                />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 11,
                }}
            >
                <Styled.Description
                    dangerouslySetInnerHTML={{
                        __html: `
                      <strong>Gráfico 1:</strong> evolução da taxa de alfabetização da população de 15 anos ou mais 
                        (1940 a 2022)
                    
                    `,
                    }}
                />
                <Styled.Img src={Graf_01} alt="" onClick={() => setImgAmpliar(Img_01)} />
                <Styled.Credits>Fonte: IBGE – Censo Demográfico 1940/2022</Styled.Credits>

                <Styled.Description
                    dangerouslySetInnerHTML={{
                        __html: `
                   É importante destacar que, até a década de 1990, as políticas públicas de alfabetização se direcionavam majoritariamente ao cumprimento de compromissos assumidos pelo governo brasileiro com organismos internacionais. <br/><br/>
Dois exemplos significativos desse período foram:
                    
                    `,
                    }}
                />
                <Styled.ContentBlue>
                    A criação da Comissão do Ano Internacional da Alfabetização (CNAIA), que em 1989 produziu o documento Alfabetizar e libertar,
                    o qual propunha a alfabetização como ação política, e não apenas tarefa funcional, destacando a importância de uma educação
                    que promovesse a emancipação e a cidadania plenas.
                </Styled.ContentBlue>
                <Styled.ContentBlue>
                    A Declaração de Jomtien, de 1990, considerada um dos principais documentos mundiais sobre educação, que estabeleceu, entre
                    outros, os objetivos de fortalecer as necessidades básicas de aprendizagem de todas as crianças, jovens e adultos e de
                    universalizar e promover a equidade no acesso à educação básica. A declaração levou o Brasil a criar o Plano Decenal de
                    Educação para Todos em 1993.
                </Styled.ContentBlue>
                <Divider />
                <Styled.Credits style={{ textAlign: 'start' }}>
                    Gráfico retirado de:{' '}
                    <a
                        href="#"
                        onClick={() =>
                            window.open(
                                'https://agenciadenoticias.ibge.gov.br/agencia-noticias/2012-agencia-de-noticias/noticias/40098-censo-2022-taxa-de-analfabetismo-cai-de-9-6-para-7-0-em-12-anos-mas-desigualdades-persistem'
                            )
                        }
                    >
                        {' '}
                        https://agenciadenoticias.ibge.gov.br/agencia-noticias/2012-agencia-de-noticias/noticias/40098-censo-2022-taxa-de-analfabetismo-cai-de-9-6-para-7-0-em-12-anos-mas-desigualdades-persistem{' '}
                    </a>
                </Styled.Credits>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 12,
                }}
            >
                <Styled.Description
                    dangerouslySetInnerHTML={{
                        __html: `
                  Na década de 1990, portanto, priorizou-se a criação de políticas públicas de 
                redução do analfabetismo, já que um quarto da população de 8 anos de idade, ou 
                seja, crianças em idade escolar, não sabia ler nem escrever um bilhete simples. <br /><br />
                A partir de então, as iniciativas se diversificaram e ganharam amplitude, 
                moldando o cenário da alfabetização no Brasil pelas décadas seguintes.
                    
                    `,
                    }}
                />
                <Styled.ContentBorderBlue>
                    <span style={{ color: Colors.SoftBlue }}>Políticas públicas</span> são ações desencadeadas e coordenadas pelo Estado, nas
                    escalas federal, estadual e municipal, que têm como objetivo a superação das lacunas sociais que comprometem o
                    desenvolvimento humano. Uma política pública deve ser suficientemente abrangente para contemplar a diversidade de públicos,
                    mas devidamente específica para que seu objeto e objetivo sejam respeitados e atingidos num espaço de tempo predeterminado. É
                    importante que a política se baseie em evidências desde sua construção até a implementação, monitoramento e avaliação dos
                    resultados
                </Styled.ContentBorderBlue>
                <Styled.TitleYellow>
                    Políticas públicas e programas voltados para a melhoria da alfabetização implementados a partir da Constituição Federal de
                    1988
                </Styled.TitleYellow>
                <Styled.Description>A Constituição Federal de 1988 garantiu:</Styled.Description>
                <Styled.ContentBlue>
                    Art. 205. A educação, direito de todos e dever do Estado e da família, será promovida e incentivada com a colaboração da
                    sociedade, visando ao pleno desenvolvimento da pessoa, seu preparo para o exercício da cidadania e sua qualificação para o
                    trabalho. […]
                </Styled.ContentBlue>
                <Styled.ContentBlue>
                    Art. 208. O dever do Estado com a educação será efetivado mediante a garantia de: I – ensino fundamental, obrigatório e
                    gratuito, inclusive para os que a ele não tiveram acesso na idade própria; […]
                </Styled.ContentBlue>
                <Styled.ContentBlue>
                    Art. 214. A lei estabelecerá o plano nacional de educação, de duração decenal […] I – erradicação do analfabetismo; (Redação
                    dada pela Emenda Constitucional nº 59, de 2009) […]
                </Styled.ContentBlue>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 13,
                }}
            >
                <Styled.Img src={Timeline01} onClick={() => setImgAmpliar(Timeline01)} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 14,
                }}
            >
                <Styled.Img src={Timeline02} onClick={() => setImgAmpliar(Timeline02)} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 15,
                }}
            >
                <Styled.Description>
                    Apesar da criação e implementação de tantos programas, e embora a taxa de analfabetismo entre crianças de 8 anos houvesse
                    caído de 25,5% para 14,7% entre 1992 e 2023, a pandemia de covid-19 fez com que o número de crianças analfabetas voltasse a
                    patamares do final da década de 1990. Assim, novos desafios se incorporam aos antigos e precisam ser enfrentados para que
                    possamos avançar na alfabetização de nossas crianças e jovens, inclusive aqueles acima de 15 anos de idade, como estratégia
                    para superar desigualdades e oportunizar seu desenvolvimento pleno, não só no período escolar, mas também ao longo da vida.{' '}
                    <br /> <br />
                    <Styled.TextItalic>
                        {' '}
                        <strong>Gráfico 2:</strong> evolução da taxa de analfabetização da população de 8 anos (1992 a 2023)
                    </Styled.TextItalic>
                </Styled.Description>
                <Styled.Img src={Graf_02} onClick={() => setImgAmpliar(Graf_02)} />
                <Styled.Credits>
                    Fonte: elaborado pelos autores com base em dados da PNAD/IBGE (1992-2015) e PNADC/IBGE (2012-2023)
                </Styled.Credits>
                <Styled.Description>
                    É possível observar que a descontinuidade das políticas públicas deixa as redes e os profissionais sem um norte de atuação,
                    comprometendo continuamente o presente e o futuro das novas gerações. Para reverter essa situação, entendemos que é essencial
                    não apenas implementar e continuar as políticas públicas de alfabetização, como também lançar mão de processos de
                    monitoramento e avaliação que produzam insumos para indicar o que pode e deve ser aprimorado e garantir o sucesso delas.
                </Styled.Description>
                <Divider />
                <Styled.Credits>
                    Informações retiradas de:{' '}
                    <a
                        href="#"
                        onClick={() =>
                            window.open(
                                'https://www.ibge.gov.br/estatisticas/sociais/populacao/9127-pesquisa-nacional-poramostra-de-domicilios.html?edicao=18338&t=publicacoes'
                            )
                        }
                    >
                        https://www.ibge.gov.br/estatisticas/sociais/populacao/9127-pesquisa-nacional-poramostra-de-domicilios.html?edicao=18338&t=publicacoes
                    </a>
                </Styled.Credits>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 16,
                }}
            >
                <Styled.Description>
                    No caso brasileiro, em que a educação básica está sob a responsabilidade de estados e municípios, garantir educação de
                    qualidade e com equidade requer pensar de forma macro para atuar no micro. Hoje, cerca de 70% dos municípios do país têm até
                    20 mil habitantes, ou seja, se caracterizam por estrutura econômica mais fragilizada e corpo técnico mais restrito. Esses
                    municípios requerem ações ainda mais estruturadas e estruturantes do que as aplicadas nas grandes cidades. Em tal contexto,
                    ganha relevância o chamado regime de colaboração, que possibilita a construção e o desenvolvimento de ações complementares
                    entre os entes nacionais. <br /> <br /> A experiência mostra que a precariedade da alfabetização funciona como disparador de
                    um efeito dominó na vida do estudante, na medida em que o afasta do fluxo escolar pela reprovação, abandono e instalação da
                    distorção idade-série. Ou seja, a criança ou o jovem acaba por assumir um fracasso que dificilmente é seu, mas que lhe é
                    indevidamente atribuído na maioria das vezes.
                </Styled.Description>
                <Styled.TitleSolftBlue style={{ marginTop: 24 }}>Consequências da não alfabetização na idade certa</Styled.TitleSolftBlue>
                <Styled.ContentBlue style={{ lineHeight: '24px', backgroundColor: Colors.SoftBlue3 }}>
                    Um estudo conduzido pelo Insper em parceria com o Instituto Ayrton Senna revelou que adultos alfabetizados desfrutam de um
                    nível significativamente superior de qualidade de vida aos 35 anos em comparação a adultos não alfabetizados. Essa diferença
                    é evidenciada em diversas dimensões, incluindo saúde, participação no mercado de trabalho, renda per capita e educação dos
                    filhos2 . A seguir, apresentamos alguns dos principais resultados encontrados pelo estudo. <br />
                    <br />
                    <span style={{ color: Colors.Blue, fontWeight: '700' }}>Aos 35 anos…</span>
                    <Styled.Img style={{ borderRadius: 30 }} src={DadosSequenciasImg} onClick={() => setImgAmpliar(DadosSequenciasImg)} />
                </Styled.ContentBlue>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 17,
                }}
            >
                <Styled.TitleYellow>Cenário das avaliações</Styled.TitleYellow>
                <Styled.Description>
                    Apesar dos grandes avanços na universalização do acesso à educação da população de 6 a 17 anos , no Brasil o fato de crianças
                    e jovens frequentarem a escola não garante que estejam aprendendo. O baixo aprendizado dos estudantes é evidenciado pelos
                    testes padronizados, aplicados em larga escala pelo Ministério da Educação, como os que fazem parte do Sistema de Avaliação
                    da Educação Básica (Saeb). <br />
                    <br /> A seguir, observamos um item do teste de Língua Portuguesa presente na edição de 2019 do Saeb, aplicado para
                    localização explícita no texto, com o seguinte comando: “Onde a cotia corre?”. <br />
                    <br /> Em 2019, 64% dos estudantes do 2º ano do ensino fundamental de escolas públicas e privadas acertaram a questão, que
                    avalia a capacidade de ler e localizar informações explícitas em textos curtos.
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 18,
                }}
            >
                <Styled.Row>
                    <Styled.ContentBlue style={{ width: '160px', minHeight: '240px', maxHeight: '340px', borderRadius: '0px 20px 0px 20px' }}>
                        “Corre cotia <br /> Na casa da tia <br /> <br /> Corre cipó <br /> Na casa da avó <br />
                        <br /> Lencinho na mão <br /> Caiu no chão <br />
                        <br /> Moça bonita <br /> Do meu coração”
                    </Styled.ContentBlue>
                    <Styled.Description style={{ width: IsCustomMaxWidth(1100) ? '100%' : '480px' }}>
                        Na edição de 2021, devido aos efeitos da pandemia de Covid-19 e do fechamento das escolas, observou-se um aumento
                        estatisticamente significativo nos percentuais de estudantes nos níveis inferiores da escala de proficiências do Saeb.
                        Isso indica um aumento no número de estudantes que dominam pouco ou não dominam qualquer uma das habilidades de leitura e
                        escrita avaliadas no teste.
                    </Styled.Description>
                </Styled.Row>
                <Styled.Description>
                    Esses alunos não foram capazes, por exemplo, de ler e localizar informações em textos curtos, como o exemplo anterior, ou de
                    escrever corretamente palavras como “pássaro” ou “banana” a partir de um ditado. Paralelamente, houve uma queda
                    estatisticamente significativa nos percentuais de estudantes nos níveis mais elevados, que representam aqueles com as
                    habilidades mais altas. <br />
                    <br />
                    <Styled.TextItalic>
                        {' '}
                        <strong>Gráfico 3:</strong> distribuição percentual dos estudantes por níveis da escala de proficiência do Saeb em Língua
                        Portuguesa no 2º ano do ensino fundamental (2019 e 2021)
                    </Styled.TextItalic>
                </Styled.Description>
                <Styled.Img src={Graf_03} onClick={() => setImgAmpliar(Graf_03)} />
                <Styled.Credits>Fonte: Brasil</Styled.Credits>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 19,
                }}
            >
                <Styled.Description>
                    O Brasil tem uma das maiores taxas de reprovação escolar no mundo, porém, além disso, como revelam os dados do Saeb de 2021,
                    à medida que a idade das crianças avança em relação ao ano escolar, suas chances de alfabetização diminuem, ao passo que as
                    chances de ficarem presas em um ciclo de fracasso educacional aumentam. Estudantes de 8 anos ou mais que estão matriculados
                    no 2º ano do ensino fundamental devido a entrada tardia ou repetência obtêm, em média, 15 pontos a menos na avaliação. <br />
                    <br /> Os dados do Saeb também permitem observar desigualdades educacionais entre grupos sociodemográficos, reflexo das
                    disparidades socioeconômicas e raciais que permeiam a sociedade brasileira. Ao se analisar os resultados de estudantes com
                    diferentes níveis socioeconômicos, percebe-se que aqueles cujos pais têm maior nível de escolaridade e que vivem em
                    domicílios mais confortáveis têm um desempenho médio em alfabetização 22 pontos superior ao de crianças de famílias mais
                    vulneráveis. Adicionalmente, estudantes brancos tendem a obter, em média, 19 pontos a mais em alfabetização em comparação a
                    estudantes pretos, pardos e indígenas. Essas diferenças não ocorrem apenas entre regiões, estados e municípios, mas também
                    dentro de um mesmo território, especialmente em grandes cidades, e até dentro das unidades escolares. <br />
                    <br />
                    <Styled.TextItalic>
                        <strong> Tabela:</strong> desempenho médio em Língua Portuguesa no Saeb, segundo o perfil dos estudantes – 2021
                    </Styled.TextItalic>
                </Styled.Description>
                <Styled.Img src={Metrica_01} onClick={() => setImgAmpliar(Metrica_01)} />
                <Styled.Credits>Fonte: elaborado pelos autores com base em Basso e Rodrigues.</Styled.Credits>
                <Styled.Description>
                    Em um país tão diverso como o Brasil, os dados de alfabetização evidenciam a necessidade urgente de ações mais inclusivas e
                    equitativas. É fundamental considerar o estudante como um indivíduo de direitos e criar condições para a superação das
                    barreiras que limitam o acesso a uma educação
                </Styled.Description>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 20,
                }}
            >
                <Styled.Description>
                    pública de qualidade. Enquanto estados como Ceará e Santa Catarina têm alcançado avanços significativos na alfabetização de
                    crianças, nota-se com preocupação que em estados como Tocantins, Acre e Amapá apenas 2 em cada 10 estudantes desenvolvem as
                    habilidades necessárias para serem considerados alfabetizados. Nas áreas rurais de Rondônia e Roraima, esse número é ainda
                    menor: apenas 1 em cada 10 estudantes alcança tal nível de proficiência. Vale ressaltar que, em nosso país, ser alfabetizado
                    representa muito mais do que ter o domínio da leitura e da escrita e a capacidade de calcular e resolver problemas;
                    representa fazer parte de uma fatia privilegiada socialmente, à qual não foi negado o direito ao acesso ao ensino das letras.{' '}
                    <br />
                    <br />
                    <Styled.TextItalic>
                        <strong> Mapa 1:</strong> taxa de alfabetização, por unidade da Federação – 2021
                    </Styled.TextItalic>
                </Styled.Description>
                <Styled.Img src={Graf_04} onClick={() => setImgAmpliar(Graf_04)} />
                <Styled.Credits>Fonte: elaborado pelas autoras com base em Saeb/Inep (2021)</Styled.Credits>
                <Divider />
                <Styled.Credits>
                    Dados obtidos em{' '}
                    <a href="#" onClick={() => window.open('https://www.gov.br/inep/pt-br/acesso-a-informacao/dados-abertos/microdados/saeb')}>
                        https://www.gov.br/inep/pt-br/acesso-a-informacao/dados-abertos/microdados/saeb
                    </a>
                </Styled.Credits>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 21,
                }}
            >
                <Styled.Description>
                    É possível utilizar os dados do Saeb para determinar se uma criança está alfabetizada ou não graças à Pesquisa Alfabetiza
                    Brasil, conduzida recentemente pelo Instituto Nacional de Estudos e Pesquisas Educacionais Anísio Teixeira (Inep). Esse
                    estudo estabeleceu o ponto de corte para definir a alfabetização de uma criança ao final do 2º ano do ensino fundamental,
                    estipulado em 743 pontos na escala do Saeb. Crianças que alcançam essa pontuação demonstram habilidades de apropriação do
                    sistema de escrita alfabética, ou seja, são leitores e escritores iniciantes que interagem de forma autônoma com textos do
                    cotidiano e artísticos-literários e participam de práticas de leitura e escrita características do letramento escolar . Esse
                    critério serve como referência para estabelecer metas anuais para os estados brasileiros no âmbito do programa federal
                    Compromisso Nacional Criança Alfabetizada e para aprimorar as ações de monitoramento das políticas públicas de alfabetização,
                    visando assegurar a alfabetização de todas as crianças até 2030. <br />
                    <br /> Seguindo nessa direção, os esforços do MEC para alinhar as escalas das avaliações estaduais com o Saeb permitiram
                    aprimorar o monitoramento das políticas de alfabetização e, assim, obter os resultados censitários de alunos do 2º ano do
                    ensino fundamental em 24 unidades da Federação. Os resultados de 2023 indicaram uma recuperação ao nível pré-pandemia na
                    média nacional de crianças alfabetizadas na idade adequada, que aumentou de 55% em 2019 para 56% em 2023. Dos 24 estados que
                    realizaram as avaliações, 79% mostraram melhorias, refletindo o sucesso das políticas que vêm sendo implementadas. <br />
                    <br /> A disponibilização desses conceitos e resultados é o primeiro passo para a construção de uma política de alfabetização
                    efetiva. Os governos federal, estaduais e municipais e a sociedade como um todo precisam se apropriar dessas informações e
                    dar continuidade a esse monitoramento para que todas as crianças brasileiras tenham seu direito à alfabetização garantido,
                    independentemente de região, cor/raça, gênero e condição socioeconômica.
                </Styled.Description>

                <Divider />
                <Styled.Credits style={{ textAlign: 'start' }}>
                    Para saber mais sobre a avaliação da alfabetização no âmbito do Compromisso Criança Alfabetizada, acesse: <br />
                    <a
                        href="#"
                        onClick={() =>
                            window.open(
                                'https://www.gov.br/inep/pt-br/areas-de-atuacao/avaliacao-e-exames-educacionais/avaliacao-da-alfabetizacao.'
                            )
                        }
                    >
                        https://www.gov.br/inep/pt-br/areas-de-atuacao/avaliacao-e-exames-educacionais/avaliacao-da-alfabetizacao.
                    </a>
                    <br />
                    Acesso em: 28 jun. 2024.
                </Styled.Credits>
                <Styled.Credits style={{ textAlign: 'start' }}>
                    Para saber mais sobre os resultados de alfabetização obtidos com base nas avaliações censitárias estaduais, acesse: <br />
                    <a href="#" onClick={() => window.open('https://www.gov.br/mec/pt-br/crianca-alfabetizada')}>
                        https://www.gov.br/mec/pt-br/crianca-alfabetizada
                    </a>
                    <br />
                    Acesso em: 2 jul. 2024.
                </Styled.Credits>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 22,
                }}
            >
                <Styled.TitleSolftBlue>Avaliação como método de monitoramento e aprimoramento de políticas públicas</Styled.TitleSolftBlue>
                <Styled.ContentBlue style={{ backgroundColor: Colors.SoftBlue3 }}>
                    As primeiras experiências de avaliação externa do ciclo de alfabetização no Brasil surgem em 1990, com o primeiro ciclo do
                    Sistema de Avaliação da Educação Básica. No entanto, os testes para essa etapa de ensino não foram mantidos nas edições
                    subsequentes. Em 2008, o Inep retomou a avaliação focada na alfabetização através da Provinha Brasil, no âmbito do Plano de
                    Metas Compromisso Todos pela Educação. A Provinha Brasil, aplicada voluntariamente a alunos do 2º ano do ensino fundamental
                    de escolas públicas e foi descontinuada em 2016. <br />
                    <br />
                    Em 2013, em resposta ao Pacto Nacional pela Alfabetização na Idade Certa, o Inep lançou a Avaliação Nacional da Alfabetização
                    (ANA) visando avaliar os alunos do 3º ano do ensino fundamental da rede pública. A ANA foi aplicada até 2016. Com a
                    homologação da BNCC em 2017, a avaliação do ciclo de alfabetização do Saeb foi ajustada para focar os dois primeiros anos do
                    ensino fundamental, resultando na criação do Saeb 2º ano, aplicado pela primeira vez em 2019. As matrizes de referência dos
                    testes cognitivos foram reformuladas para se alinhar à BNCC, e a avaliação seguiu a
                    periodicidade bianual, como ocorre nos demais ciclos avaliados pelo Saeb, tais como 5º ano e 9º ano do ensino fundamental e
                    3ª série do Ensino Médio. <br />
                    <br />
                    Em 2023, o MEC avançou no uso dos resultados de avaliações para monitorar e aprimorar políticas públicas ao conduzir a
                    pesquisa Alfabetiza Brasil. Esta pesquisa estabeleceu o ponto de corte na escala do Saeb, o qual indica se uma criança está
                    alfabetizada ao final do 2º ano do ensino fundamental. A iniciativa permitiu alinhar as medidas de alfabetização do Saeb com
                    as avaliações implementadas pelos estados anualmente e de forma censitária, proporcionando aos governos federal, estaduais e
                    municipais uma ferramenta poderosa para definir metas claras de alfabetização e viabilizar o monitoramento contínuo da
                    qualidade do ensino em todo o país.
                </Styled.ContentBlue>
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 23,
                }}
            >
                <Styled.TitleYellow>Caminhos para políticas eficazes de alfabetização</Styled.TitleYellow>
                <Styled.Description>
                    Combinando a perspectiva histórica com os dados atuais e somando ainda os aprendizados conquistados nos 30 anos de atuação do
                    Instituto Ayrton Senna com alfabetização, temos a oportunidade de delinear alguns caminhos promissores para boas políticas de
                    alfabetização, que dividiremos, para fins didáticos, em etapas:
                </Styled.Description>
                <Styled.Img src={fetchStorageFile(InfoGraf_01)} onClick={() => setImgAmpliar(fetchStorageFile(InfoGraf_01))} />
            </PageEbook>
            <PageEbook
                header={{
                    firstText: 'Alfabetizar: das definições às boas práticas ',
                    secoundText: 'Caminhos da alfabetização no Brasil: uma análise histórica',
                }}
                footer={{
                    numberSummary: 24,
                }}
            >
                <Styled.Description>
                    Além das etapas destacadas anteriormente, é fundamental estabelecer um referencial curricular claro e objetivo, alinhado com
                    a Base Nacional Comum Curricular (BNCC) e o Compromisso Nacional Criança Alfabetizada, mas que também leve em consideração as
                    especificidades locais e culturais de cada região e os conhecimentos já consolidados e disponíveis acerca dos aspectos
                    cognitivos e conceituais envolvidos no ensino e aprendizagem da alfabetização. <br />
                    <br /> Para finalizar, vale reforçar que garantir que todas as crianças tenham acesso a uma educação de qualidade e sejam
                    plenamente alfabetizadas não é apenas uma questão educacional, mas um imperativo social. A alfabetização é o alicerce sobre o
                    qual se erguem a cidadania plena e o desenvolvimento humano sustentável. É responsabilidade do Estado, das instituições
                    educacionais e da sociedade como um todo unir esforços para superar os desafios e construir um futuro mais promissor e
                    equitativo para as próximas gerações.
                </Styled.Description>
            </PageEbook>
        </>
    );
};

export default ATP_Modulo01;
