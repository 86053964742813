import { Quill } from 'react-quill';

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '24px', '32px'];
Quill.register(Size, true);

export default {
    modules: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: Size.whitelist }],
            [
                'bold',
                'italic',
                'underline',
                'link',
                {
                    color: [
                        '#ffca00',
                        '#003c78',
                        '#00a8e9',
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                    ],
                },
                {
                    background: [
                        '#f6f6f6',
                        '#ffca00',
                        '#003c78',
                        '#00a8e9',
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                    ],
                },
                { list: 'ordered' },
                { list: 'bullet' },
            ],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['image', 'blockquote', 'video'],
            ['clean'],
        ],
    },

    modules2: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: Size.whitelist }],
            [
                'bold',
                'italic',
                'link',
                {
                    color: [
                        '#ffca00',
                        '#003c78',
                        '#00a8e9',

                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',

                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                    ],
                },
                {
                    background: [
                        '#f6f6f6',
                        '#ffca00',
                        '#003c78',
                        '#00a8e9',
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                    ],
                },
                { list: 'ordered' },
                { list: 'bullet' },
            ],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['blockquote'],
            ['clean'],
        ],
    },
    modulesSimples: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: Size.whitelist }],
            [
                'bold',
                'italic',
                'link',
                {
                    color: [
                        '#ffca00',
                        '#003c78',
                        '#00a8e9',

                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',

                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                    ],
                },
            ],
        ],
    },

    formats: [
        'header',
        'size',
        'bold',
        'italic',
        'underline',
        'color',
        'background',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'align',
    ],

    formats2: [
        'header',
        'size',
        'bold',
        'italic',
        'underline',
        'color',
        'background',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'align',
        'style',
    ],

    formatsSimples: ['header', 'size', 'bold', 'italic', 'underline', 'color'],
};
