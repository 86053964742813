type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
};

export const ListMateriasAceleraApoioCultural: TItem[] = [
    {
        title: 'Materiais Didáticos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Materiais Didáticos',
                arquivos: [
                    {
                        nome: 'Acelera_livro1_MinhaIdentidade_digital_ApoioCultural',
                        arquivo: 'Materiais/Acelera_livro1_MinhaIdentidade_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'Acelera_livro2_Criacoes_digital_ApoioCultural',
                        arquivo: 'Materiais/Acelera_livro2_Criacoes_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'Acelera_livro3_NossaHist_digital_ApoioCultural',
                        arquivo: 'Materiais/Acelera_livro3_NossaHist_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'Acelera_livro4_ATerra_digital_ApoioCultural',
                        arquivo: 'Materiais/Acelera_livro4_ATerra_digital_ApoioCultural.pdf',
                    },
                    { nome: 'Acelera_livroProfessor_ApoioCultural', arquivo: 'Materiais/Acelera_livroProfessor_ApoioCultural.pdf' },
                ],
            },
        ],
    },

    {
        title: 'Teste Diagnóstico',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Teste Diagnóstico',
                arquivos: [
                    {
                        nome: 'Orientacao_Teste_Diagnostico_SL_e_AB_digital_ApoioCultural',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_SL_e_AB_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'Teste_Diagnostico_de_alfabetizacao_SL_e_AB_ApoioCultural',
                        arquivo: 'Materiais/Teste_Diagnostico_de_alfabetizacao_SL_e_AB_ApoioCultural.pdf',
                    },
                    {
                        nome: 'Orientacao_Teste_Diagnostico_SL_e_AB_matematica',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_SL_e_AB_matematica.pdf',
                    },
                    { nome: 'Teste_Diagnostico_de_matematica_SL_e_AB', arquivo: 'Materiais/Teste_Diagnostico_de_matematica_SL_e_AB.pdf' },
                ],
            },
        ],
    },
    {
        title: 'Pauta Formativa',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Pauta Formativa',
                arquivos: [
                    {
                        nome: 'PAUTA_FORMATIVA_Acelera_digital_ApoioCultural',
                        arquivo: 'Materiais/PAUTA_FORMATIVA_Acelera_digital_ApoioCultural.pdf',
                    },
                    { nome: 'PPT_DE_APOIO_ACELERA_ApoioCultural', arquivo: 'PPT_DE_APOIO_ACELERA_ApoioCultural.ppt' },
                ],
            },
        ],
    },
    {
        title: 'Subsídios',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'Acelera_Subsidios_para_o_inicio_do_programa_digital_ApoioCultural',
                        arquivo: 'Materiais/Acelera_Subsidios_para_o_inicio_do_programa_digital_ApoioCultural.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sistemática de Acompanhamento',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Sistemática de Acompanhamento',
                arquivos: [
                    {
                        nome: 'Sistematica_de_Acompanhamento_SL_AC_digital_ApoioCultural',
                        arquivo: 'Materiais/Sistematica_de_Acompanhamento_SL_AC_digital_ApoioCultural.pdf',
                    },
                    {
                        nome: 'Cartazes_Se_Liga_e_Acelera_A3_PF',
                        arquivo: 'Materiais/Cartazes_Se_Liga_e_Acelera_A3_PF.pdf',
                    },
                    {
                        nome: 'Tabelas_Se Liga e Acelera_A4_PF',
                        arquivo: 'anexo_storage_files_2871054107129682821.pdf_2025-03-28-1743173725766.pdf',
                    },
                ],
            },
        ],
    },

    {
        title: 'Guia Letramento Científico',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'Guia_Letramento_Cientifico_Acelera_Brasil_digital_ApoioCultural',
                        arquivo: 'Materiais/Guia_Letramento_Cientifico_Acelera_Brasil_digital_ApoioCultural.pdf',
                    },
                ],
            },
        ],
    },
];

export const ListMateriaisAceleraPlanoAnual: TItem[] = [
    {
        title: 'Materiais Didáticos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Materiais Didáticos',
                arquivos: [
                    {
                        nome: 'Acelera_livro1_MinhaIdentidade_digital_PlanoAnual',
                        arquivo: 'Materiais/Acelera_livro1_MinhaIdentidade_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'Acelera_livro2_Criacoes_digital_PlanoAnual',
                        arquivo: 'Materiais/Acelera_livro2_Criacoes_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'Acelera_livro3_NossaHist_digital_PlanoAnual',
                        arquivo: 'Materiais/Acelera_livro3_NossaHist_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'Acelera_livro4_ATerra_digital_PlanoAnual',
                        arquivo: 'Materiais/Acelera_livro4_ATerra_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'Acelera_livroProfessor_PlanoAnual',
                        arquivo: 'Materiais/Acelera_livroProfessor_PlanoAnual.pdf',
                    },
                ],
            },
        ],
    },

    {
        title: 'Teste Diagnóstico',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Teste Diagnóstico',
                arquivos: [
                    {
                        nome: 'Teste_Diagnostico_de_alfabetizacao_SL_e_AB_PlanoAnual',
                        arquivo: 'Materiais/Teste_Diagnostico_de_alfabetizacao_SL_e_AB_PlanoAnual.pdf',
                    },
                    {
                        nome: 'Orientacao_Teste_Diagnostico_SL_e_AB_digital_PlanoAnual',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_SL_e_AB_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'Orientacao_Teste_Diagnostico_SL_e_AB_matematica',
                        arquivo: 'Materiais/Orientacao_Teste_Diagnostico_SL_e_AB_matematica.pdf',
                    },
                    {
                        nome: 'Teste_Diagnostico_de_matematica_SL_e_AB',
                        arquivo: 'Materiais/Teste_Diagnostico_de_matematica_SL_e_AB.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Pauta Formativa',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Pauta Formativa',
                arquivos: [
                    {
                        nome: 'PAUTA_FORMATIVA_Acelera_digital_PlanoAnual',
                        arquivo: 'Materiais/PAUTA_FORMATIVA_Acelera_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'PPT_DE_APOIO_ACELERA_PlanoAnual',
                        arquivo: 'Materiais/PPT_DE_APOIO_ACELERA_PlanoAnual.ppt',
                    },
                ],
            },
        ],
    },
    {
        title: 'Subsídios',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'Acelera_Subsidios_para_o_inicio_do_programa_digital_PlanoAnual',
                        arquivo: 'Materiais/Acelera_Subsidios_para_o_inicio_do_programa_digital_PlanoAnual.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sistemática de Acompanhamento',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Sistemática de Acompanhamento',
                arquivos: [
                    {
                        nome: 'Sistematica_de_Acompanhamento_SL_AC_digital_PlanoAnual',
                        arquivo: 'Materiais/Sistematica_de_Acompanhamento_SL_AC_digital_PlanoAnual.pdf',
                    },
                    {
                        nome: 'Cartazes_Se_Liga_e_Acelera_A3_PF',
                        arquivo: 'Materiais/Cartazes_Se_Liga_e_Acelera_A3_PF.pdf',
                    },
                    {
                        nome: 'Tabelas_Se Liga e Acelera_A4_PF',
                        arquivo: 'anexo_storage_files_2615790127463448074.pdf_2025-03-28-1743174145329.pdf',
                    },
                ],
            },
        ],
    },

    {
        title: 'Guia Letramento Científico',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'Guia_Letramento_Cientifico_Acelera_Brasil_digital_PlanoAnual',
                        arquivo: 'Materiais/Guia_Letramento_Cientifico_Acelera_Brasil_digital_PlanoAnual.pdf',
                    },
                ],
            },
        ],
    },
];
