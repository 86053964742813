import React, { useState } from 'react';
import Styled from './NewInPractice.styled';
import Slider from 'react-slick';
import Colors from 'enums/Colors';
import { IsMediumSizeMaxWidth } from 'core/util/WindowSize';
import { FaPlay } from 'react-icons/fa';
import ButtonExclusivo from './../generics/ButtonExclusivo';
import naPraticaSvg from './../../assets/naPratica.svg';

export enum TypeCard {
    VIDEO = 'VIDEO',
    IMAGEM = 'IMAGEM',
}

type TCredits = {
    credits: string;
    local: string;
    ano: string;
};
export interface ICardInPractice {
    type: TypeCard;
    url: string;
    credits?: TCredits;
}

const CardInPractice = ({ type, url }: ICardInPractice) => {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    return (
        <Styled.CardInPractice.Container>
            {type === TypeCard.IMAGEM ? (
                <Styled.Img src={url} />
            ) : (
                <>
                    {!isVideoPlaying ? (
                        <>
                            <img
                                src={`https://img.youtube.com/vi/${url}/hqdefault.jpg`}
                                alt="Thumbnail do vídeo do YouTube"
                                onClick={() => setIsVideoPlaying(true)} // Atualiza o estado para mostrar o vídeo
                                style={{ cursor: 'pointer', width: '100%', height: '100%', borderRadius: '20px' }} // Adiciona um cursor de ponteiro
                            />
                            <Styled.Play onClick={() => setIsVideoPlaying(true)}>
                                <FaPlay size={44} />
                            </Styled.Play>
                        </>
                    ) : (
                        <iframe
                            style={{ width: '100%', height: '100%', borderRadius: '20px' }}
                            src={`https://www.youtube.com/embed/${url}?autoplay=1`} // Adiciona autoplay ao iframe
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="YouTube video"
                        />
                    )}
                </>
            )}
        </Styled.CardInPractice.Container>
    );
};

interface IInPractice {
    description: string;
    items: ICardInPractice[];
    linkExclusivo?: string;
    openModalToken?: () => void;
}

const NewInPractice = ({ description, items, linkExclusivo, openModalToken }: IInPractice) => {
    const isMobile = IsMediumSizeMaxWidth();

    const paging = (index: number) => {
        const card: ICardInPractice = items[index];
        return (
            <Styled.ImageContainer>
                <a>
                    <Styled.Paging src={card.type == TypeCard.VIDEO ? `https://img.youtube.com/vi/${card.url}/hqdefault.jpg` : card.url} />
                </a>
            </Styled.ImageContainer>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: paging,
        adaptiveHeight: true,
        arrows: false,
    };

    return (
        <Styled.ColorContainer>
            <Styled.Container isMobile={isMobile}>
                <Styled.DescriptionContainer>
                    <Styled.ImageInPractice src={naPraticaSvg} />
                    <Styled.Description dangerouslySetInnerHTML={{ __html: description }}></Styled.Description>
                    {!isMobile && (
                        <ButtonExclusivo
                            marginTop="36px"
                            handleButtonActiveModalToken={openModalToken}
                            linkExclusivo={linkExclusivo ?? ''}
                            bgColor={Colors.Yellow}
                        />
                    )}
                </Styled.DescriptionContainer>
                <Styled.CarouselContainer isMobile={isMobile}>
                    <Styled.Carrousel
                        style={{
                            display: items.length > 0 ? 'flex' : 'none',
                        }}
                    >
                        <Styled.CarouselCardContainer isMobile={isMobile} bigSlick={items.length > 6}>
                            <Slider {...settings}>
                                {items.length > 0 &&
                                    items.map((dados, index) => (
                                        <>
                                            <CardInPractice key={index} type={dados.type} url={dados.url} />
                                            {dados.credits && (
                                                <Styled.credits>
                                                    Créditos: {dados.credits.credits} - {dados.credits.local}, {dados.credits.ano}
                                                </Styled.credits>
                                            )}
                                        </>
                                    ))}
                            </Slider>
                        </Styled.CarouselCardContainer>
                    </Styled.Carrousel>
                </Styled.CarouselContainer>
            </Styled.Container>
        </Styled.ColorContainer>
    );
};

export default NewInPractice;
