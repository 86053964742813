import { FadAvaliationsModel } from "models/ferramentaAutoria/FadAvaliationsModel";
import { GenericService } from "../Generic.service";


export class FadAvaliationsService extends GenericService {
    readonly URI: string = '/v1/fadAvaliations';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadAvaliationsModel) {
        return await this.post('', model);
    }

    async findAnexoByCourse(codigo: number) {
        return await this.get('course/' + codigo, { isPublic: false });
    }
}
