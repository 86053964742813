import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import React, { useEffect, useRef, useState } from 'react';

import Styled from './Solucoes.styled';
import Apresentation from './components/solucoes/Apresentation';
import ParceirosImplementadores from './components/solucoes/ParceirosImplementadores';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import ResgatedToken from './components/solucoes/ResgatedToken';
import AboutSolutions from './components/solucoes/AboutSolutions';
import OfferedSolutions from './components/solucoes/OfferedSolutions';
import NewFormSolucoes from './components/newPageSolutionsComponents/NewFormSolucoes';
import NewMenuFixed from './components/newPageSolutionsComponents/NewMenuFixed';

const Solucoes = () => {
    const sectionRef = useRef<HTMLDivElement | null>(null);
    
    const divRef = useRef(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [showComponent, setShowComponent] = useState<boolean>(false);
    
    const [openModalToken, setOpenModalToken] = useState<boolean>(false);
    const [modalContentExclusive, setModalContentExclusive] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 } // Define o quanto da div precisa estar visível
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowComponent(true);
        }, 1000); // 1 segundo

        return () => clearTimeout(timer); // Limpa o timer se o componente desmontar
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scroolToSolutions = () => {
        if (sectionRef.current) {
            const yOffset = -80; // Ajuste de 50px para cima
            const y = sectionRef.current.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <>
            <DynamicHeader />
            <Styled.ContainerSolutions>
                <Apresentation setModalTokenOpen={setOpenModalToken} />
                {/* <MultiSolucoes height={ IsCustomMaxWidth(480) ? '1200px' : '1000px'} /> DEIXOU DE SER UTILIZADO */}
                <AboutSolutions
                    scroolToSolutions={scroolToSolutions}
                    setModalContentExclusive={() => setModalContentExclusive(true)}
                    setModalTokenOpen={setOpenModalToken}
                    menuFixed={<div ref={divRef} />}
                />
                <div ref={sectionRef}>
                    <OfferedSolutions ref={sectionRef} />
                </div>
                <ParceirosImplementadores />
                <NewFormSolucoes preDefinition={false} setOpenModalToken={() => setOpenModalToken(true)} />
                <ResgatedToken
                    valueModalToken={openModalToken}
                    setHidenModalToken={setOpenModalToken}
                    openModalSolucoesExclusiva={modalContentExclusive}
                    setOpenModalSolucoesExclusiva={setModalContentExclusive}
                />
                {showComponent && (
                    <NewMenuFixed openMenu={!isVisible} scroolToSolutions={scroolToSolutions} setModalTokenOpen={setModalContentExclusive} />
                )}
            </Styled.ContainerSolutions>
            <ContactContainer />
        </>
    );
};

export default Solucoes;
