import styled from 'styled-components';

const StyleCircuito = {
    Container: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        margin-top: ${({ isMobile }) => (isMobile ? '60px' : '85px')};
    `,
};

export default StyleCircuito;
