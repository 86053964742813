import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledOfferedSolutions = {
    Container: styled.div`
        display: flex;
        justify-content: center;
        background-color: ${Colors.BackGroundWhite};
        padding-bottom: 65px;
    `,

    Content: styled.div`
        max-width: 1286px;
        gap: 21px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    `,

    CardSolution: styled.div<{ isMobile: boolean }>`
        height: 478px;
        width: ${({ isMobile }) => (isMobile ? 'unset' : '412px')} ;
        max-width: 412px;
        margin: ${({ isMobile }) => (isMobile ? '0px 15px' : 'unset')};
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 10px 28px 8px 28px;
    `,

    Logo: styled.div<{ img: string }>`
        background: url(${({ img }) => img});
        width: 110px;
        height: 110px;
        background-size: contain;
        background-repeat: no-repeat;
    `,

    CardTitle: styled.h1`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: ${Colors.Blue};
    `,
    CardDescription: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: rgba(0, 0, 0, 0.58);
    `,

    ButtonContainer: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 30%;
        gap: 15px;
    `,

    TagContainer: styled.div`
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-self: flex-start;
        height: 13%;
        align-items: center;

        p {
          height: fit-content;
        }
    `,

    Tag: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 26px;
        color: ${Colors.Blue};
        border: 1px solid #003c78;
        border-radius: 100px;
        padding: 0px 15px;
    `,

    Button: styled.button<{bgColor: Colors, textColor: Colors}>`
        font-family: 'Barlow';
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: ${({bgColor}) => bgColor};
        color: ${({textColor}) => textColor};
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        min-width: 167px;
        min-height: 46px;
        padding: 10px 20px;
        gap: 0.5rem;
    `,
};

export default StyledOfferedSolutions;
