import { FadAvaliationsComponentModel } from 'models/ferramentaAutoria/FadAvaliationsComponentModel';
import { GenericService } from '../Generic.service';

export class FadAvaliationsComponentService extends GenericService {
    readonly URI: string = '/v1/fadAvaliationComponent';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async insertListModel(model: FadAvaliationsComponentModel[]) {
        return await this.post('', model);
    }
}
