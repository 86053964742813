import React from 'react';
import Styled from './NewMenuFixed.styled';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import { Popconfirm } from 'antd';
import keycloak from 'core/config/keycloak';
import { KeycloakLoginOptions } from 'keycloak-js';
import Colors from 'enums/Colors';

interface INewMenuFixed {
    openMenu: boolean;
    setModalTokenOpen: (bool: boolean) => void;
    scroolToSolutions: () => void;
}

const NewMenuFixed = ({ openMenu, setModalTokenOpen, scroolToSolutions }: INewMenuFixed) => {
    const isMobile = IsMobileBiggerWidth();

    const validateAccessToken = (goTologin: boolean) => {
        const current = location.pathname.split('/')[1];

        const redirect = current === 'home' ? '/home' : '/solucoes';

        if (keycloak.authenticated) {
            setModalTokenOpen(true);
        } else {
            const options: KeycloakLoginOptions = {
                redirectUri: window.location.origin + '/login?' + redirect,
            };

            const _url = goTologin ? keycloak.createLoginUrl(options) : keycloak.createRegisterUrl(options);
            window.location.assign(_url);
        }
    };

    return (
        <Styled.Container open={openMenu}>
            <Styled.ContainerButton isMobile={isMobile}>
                <Popconfirm
                    disabled={keycloak.authenticated}
                    placement="top"
                    title={<strong>Continue como Parceiro do Instituto</strong>}
                    description={<p>Para continuar como parceiro do Instituto é preciso realizar seu cadastro ou login.</p>}
                    onConfirm={() => validateAccessToken(true)}
                    onCancel={() => validateAccessToken(false)}
                    okText="Já sou cadastrado e quero fazer meu login"
                    cancelText="Quero me cadastrar"
                    cancelButtonProps={{
                        style: {
                            width: '200px',
                            height: '30px',
                        },
                    }}
                    okButtonProps={{
                        style: {
                            height: '30px',
                            backgroundColor: Colors.Blue,
                        },
                    }}
                >
                    <Styled.ButtonSecondary
                        stylesPersonalizations={{ padding: '10px 20px' }}
                        borderRadius="5px"
                        width={keycloak.authenticated ? 'fit-content' : isMobile ? '280px' : '174px'}
                        onClick={() => {
                            keycloak.authenticated && setModalTokenOpen(true);
                        }}
                    >
                        {keycloak.authenticated ? 'VER CONTEÚDOS EXCLUSIVOS' : 'JÁ SOU PARCEIRO'}
                    </Styled.ButtonSecondary>
                </Popconfirm>
                <Styled.ButtonPrimary stylesPersonalizations={{ borderRadius: '5px' }} onClick={() => scroolToSolutions()}>
                    {'VER SOLUÇÕES EDUCACIONAIS'}
                </Styled.ButtonPrimary>
            </Styled.ContainerButton>
        </Styled.Container>
    );
};

export default NewMenuFixed;
