import React from 'react';
import Colors from 'enums/Colors';
import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';
import { FAD_Row1 } from 'pages/ferramentaAutoria/AdminFerramentaAutoria.styled';
import { FadModulesModel } from 'models/ferramentaAutoria/FadModulesModel';
import { Input, Modal, Switch } from 'antd';
import { FAD_Button_Outline, FAD_Button_Primary } from '../FadButtons';

const Styled = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        background-color: ${Colors.White};
    `,

    ColumnForm: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,

    LabelForm: styled.h3`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,
    LabelForm2: styled.h3`
        //styleName: Base/Legenda 2;
        //styleName: Base/Corpo;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: #666666;
    `,
};

interface IModalFormModule {
    open: boolean;
    loading: boolean;
    handleSave: () => void;
    handleEdit: () => void;
    handleHidden: () => void;
    setForm: (value: any) => void;
    removeModule: (module: FadModulesModel) => void;
    form: FadModulesModel;
    openEdit?: boolean;
}

const ModalFormModule = ({ open, loading, form, handleSave, handleEdit, handleHidden, setForm, openEdit, removeModule }: IModalFormModule) => {
    return (
        <Modal
            title={<h1 style={{ fontSize: 26 }}> {openEdit ? 'Editar módulo' : 'Criar módulo'}</h1>}
            open={open || openEdit}
            onOk={openEdit ? handleEdit : handleSave}
            okText={openEdit ? 'Salvar edição' : 'Criar módulo'}
            cancelText={'Cancelar'}
            maskClosable={false}
            confirmLoading={loading}
            okButtonProps={{
                style: {
                    backgroundColor: Colors.Blue,
                },
            }}
            onCancel={handleHidden}
        >
            <Styled.ColumnForm style={{ borderTop: '1px solid #F0F0F0', paddingTop: 22 }}>
                <Styled.LabelForm>Título do módulo</Styled.LabelForm>
                <Input
                    showCount
                    value={form.title}
                    maxLength={100}
                    onChange={e => {
                        setForm((prev: any) => ({ ...prev, title: e.target.value }));
                    }}
                    placeholder="Digite o título do módulo"
                    size="large"
                />
            </Styled.ColumnForm>
            <Styled.ColumnForm style={{ borderTop: '1px solid #F0F0F0', paddingTop: 22, marginBottom: 22 }}>
                <Styled.LabelForm>Descrição do módulo</Styled.LabelForm>
                <TextArea
                    showCount
                    value={form.description}
                    rows={3}
                    maxLength={300}
                    onChange={e => {
                        setForm((prev: any) => ({ ...prev, description: e.target.value }));
                    }}
                    placeholder="Digite a descrição do módulo"
                    size="large"
                />
            </Styled.ColumnForm>
            <FAD_Row1 style={{ marginTop: 10 }}>
                <Switch
                    value={form.isPublic}
                    onChange={c => {
                        setForm((prev: any) => ({ ...prev, isPublic: c }));
                    }}
                />
                <Styled.LabelForm2>Módulo público</Styled.LabelForm2>
            </FAD_Row1>
            <FAD_Button_Outline
                styles={{
                    backgroundColor: Colors.DeleteRed,
                    color: Colors.White,
                    height: 32,
                    marginTop: 22,
                    width: 200,
                    position: 'relative',
                    top: 42,
                }}
                text="Excluir módulo"
                onClick={() => removeModule(form)}
            />
        </Modal>
    );
};

export default ModalFormModule;
