import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';

const StyledAboutSolutions = {
    Container: styled.div`
        display: flex;
        justify-content: center;
        background-color: ${Colors.BackGroundWhite};
        /* position: sticky;
        position: -webkit-sticky;
        z-index: 20;
        top: 10px; */
    `,

    Title: styled.h1`
        font-size: 32px;
        color: ${Colors.Blue};
        font-weight: 700;
        font-family: 'Barlow';
    `,

    Description: styled.p<{ isMobile: boolean }>`
        font-size: 16px;
        line-height: 150%;
        color: ${Colors.White};
        font-family: 'Barlow';
        max-width: ${({ isMobile }) => (isMobile ? '100%' : '85%')};
        width: ${({ isMobile }) => (isMobile ? '100%' : '85%')}; ;
    `,

    ContainerButton: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
        align-items: ${({ isMobile }) => (isMobile ? 'center' : 'normal')};
        justify-content: flex-start;
        column-gap: 25px;

        /* position: sticky;
        position: -webkit-sticky;
        z-index: 20;
        top: 100px; */
    `,

    ButtonPrimary: styled.button<{ light?: boolean, stylesPersonalizations?: React.CSSProperties }>`
        background-color: ${({ light }) => (light ? Colors.White : Colors.Blue)};
        color: ${({ light }) => (light ? Colors.Blue : Colors.White)};
        font-family: 'Barlow';
        width: 280px;
        min-height: 50px;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        font-size: 15px;
        margin-top: 10px;
        ${({ stylesPersonalizations }) => ({ ...stylesPersonalizations })}
        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

    ButtonSecondary: styled.button<{ light?: boolean; borderRadius?: string; width?: string; stylesPersonalizations?: React.CSSProperties }>`
        background-color: ${({ light }) => (light ? Colors.White : Colors.Yellow)};
        color: ${({ light }) => (light ? Colors.Yellow : Colors.Blue)};
        font-weight: 500;
        width: ${({ width }) => (width ? width : '280px')};
        min-height: 50px;
        border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '10px')};
        font-size: 15px;
        border: none;
        margin-top: 10px;
        ${({ stylesPersonalizations }) => ({ ...stylesPersonalizations })}

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

    DescriptionBlack: styled.div`
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        font-family: 'Barlow';
        max-width: 85%;
        width: 85%;
    `,

    Content: styled.div<{ isMobile: boolean }>`
        max-width: 1286px;
        gap: 8px;
        display: flex;
        flex-direction: column;
        width: ${({ isMobile }) => (isMobile ? '90%' : 'unset')};
        padding: ${({ isMobile }) => (isMobile ? '40px 0px' : '67px 78px 47px 10px')};

        @media screen and (max-width: 1280px){
            width: 860px;
            padding-left: 22px;
        }
    `,
};

export default StyledAboutSolutions;
