import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IComponentHostpotModel {
    codigo: number;
    text: string;
    x: number;
    y: number;
}

class ComponentHostpotModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    
    @jsonProperty(String)
    text: string = '';

    @jsonProperty(Number)
    x: number = -1;

    @jsonProperty(Number)
    y: number = -1;

    fromJSON(json: IComponentHostpotModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.text = json.text;
        this.x = json.x;
        this.y = json.y;

        return this;
    }
}

export default ComponentHostpotModel;
