import React, { useContext, useEffect } from 'react';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import GlobalStyled from '../../AcessoExlusivo.style';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import OverviewExclusivo from '../components/OverviewExclusivo';
import CicloExclusivo from '../components/CicloExclusivo';
import formulaLogo from './../../../../images/solutions/logo/formulaDaVitoriaLogo.png';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import { ListMateriasFVMatematica } from './ListMateriasFVMatematica';

const FormulaDaVitoriaMatematicaPrivate = () => {
    const { loading, idsMyCategorias } = useContext(AcessoExclusivoContext.Context);
    const isMobile = IsMobileBiggerWidth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GlobalStyled.GlobalContainer isMobile={isMobile}>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        title="Fórmula da Vitória Matemática"
                        img="ExclusivoFormulaMatImagem1.jpg"
                        logo={formulaLogo}
                        //arquivo={'anexo_storage_files_2839206562349981815.pdf_2024-11-27-1732735828100.pdf'}
                        nome_arquivo="Formula_Vitoria_Mat_Visao_Geral"
                        description={`
                          O Fórmula da Vitória Matemática tem como objetivo promover o desenvolvimento integral dos alunos que chegaram até os anos finais do Ensino Fundamental com deficiências básicas de aprendizagem em Matemática.
                          </br></br>
                          A solução educacional, de Matemática, almeja:  </br>
                          <ol style="list-style: inside disc;">
                            <li>Qualificar a linguagem matemática dos alunos, oral e escrita, nos processos de comunicação inerentes a ela tais como as capacidades de desenvolver argumentações, ler, falar e escrever;</li>
                            <li>Favorecer a aquisição de habilidades necessárias para o seu desempenho, para a estruturação do pensamento e agilização do raciocínio dedutivo.</li>
                            <li>Desenvolver o pensamento matemático e a postura de resolução de problemas. </li>
                          </ol>
                          </br>
                          Acesse o documento de visão geral para saber mais.
                        `}
                    />
                    <CicloExclusivo
                        title="Trilha Formativa Fórmula da Vitória Matemática"
                        description={`
                          A trilha formativa da Fórmula da Vitória Matemática apresenta os conteúdos da solução educacional de modo que os profissionais
                          (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                          Inicie agora a sua trilha formativa!
                        `}
                        img="ExclusivoFormulaMatImagem2.jpg"
                        itens={[
                            {
                                title: 'Trilha Formativa Fórmula da Vitória Matemática',
                                url: '',
                                isBlock: true,
                                titleIsBlock: `Essa trilha formativa não está disponível.`,
                            },
                        ]}
                    />
                    <MateriaisExclusivos
                        title="Materiais especiais para download"
                        description={`Tenha acesso aos materiais exclusivos do Fórmula da Vitória Matemática, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={'anexo_storage_files_18439785008892145703.jpeg_2025-03-31-1743444431365.jpeg'}
                        items={ListMateriasFVMatematica}
                    />
                    <ContactContainer />
                </>
            )}
        </GlobalStyled.GlobalContainer>
    );
};

export default FormulaDaVitoriaMatematicaPrivate;
