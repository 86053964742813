type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
};

export const ListMateriasGestaoApoioCultural: TItem[] = [
    {
        title: 'Focos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Focos',
                arquivos: [
                    {
                        nome: 'FOCO_IAS_1_Educacao_integral_digital_ApoioCultural',
                        arquivo: 'Materiais/FOCO_IAS_1_Educacao_integral_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_2_Lideranca_digital_ApoioCultural',
                        arquivo: 'Materiais/FOCO_IAS_2_Lideranca_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_3_Evidencias_digital_ApoioCultural',
                        arquivo: 'Materiais/FOCO_IAS_3_Evidencias_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_4_diretor_como_gestor_de_transformacao_digital_apoi_cultural',
                        arquivo: 'Materiais/foco_IAS_4_diretor_como_gestor_de_transformacao_digital_apoi_cultural_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_5_intervencoes_digital_apoio_cultural',
                        arquivo: 'Materiais/foco_IAS_5_intervencoes_digital_apoio_cultural_ARQ_GN10.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Ciclos Formativos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Ciclos Formativos',
                arquivos: [
                    {
                        nome: 'GN10_Ciclo1_Apresentacao_Anexos_digital',
                        arquivo: 'Materiais/GN10_Ciclo1_Apresentacao_Anexos_digital_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo1_Apresentacao_digital_ApoioCultural',
                        arquivo: 'Materiais/GN10_Ciclo1_Apresentacao_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                    { nome: 'GN10_Ciclo1_DIA_1_PPT_ApoioCultural', arquivo: 'Materiais/GN10_Ciclo1_DIA_1_PPT_ApoioCultural_ARQ_GN10.ppt' },
                    { nome: 'GN10_Ciclo1_DIA_2_PPT_ApoioCultural', arquivo: 'Materiais/GN10_Ciclo1_DIA_2_PPT_ApoioCultural_ARQ_GN10.ppt' },
                    { nome: 'GN10_Ciclo1_DIA_3_PPT_ApoioCultural', arquivo: 'Materiais/GN10_Ciclo1_DIA_3_PPT_ApoioCultural_ARQ_GN10.ppt' },
                    { nome: 'GN10_Ciclo2_PPT_ApoioCultural', arquivo: 'Materiais/GN10_Ciclo2_PPT_ApoioCultural_ARQ_GN10.ppt' },
                    {
                        nome: 'GN10_Ciclo2_RotinasEscolares_Anexos_digital',
                        arquivo: 'Materiais/GN10_Ciclo2_RotinasEscolares_Anexos_digital_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo2_RotinasEscolares_digital_ApoioCultural',
                        arquivo: 'Materiais/GN10_Ciclo2_RotinasEscolares_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo3_GestaoEscolar_Anexos_digital',
                        arquivo: 'Materiais/GN10_Ciclo3_GestaoEscolar_Anexos_digital_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo3_GestaoEscolar_digital_ApoioCultural',
                        arquivo: 'Materiais/GN10_Ciclo3_GestaoEscolar_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                    { nome: 'GN10_Ciclo3_PPT_ApoioCultural', arquivo: 'Materiais/GN10_Ciclo3_PPT_ApoioCultural_ARQ_GN10.ppt' },
                ],
            },
        ],
    },
    {
        title: 'Documento Geral',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'GN10_documento_geral_v04_digital_ApoioCultural',
                        arquivo: 'Materiais/GN10_documento_geral_v04_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sistemática de Acompanhamento',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'GN10_Sistematica_Acompanhamento_digital_ApoioCultural',
                        arquivo: 'Materiais/GN10_Sistematica_Acompanhamento_digital_ApoioCultural_ARQ_GN10.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Tabelas e Cartazes',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Tabelas e Cartazes',
                arquivos: [
                    {
                        nome: 'Tabelas_e_Cartazes_Gestao_Nota_10_Anos_Iniciais__A3',
                        arquivo: 'Materiais/Tabelas_e_Cartazes_Gestao_Nota_10_Anos_Iniciais__A3_ARQ_GN10.pdf',
                    },
                    {
                        nome: 'Tabelas_e_Cartazes_Gestao_Nota_10_Anos_Finais__A3',
                        arquivo: 'Materiais/Tabelas_e_Cartazes_Gestao_Nota_10_Anos_Finais__A3_ARQ_GN10.pdf',
                    },
                ],
            },
        ],
    },
];

export const ListMateriasGestaoPlanoAnual: TItem[] = [
    {
        title: 'Focos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Focos',
                arquivos: [
                    {
                        nome: 'FOCO_IAS_1_Educacao_integral_digital_PlanoAnual',
                        arquivo: 'Materiais/FOCO_IAS_1_Educacao_integral_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_2_Lideranca_digital_PlanoAnual',
                        arquivo: 'Materiais/FOCO_IAS_2_Lideranca_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_3_Evidencias_digital_PlanoAnual',
                        arquivo: 'Materiais/FOCO_IAS_3_Evidencias_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_4_Diretor_como_gestor_de_transformacao_digital_PlanoAnual',
                        arquivo: 'Materiais/FOCO_IAS_4_Diretor_como_gestor_de_transformacao_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'FOCO_IAS_5_Intervencoes_digital_PlanoAnual',
                        arquivo: 'Materiais/FOCO_IAS_5_Intervencoes_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Ciclos Formativos',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'GN10_Ciclo1_Apresentacao_Anexos_digital',
                        arquivo: 'Materiais/GN10_Ciclo1_Apresentacao_Anexos_digital_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo1_Apresentacao_digital_PlanoAnual',
                        arquivo: 'Materiais/GN10_Ciclo1_Apresentacao_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                    { nome: 'GN10_Ciclo1_DIA_1_PPT_PlanoAnual', arquivo: 'Materiais/GN10_Ciclo1_DIA_1_PPT_PlanoAnual_ARQ_ANUAL_GN10.ppt' },
                    { nome: 'GN10_Ciclo1_DIA_2_PPT_PlanoAnual', arquivo: 'Materiais/GN10_Ciclo1_DIA_2_PPT_PlanoAnual_ARQ_ANUAL_GN10.ppt' },
                    { nome: 'GN10_Ciclo1_DIA_3_PPT_PlanoAnual', arquivo: 'Materiais/GN10_Ciclo1_DIA_3_PPT_PlanoAnual_ARQ_ANUAL_GN10.ppt' },
                    { nome: 'GN10_Ciclo2_PPT_PlanoAnual', arquivo: 'Materiais/GN10_Ciclo2_PPT_PlanoAnual_ARQ_ANUAL_GN10.ppt' },
                    {
                        nome: 'GN10_Ciclo2_RotinasEscolares_Anexos_digital',
                        arquivo: 'Materiais/GN10_Ciclo2_RotinasEscolares_Anexos_digital_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo2_RotinasEscolares_digital_PlanoAnual',
                        arquivo: 'Materiais/GN10_Ciclo2_RotinasEscolares_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo3_GestaoEscolar_Anexos_digital',
                        arquivo: 'Materiais/GN10_Ciclo3_GestaoEscolar_Anexos_digital_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'GN10_Ciclo3_GestaoEscolar_digital_PlanoAnual',
                        arquivo: 'Materiais/GN10_Ciclo3_GestaoEscolar_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                    { nome: 'GN10_Ciclo3_PPT_PlanoAnual_ARQ_ANUAL_GN10', arquivo: 'Materiais/GN10_Ciclo3_PPT_PlanoAnual_ARQ_ANUAL_GN10.ppt' },
                ],
            },
        ],
    },
    {
        title: 'Documento Geral',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'GN10_documento_geral_v04_digital_PlanoAnual',
                        arquivo: 'Materiais/GN10_documento_geral_v04_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Sistemática de Acompanhamento',
        materiais: [
            {
                isPaste: false,
                titlePaste: '',
                arquivos: [
                    {
                        nome: 'GN10_Sistematica_Acompanhamento_digital_PlanoAnual',
                        arquivo: 'Materiais/GN10_Sistematica_Acompanhamento_digital_PlanoAnual_ARQ_ANUAL_GN10.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Tabelas e Cartazes',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Tabelas e Cartazes',
                arquivos: [
                    {
                        nome: 'Tabelas_e_Cartazes_Gestão Nota 10_Anos_Iniciais__A3',
                        arquivo: 'Materiais/Tabelas_e_Cartazes_Gestão Nota 10_Anos_Iniciais__A3_ARQ_ANUAL_GN10.pdf',
                    },
                    {
                        nome: 'Tabelas_e_Cartazes_Gestão_Nota_10_Anos_Finais__A3',
                        arquivo: 'Materiais/Tabelas_e_Cartazes_Gestão_Nota_10_Anos_Finais__A3_ARQ_ANUAL_GN10.pdf',
                    },
                ],
            },
        ],
    },
];
