import Colors from 'enums/Colors';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import React from 'react';
import styled from 'styled-components';

const Styled = {
    Divider: styled.div`
        width: 100%;
        height: 3px;
        background-color: #f0f0f0;
        margin: 42px 0px;
    `,

    DividerNumber: {
        Container: styled.div`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 22px 0px;
        `,

        Metric: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            position: absolute;
            top: 10px;
            font-size: 22px;
            gap: 10px;
            background-color: ${Colors.Blue};
            color: ${Colors.White};
        `,
    },
};

interface IComponentDividerPublic {
    dados: ComponentInteractionModel;
}

const ComponentDividerPublic = ({ dados }: IComponentDividerPublic) => {
    if (dados.tipo === ComponentInteractionEnum.DIVIDER_LINE) {
        return <Styled.Divider style={{ backgroundColor: Colors.Grey5, height: 2 }} />;
    }

    if (dados.tipo === ComponentInteractionEnum.DIVIDER_LINE_WITH_NUMBER) {
        return (
            <>
                <Styled.DividerNumber.Container>
                    <Styled.DividerNumber.Metric>{parseInt(dados.conteudo) > 0 ? dados.conteudo : 1}</Styled.DividerNumber.Metric>
                </Styled.DividerNumber.Container>
                <Styled.Divider />
            </>
        );
    }

    return <></>;
};

export default ComponentDividerPublic;
