import React, { useContext, useEffect, useState } from 'react';
import Styled from './CartaoMultiSolucoes.styled';
import TagSolucoes from './TagSolucoes';
import { TagSolucoesEnum } from 'enums/TagSolucoesEnum';
import { YellowButton } from 'components/UI/Buttons';
import { MdArrowForwardIos } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import styled from 'styled-components';
import Colors from 'enums/Colors';
import keycloak from 'core/config/keycloak';
import { ButtonHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';
import { Popover } from 'antd';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';

const styleButton = {
    button: styled.button<{ bgColor?: Colors; marginTop?: string }>`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: ${({ bgColor }) => (bgColor ? bgColor : Colors.Yellow)};
        color: ${({ bgColor }) => (bgColor === Colors.Blue ? Colors.White : Colors.Blue)};
        border-radius: 10px;
        height: 45px;
        font-weight: 700;
        font-size: 12px;
        width: 250px;
        border: none;
        gap: 0.5rem;
        margin-top: ${({ marginTop }) => marginTop};
    `,
};

export type TypeCard = {
    id: number[];
    tag: TagSolucoesEnum[];
    img?: string;
    imgWith?: string;
    imgHeight?: string;
    smallDescription?: string;
    titulo: string;
    descricao: string;
    link: string;
    linkExclusivo: string;
    listLinksExclusivos?: string[];
};

interface ICartaoMultiSolucoes {
    dados: TypeCard;
    isButtonOculto?: boolean;
    mySolucoesExclusivas: TipoSolucoes[];
    marginTop?: string;
    showDescriptionAndTag?: boolean;
}
const CartaoMultiSolucoes = ({ dados, isButtonOculto, mySolucoesExclusivas, marginTop, showDescriptionAndTag }: ICartaoMultiSolucoes) => {
    const { loading, idsMyCategorias } = useContext(AcessoExclusivoContext.Context);
    const [possuiExclusividade, setPossuiExclusividade] = useState<boolean>(false);
    const [openPopover, setOpenPopover] = useState<number>(0);

    const history = useHistory();

    const goToPush = () => {
        history.push(dados.link);
    };

    const handleToAccess = (card: TypeCard) => {
        if (card.id.some(c => c !== 5 && c !== 7)) {
            return history.push(card.linkExclusivo);
        }

        if (mySolucoesExclusivas.some(s => s.id === 7) && mySolucoesExclusivas.some(s => s.id === 5)) {
            return setOpenPopover(card.id[0]);
        }

        if (card.listLinksExclusivos) {
            if (mySolucoesExclusivas.some(s => s.id === 5)) {
                return history.push(card.listLinksExclusivos[0]);
            }

            if (mySolucoesExclusivas.some(s => s.id === 7)) {
                return history.push(card.listLinksExclusivos[1]);
            }
        }
    };

    const goToLinksExclusive = (link: string) => {
        history.push(link);
    };

    const handlePopoverClose = () => {
        setOpenPopover(0);
    };

    useEffect(() => {
        const acesso = mySolucoesExclusivas.filter(f => dados.id.some((n: number) => n === f.id));

        if (acesso.length > 0) {
            setPossuiExclusividade(true);
        }
    }, [mySolucoesExclusivas, dados]);

    return (
        <Styled.Container showDescription={showDescriptionAndTag}>
            <Styled.ContentImg>
                <Styled.Img
                    width={dados.imgWith}
                    height={dados.imgHeight}
                    src={
                        dados.img ??
                        'https://estado.sc.gov.br/noticias/wp-content/uploads/sites/3/2019/12/reuniao_sao_paulo_instituto_ayrton_senna_20191203_2072413675.jpeg'
                    }
                />
            </Styled.ContentImg>
            {showDescriptionAndTag && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', height: 100 }}>
                    <Styled.Title>{dados.titulo}</Styled.Title>
                    <Styled.Description dangerouslySetInnerHTML={{ __html: dados.descricao }} />
                </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                 
                    {possuiExclusividade && (
                        <Popover
                            key={dados.id[0]}
                            open={dados.id.some(c => c === openPopover)}
                            content={
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <ButtonHomeV2
                                        onClick={() => {
                                            if (dados.listLinksExclusivos) {
                                                goToLinksExclusive(dados.listLinksExclusivos[0]);
                                            }
                                        }}
                                        name="Português"
                                    >
                                        Português
                                    </ButtonHomeV2>
                                    <ButtonHomeV2
                                        onClick={() => {
                                            if (dados.listLinksExclusivos) {
                                                goToLinksExclusive(dados.listLinksExclusivos[1]);
                                            }
                                        }}
                                        name="Matemática"
                                    >
                                        Matemática
                                    </ButtonHomeV2>
                                </div>
                            }
                            title="Escolha um dos conteúdos exclusivos abaixo para acessar:"
                            trigger="click"
                            onOpenChange={handlePopoverClose}
                        >
                            <styleButton.button
                                style={{ width: '180px', paddingLeft: '20px', fontSize: 10 }}
                                bgColor={Colors.Blue}
                                onClick={() => handleToAccess(dados)}
                            >
                                {` Acessar conteúdo exclusivo`.toLocaleUpperCase()} <MdArrowForwardIos />
                            </styleButton.button>
                        </Popover>
                    )}
                </div>
            </div>
        </Styled.Container>
    );
};

export default CartaoMultiSolucoes;
