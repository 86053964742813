import React, { useEffect, useState } from 'react';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import HeaderSolution from 'pages/solucoes/components/newPageSolutionsComponents/HeaderSolution';
import NewFormSolucoes from 'pages/solucoes/components/newPageSolutionsComponents/NewFormSolucoes';
import NewInPractice from 'pages/solucoes/components/newPageSolutionsComponents/NewInPractice';
import NewOverview from 'pages/solucoes/components/newPageSolutionsComponents/NewOverview';
import NewSupportMaterial from 'pages/solucoes/components/newPageSolutionsComponents/NewSupportMaterial';

import circuitoBanner from '../../assets/circuito360/circuitoBanner.jpg';
import imagem_inpratice_01 from '../../assets/circuito360/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/circuito360/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/circuito360/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/circuito360/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/circuito360/inPratice/img_05.jpg';
import imagem_inpratice_06 from '../../assets/circuito360/inPratice/img_06.jpg';
import imagem_inpratice_07 from '../../assets/circuito360/inPratice/img_07.jpg';
import ImagemCircuito from '../../assets/circuito360/logo_circuito_360.svg';
import imagem_01 from '../../assets/circuito360/material/imagem_01.png';
import GlobalStyled from '../../Solucoes.styled';
import arrow from './../../assets/arrow.svg';
import useFetch from 'hooks/useFetch';
import { validAccessExclusivo } from 'pages/solucoes/helpers/FunctionsHelpersSolucoes';
import ResgatedToken from 'pages/solucoes/components/solucoes/ResgatedToken';

const Circuito360 = () => {
    const [openModalToken, setOpenModalToken] = useState<boolean>(false);
    const [modalContentExclusive, setModalContentExclusive] = useState<boolean>(false);

    const [access, setAccess] = useState<boolean>(false);

    useFetch(async () => {
        const status = await validAccessExclusivo();
        setAccess(status);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <HeaderSolution img={circuitoBanner} />
            <GlobalStyled.Container>
                <NewOverview
                    subname={'CIRCUITO 360'}
                    dados={{
                        title: 'Circuito 360',
                        descricao: `
                        Em 2023, o Instituto Ayrton Senna lança a solução Circuito 360, a partir de atualizações na solução Gestão de Política de Alfabetização (GALF), 
                        e com base no Circuito Campeão, solução que desde 2003 propõe a gestão de processos e resultados dos cinco primeiros anos do Ensino Fundamental.
                        `,
                        objetivo: `
                         O objetivo do Circuito 360 é subsidiar as secretarias de educação na elaboração e na gestão de sua política educacional para os
                         anos iniciais do Ensino Fundamental na perspectiva de educação integral.
                         `,
                        publicoAlvo: '1º ao 5º do Ensino Fundamental.',
                        avaliacao: 'Testes Diagnósticos de Alfabetização (língua portuguesa e matemática).',
                        formacao: `
                        4 ciclos e 4 encontros formativos para coordenadores pedagógicos (carga
                        horária – 54 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos, ancorados em indicadores e
                                    metas previamente definidas.
                        `,
                        atores: `
                        Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                            Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria de Educação
                            (gestão da política educacional).
                        `,
                        elementos: `
                        <ol>
                            <li>1º - Metodologias de trabalho para gerar sucesso por meio de atividades cognitivas e socioemocionais inovadoras;</li>
                            <li>2º - Gestão e gerenciamento dos processos e resultados;</li>
                            <li>3º - Formação dos profissionais, com prioridade para o coordenador pedagógico;</li>
                            <li>4º - Normatização da proposta e processos;</li>
                            <li>5º - Referencial curricular que dá clareza e unidade às expectativas de aprendizagem a cada ano dos anos
                                iniciais do Ensino Fundamental.</li>
                        </ol>                     
                        `,
                        img: ImagemCircuito,
                    }}
                />
                <NewInPractice
                    linkExclusivo="/exclusivo/circuito-360"
                    items={[
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_05,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_06,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_07,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Marcio Schimming',
                                local: 'Palhoça',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Marcio Schimming',
                                local: 'Palhoça',
                                ano: '2019',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Circuito 360 acontece na prática? <br /><br />
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente. <br /><br />
                    Sua rede pode ser a próxima a implementar a solução Circuito 360 e ter as imagens compartilhadas aqui na humane. Que tal?

                    `}
                    openModalToken={() => setOpenModalToken(true)}
                />
                <NewSupportMaterial
                    description={
                        <>
                            As redes parceiras que implementam a solução educacional Circuito 360 têm acesso a materiais exclusivos, como: <br />
                            <ul style={{ listStyle: `inside url(${arrow})` }}>
                                <li>
                                    {' '}
                                    <strong>Instrumentos diagnósticos</strong> dos níveis de alfabetização em Língua Portuguesa e Matemática;
                                </li>
                                <li>
                                    <strong>Subsídios para o trabalho pedagógico</strong> embasados em conhecimentos e avanços científicos e
                                    metodologias inovadoras;
                                </li>
                                <li>
                                    {' '}
                                    <strong>Protocolo para o monitoramento</strong> do processo educacional.
                                </li>
                            </ul>
                        </>
                    }
                    img={imagem_01}
                />
                <NewFormSolucoes
                    tipoSolucao={TipoSolucoesEnum.CIRCUITO_360}
                    preDefinition
                    linkExclusivo="/exclusivo/circuito-360"
                    accessExclusivo={access}
                    setOpenModalToken={() => setOpenModalToken(true)}
                />

                {/* <ResgatedToken /> */}
            </GlobalStyled.Container>
            <ResgatedToken
                valueModalToken={openModalToken}
                setHidenModalToken={setOpenModalToken}
                openModalSolucoesExclusiva={modalContentExclusive}
                setOpenModalSolucoesExclusiva={setModalContentExclusive}
            />
            <ContactContainer />
        </div>
    );
};

export default Circuito360;
