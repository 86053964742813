import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { mobileTopMenuHeight } from 'pages/menu/Menu.styled';
import styled from 'styled-components';

const DynamicHeaderStyled = {
    Container: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 86px;
        left: 0px;
        top: 0px;
        background: ${Colors.White};

        position: fixed;
        //margin-top: 86px;

        z-index: 100;
        padding-left: 60px;
        padding-right: 60px;
        box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            justify-content: unset;
            height: ${mobileTopMenuHeight}px;
            padding-left: 10px;
            padding-right: 20px;
        }
    `,

    LogoContainer: styled.div`
        display: flex;
        height: 48px;
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            height: 32px;
        }
    `,

    RightButton: styled.button`
        width: 109px;
        height: 37px;
        background-color: ${Colors.White};
        border: 1px solid ${Colors.BlueGrey};
        box-sizing: border-box;
        border-radius: 50px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: ${Colors.BlueGrey};
        cursor: pointer;
        outline: none;
        margin-left: 40px;

        &:hover {
            background-color: ${Colors.BlueGrey};
            color: ${Colors.White};
        }
    `,

    Img: styled.img`
        &:hover {
            cursor: pointer;
        }
    `,

    RightSideContainer: styled.div`
        margin-left: auto;
    `,
};

export default DynamicHeaderStyled;
