import { GenericService } from "../Generic.service";


export class ComponentHostpotService extends GenericService {
    readonly URI: string = '/v1/componentHostpot';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public deleteById(idComponente: number) {
        return this.delete(idComponente);
    }
}