import React from 'react';
import { ConfigProvider, Input, message, Modal } from 'antd';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import { useContext, useEffect, useState } from 'react';
import logoHumane from '../assets/logoHumane.svg';
import Select from 'components/Select';
import { ButtonHomeV2 } from 'pages/home/components/homeV2/button/ButtonHomeV2.styled';
import useUserData from 'hooks/useUserData';
import { ProfileService } from 'core/http/service/Profile.service';
import keycloak from 'core/config/keycloak';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';

const ModalSchool = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const { loadData } = useUserData();
    const { getUserExistBySchool, setGetUserExistBySchool } = useContext(AcessoExclusivoContext.Context);
    const [schoolSelected, setSchoolSelected] = useState<any>({});
    const [userSchools, setUserSchools] = useState<any[]>([]);
    const [notFoundSchool, setNotFoundSchool] = useState(false);
    const [schoolName, setSchoolName] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const is700x = IsCustomMaxWidth(700);

    const profileService = new ProfileService();

    const success = (message: string, delay?: number) => {
        messageApi.open({
            type: 'success',
            content: message,
            duration: delay,
        });
    };
    const error = (message: string, delay?: number) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: delay,
        });
    };

    const handleSaveUserSchool = async () => {
        if (notFoundSchool && schoolName.length === 0) return error('Informe uma escola!');

        if (!notFoundSchool && schoolSelected && !schoolSelected.schoolName) {
            return error('Informe uma escola!');
        }

        setLoading(true);

        const { usuarioLogado } = await loadData();

        if (usuarioLogado) {
            try {
                const name = notFoundSchool ? schoolName : schoolSelected.schoolName;
                const inep = notFoundSchool ? null : schoolSelected.schoolInep;

                const { status } = await profileService.saveUserSchool(name, inep);

                if (status === 200) {
                    success('Escola vinculada com sucesso!');
                    setGetUserExistBySchool(false);
                }
            } catch (error) {
                console.log('🚀 ~ handleOk ~ error:', error);
            }
        }
        setLoading(false);
    };

    const getUserSchools = async () => {
        if (keycloak.authenticated) {
            setLoading(true);

            const { data, status }: any = await profileService.findSchools();

            if (status === 200) {
                setUserSchools(data);
            }

            setLoading(false);
        }
    };

    useEffect(() => {
        getUserSchools();
    }, []);

    return (
        <Modal
            confirmLoading={loading}
            onCancel={() => setGetUserExistBySchool(false)}
            width={700}
            open={getUserExistBySchool}
            closeIcon={false}
            maskClosable={false}
            footer
        >
            <ConfigProvider
                input={{
                    style: {
                        height: 72,
                        fontSize: 22,
                    },
                }}
            >
                {contextHolder}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: is700x ? '' : '28px 66px' }}>
                    <span style={{ color: Colors.SoftBlue, fontWeight: 500, fontFamily: 'Barlow', fontSize: 16 }}>Acesso a plataforma</span>
                    <img src={logoHumane} alt="logo-humane" style={{ width: '176px' }} />
                    <p
                        style={{ maxWidth: '445px' }}
                        dangerouslySetInnerHTML={{ __html: `Selecione abaixo o nome da escola na qual você atua:` }}
                    />
                    {!notFoundSchool ? (
                        <>
                            <Select
                                itens={userSchools.map(dados => ({
                                    label: dados.name,
                                    value: dados.inep,
                                }))}
                                onSelected={selected => {
                                    setSchoolSelected({ schoolName: selected.label, schoolInep: selected.value });
                                }}
                                isSmall={false}
                                width="100%"
                            />
                            <a
                                onClick={() => setNotFoundSchool(true)}
                                style={{
                                    color: Colors.DarkBlue3,
                                    fontWeight: 500,
                                    fontFamily: 'Barlow',
                                    fontSize: 16,
                                    textAlign: 'center',
                                    textDecoration: 'underline',
                                }}
                            >
                                Não encontrei minha escola
                            </a>
                        </>
                    ) : (
                        <Input
                            placeholder="Digite o nome da sua escola."
                            value={schoolName}
                            onChange={e => setSchoolName(e.target.value)}
                            size={'large'}
                        />
                    )}
                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'center' }}>
                        <ButtonHomeV2 onClick={handleSaveUserSchool} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {loading ? <div className="loaderConteudo" /> : 'Confirmar'}
                            {loading && 'Aguarde'}
                        </ButtonHomeV2>
                    </div>
                </div>
            </ConfigProvider>
        </Modal>
    );
};

export default ModalSchool;
