import { jsonProperty, Serializable, SerializationSettings } from "ts-serializable";

interface IComponentMarkText {
    codigo: number;
    codigoComponentMarke: number;
    text: string;
    ordem: number;
}

export class ComponentMarkText extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number, null)
    codigoComponentMarke: number | null = -1;

    @jsonProperty(String)
    text: string = "";

    @jsonProperty(Number)
    ordem: number = 1;

    

    fromJSON(json: IComponentMarkText, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.text = json.text;
        this.ordem = json.ordem;
        this.codigoComponentMarke = json.codigoComponentMarke ?? null;

        return this;
    }
}