import { Button, Collapse, Input, InputNumber, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ToastSuccess } from 'components/Toaster';
import { ComponentItemCorrespondentService } from 'core/http/service/componetInteraction/ComponentItemCorrespondent.service';
import Colors from 'enums/Colors';
import { forEach } from 'lodash';
import CorrespondentItemModel from 'models/componentInteraction/CorrespondentItemModel';
import CorrespondentModel from 'models/componentInteraction/CorrespondentModel';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import React, { useEffect, useState } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
    `,

    Row: styled.div`
        display: flex;
        gap: 32px;
        margin-top: 12px;
        margin-bottom: 12px;
    `,
};

interface ICorrespondenciaInteractionAdmin {
    handleAtualizarInfos: (value: string, isPergunta?: boolean) => void;
    handleItens: (itens: CorrespondentItemModel[]) => void;
    dadosCorrespondent: CorrespondentModel | null;
}

const CorrespondenciaInteractionAdmin = ({ handleAtualizarInfos, handleItens, dadosCorrespondent }: ICorrespondenciaInteractionAdmin) => {
    const [itens, setItens] = useState<CorrespondentItemModel[]>(dadosCorrespondent?.itens ?? []);
    const [ordem, setOrdem] = useState<number>(1);

    const handleAddItem = () => {
        const newId = itens.length > 0 ? Math.max(...itens.map(item => item.codeInVinculaty)) + 1 : 1;

        // Primeiro item
        const newItem1 = new CorrespondentItemModel();
        newItem1.ordem = ordem;
        newItem1.codeInVinculaty = newId;

        // Segundo item
        const newItem2 = new CorrespondentItemModel();
        newItem2.ordem = ordem;
        newItem2.fixed = true;
        newItem2.codeInVinculaty = newId;

        setItens(prevItems => [...prevItems, newItem1, newItem2]);
        setOrdem(ordem + 1);
    };

    const handleTextItem = (idVinculaty: number, fixed: boolean, text: string) => {
        let item = itens.find(i => i.codeInVinculaty === idVinculaty && i.fixed === fixed);

        if (item) {
            item.text = text;

            setItens((prevState: any) => prevState.map((i: any) => (i === item ? { ...i, ...item } : i)));
        }
    };
    const handleOrdenacaoItem = (idVinculaty: number, fixed: boolean, number: number) => {
        let item = itens.find(i => i.codeInVinculaty === idVinculaty && i.fixed === fixed);

        if (item) {
            item.ordem = number;

            setItens((prevState: any) => prevState.map((i: any) => (i === item ? { ...i, ...item } : i)));
        }
    };

    const handleRemoveVinculo = async (idVinculo: number) => {
        const serviceItemCorrespondent = new ComponentItemCorrespondentService();

        let item = itens.filter(i => i.codeInVinculaty === idVinculo);

        item.forEach(async (dados, index) => {
            await serviceItemCorrespondent.deleteById(dados.id);
        });

        // if (status === 200) {
        //     ToastSuccess('Opção e alternativa removida com sucesso!');
        // }
    };

    const handleRemoveList = (idVinculaty: number) => {
        setItens(prevItens => {
            // Cria uma cópia do array para evitar mutação
            let array = [...prevItens];

            let index01 = array.findIndex(i => i.codeInVinculaty === idVinculaty && i.fixed === false);

            // Remove os itens se encontrados
            if (index01 !== -1) {
                array.splice(index01, 1);
            }

            let index02 = array.findIndex(i => i.codeInVinculaty === idVinculaty && i.fixed === true);

            if (index02 !== -1) {
                array.splice(index02, 1);
            }

            return array;
        });

        handleRemoveVinculo(idVinculaty);

        setOrdem(ordem - 1);
    };

    useEffect(() => {
        handleItens(itens);
    }, [itens]);

    useEffect(() => {
        if (dadosCorrespondent) {
            setItens(dadosCorrespondent.itens ?? []);

            const numberOrdem = dadosCorrespondent.itens && dadosCorrespondent.itens.filter(i => i.fixed).length;
            
            if (numberOrdem != null && numberOrdem > 0) {
                setOrdem(numberOrdem + 1);
            }
        }
    }, [dadosCorrespondent]);

    return (
        <Styled.Container>
            <Typography.Title level={3}>Digite a pergunta da correspondência:</Typography.Title>
            <ReactQuill
                theme="snow"
                placeholder="Esse campo é destinado a pergunta da correspondência."
                modules={editorConfig.modules}
                formats={editorConfig.formats}
                value={dadosCorrespondent?.pergunta && dadosCorrespondent.pergunta}
                onChange={val => {
                    // Atualiza o estado sem mutar diretamente o objeto
                    handleAtualizarInfos(val, true);
                }}
            />

            <Styled.Row>
                <Typography.Title level={4} style={{ marginTop: '20px' }}>
                    Opções interativas / Alternativas estáticas
                </Typography.Title>
            </Styled.Row>
            <Styled.Column>
                {itens
                    .filter(i => i.fixed === false)
                    .map((dados, index) => {
                        const itemPar = itens.find(item => item.codeInVinculaty === dados.codeInVinculaty && item.fixed);

                        return (
                            <Styled.Column key={index}>
                                <Styled.Row>
                                    <Styled.Column>
                                        <TextArea
                                            size="large"
                                            placeholder="Opção interativa."
                                            showCount
                                            value={dados.text}
                                            maxLength={200}
                                            onChange={e => {
                                                handleTextItem(dados.codeInVinculaty, false, e.target.value);
                                            }}
                                        />{' '}
                                        <Typography.Text type="warning">Ordenação de exibição pública</Typography.Text>
                                        <InputNumber
                                            min={1}
                                            max={itens.length / 2}
                                            defaultValue={dados.ordem}
                                            onChange={v => {
                                                handleOrdenacaoItem(dados.codeInVinculaty, false, v ?? -1);
                                            }}
                                            changeOnWheel
                                        />
                                    </Styled.Column>
                                    <Styled.Column>
                                        <TextArea
                                            size="large"
                                            showCount
                                            value={itemPar?.text}
                                            placeholder="Alternativa estática."
                                            maxLength={200}
                                            onChange={e => {
                                                handleTextItem(dados.codeInVinculaty, true, e.target.value);
                                            }}
                                        />
                                        <Typography.Text type="warning">Ordenação de exibição pública</Typography.Text>
                                        <InputNumber
                                            min={1}
                                            max={itens.length / 2}
                                            defaultValue={itemPar?.ordem}
                                            onChange={v => {
                                                handleOrdenacaoItem(dados.codeInVinculaty, true, v ?? -1);
                                            }}
                                            changeOnWheel
                                        />
                                    </Styled.Column>
                                    <MdDeleteForever
                                        onClick={() => handleRemoveList(dados.codeInVinculaty)}
                                        size={60}
                                        color="red"
                                        cursor={'pointer'}
                                    />
                                </Styled.Row>
                            </Styled.Column>
                        );
                    })}

                <Button onClick={handleAddItem} type="dashed" style={{ width: '200px', backgroundColor: Colors.Yellow }}>
                    Adicionar novo item
                </Button>
            </Styled.Column>
            <Collapse
                style={{
                    margin: '10px 0px',
                }}
                items={[
                    {
                        key: '1',
                        label: 'Adicionar texto de feedback',
                        children: (
                            <ReactQuill
                                style={{ marginTop: '20px' }}
                                theme="snow"
                                modules={editorConfig.modules}
                                formats={editorConfig.formats}
                                value={dadosCorrespondent?.feedback && dadosCorrespondent.feedback}
                                onChange={val => {
                                    handleAtualizarInfos(val, false);
                                }}
                            />
                        ),
                    },
                ]}
            />
        </Styled.Container>
    );
};

export default CorrespondenciaInteractionAdmin;
