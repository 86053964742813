import ComponentReguaAvaliativaModel from 'models/componentInteraction/ComponenteReguaAvaliativa';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextDangerouslySetInnerHtml } from '../../RecursosConteudoPage.styled';
import ComponentReguaAvaliativaQuestions from 'models/componentInteraction/ComponentReguaAvaliativaQuestions';
import ComponentReguaAvaliativaFeedbacks from 'models/componentInteraction/ComponentReguaAvaliativaFeedbacks';
import ComponentReguaAvaliativaOptions from 'models/componentInteraction/ComponentReguaAvaliativaOptions';
import { Progress, Result, Slider } from 'antd';
import { OutlineButton } from 'components/UI/Buttons';
import Feedback from 'react-bootstrap/esm/Feedback';
import Colors from 'enums/Colors';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { FadAvaliationsModel } from 'models/ferramentaAutoria/FadAvaliationsModel';
import { conicColors } from 'pages/ferramentaAutoria/pages/public/FadContentsPage';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 400px;
        margin: 22px 0px;
        border: 1px solid #f0f0f0;
        padding: 16px;
        border-radius: 8px;
    `,

    Titulo: styled.h1`
        //styleName: Base/H4;
        font-family: 'Barlow';
        font-size: 22px;
        font-weight: 600;
        line-height: 26.4px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,

    TextTituloAux: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
    `,

    Regua: {
        Container: styled.div`
            border: 1px solid #d9d9d9;
            height: 100%;
            padding: 16px;
            border-radius: 8px;
            background-color: #fafafa;
            gap: 8px;
        `,

        Titulo: styled.h1`
            //styleName: Base/Subtitulo;
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-decoration-skip-ink: none;
        `,

        Center: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        `,

        TextAux: styled.p`
            //styleName: Base/Corpo;
            font-family: 'Barlow';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: #666666;
        `,
    },

    Feedback: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 32px;
            height: 100%;
            gap: 8px;
        `,

        Pontuacao: styled.h1`
            //styleName: Base/H2;
            font-family: 'Barlow';
            font-size: 36px;
            font-weight: 700;
            line-height: 43.2px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};
        `,

        ContentText: styled.div`
            width: 100%;
            min-height: 120px;
            padding: 16px;
            //styleName: Base/H5;
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 400;
            line-height: 25.2px;
            text-align: center;
            text-decoration-skip-ink: none;
            color: #242424;
            background-color: #fafafa;
        `,
    },

    RowEnd: styled.div`
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: end;
    `,
};
interface IComponent {
    niveis: ComponentReguaAvaliativaOptions[];
    nivelSelect: (value: number) => void;
}

const Regua = ({ niveis, nivelSelect }: IComponent) => {
    const niveisComponent = niveis.reduce((acc: any, d, index) => {
        acc[index] = d.text ?? ' ';
        return acc;
    }, {});

    return (
        <div style={{ height: '100px', width: '90%' }}>
            <Slider autoFocus marks={niveisComponent} max={niveis.length - 1} onChange={v => nivelSelect(v + 1)} step={null} defaultValue={0} />
        </div>
    );
};

interface IReguaAvaliativaPublic {
    dados: ComponentReguaAvaliativaModel;
    component: ComponentInteractionModel;
    handleSaveAvaliation: (value: FadAvaliationsModel) => void;
}

const ReguaAvaliativaPublic = ({ dados, component, handleSaveAvaliation }: IReguaAvaliativaPublic) => {
    const { classSelected } = useContext(FerramentaAutoriaContext.Context);
    const [questions, setQuestions] = useState<ComponentReguaAvaliativaQuestions[]>(dados.questions);
    const [indexQuestion, setIndexQuestion] = useState<number>(0);

    //Pontuações
    const [myPontos, setMyPontos] = useState<number>(0);
    const [nivelSelect, setNivelSelected] = useState<number>(1);

    const [notaTotal, setNotaTotal] = useState<number>(0);

    const [feedback, setFeedback] = useState<ComponentReguaAvaliativaFeedbacks | null>(null);

    const calcularMediaAvaliativa = (avaliacoes: number, maxPontuacao: number) => {
        const somaPontos = avaliacoes;
        const maxPontosPossiveis = avaliacoes * maxPontuacao;

        // Normalizando para a escala de 0 a 10
        const media = (somaPontos / maxPontosPossiveis) * 10;

        setNotaTotal(notaTotal + parseFloat(media.toFixed(2))); // Retorna com duas casas decimais
    };

    const isFinish = (): boolean => {
        return questions.length - 1 === indexQuestion;
    };

    const fetchQuestionNumbers = (): string => {
        return !feedback ? indexQuestion + 1 + ' / ' + questions.length : '';
    };

    function isBetween(value: number, min: number, max: number) {
        return value >= min && value <= max;
    }

    const addFeedback = (pontos: number) => {
        const feedbackSelecionado = dados.feedbacks.filter(f => isBetween(pontos, f.nivelMin, f.nivelMax) && f).find(f => f);

        if (feedbackSelecionado) {
            setFeedback(feedbackSelecionado);
        }
    };

    const resetQuestions = () => {
        setIndexQuestion(0);
        setMyPontos(0);
        setNivelSelected(1);
        setFeedback(null);
    };

    const handleSaveAvaliations = async () => {
        const fadAvaliation = new FadAvaliationsModel().fromJSON({
            codigo: -1,
            average: notaTotal,
            codigoClasse: classSelected?.codigo ?? -1,
            codigoComponent: component.id,
            created: '',
        });

        handleSaveAvaliation(fadAvaliation);
    };

    const nextQuestion = () => {
        let myp = myPontos;
        const pontosAtuais = (myp += nivelSelect);

        setMyPontos(pontosAtuais);
        calcularMediaAvaliativa(myp, questions[indexQuestion].options?.length ?? 0);

        if (!isFinish()) {
            setIndexQuestion(indexQuestion + 1);
        } else {
            addFeedback(pontosAtuais);
            handleSaveAvaliations();
        }

        //resetInfo
        setNivelSelected(1);
    };

    useEffect(() => {
        setQuestions(dados.questions);
    }, [dados]);

    return (
        <Styled.Container>
            {component.userAvaliation && !feedback ? (
                <>
                 <Result
                    status="success"
                    title="Você já respondeu este exercício."
                    subTitle="Exercício da régua avaliativa"
                    extra={[
                        <div key={'unique'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Progress
                                size={'small'}
                                type="dashboard"
                                percent={(component.userAvaliation?.average ?? 0) * 10}
                                strokeColor={conicColors}
                                format={percent => (percent === 100 ? '100%' : `${parseInt(percent?.toString() ?? '0')}%`)}
                            />
                            <span>Porcentagem total da avaliação</span>
                        </div>,
                    ]}
                />
                </>
            ) : (
                <>
                    <Styled.Titulo>{dados.titulo} </Styled.Titulo>
                    {!feedback && (
                        <>
                            <TextDangerouslySetInnerHtml
                                style={{ margin: 0 }}
                                dangerouslySetInnerHTML={{
                                    __html: dados.textTituloAux ?? '',
                                }}
                            />
                            {questions.map((f, i) => {
                                if (i === indexQuestion) {
                                    return (
                                        <Styled.Regua.Container key={i}>
                                            <Styled.Regua.Titulo>{f.titulo}</Styled.Regua.Titulo>
                                            <Styled.Regua.TextAux>{f.textAux}</Styled.Regua.TextAux>
                                            <Styled.Regua.Center>
                                                <Regua niveis={f.options ?? []} nivelSelect={v => setNivelSelected(v)} />
                                            </Styled.Regua.Center>
                                        </Styled.Regua.Container>
                                    );
                                }
                            })}
                            <Styled.RowEnd>
                                <h2 style={{ fontWeight: 500 }}>{fetchQuestionNumbers()}</h2>
                                <OutlineButton
                                    onClick={() => {
                                        nextQuestion();
                                    }}
                                >
                                    {isFinish() ? 'Concluir' : 'Avançar'}
                                </OutlineButton>
                            </Styled.RowEnd>
                        </>
                    )}

                    {feedback && (
                        <Styled.Feedback.Container>
                            <Styled.Feedback.Pontuacao>
                                {feedback.nivelMin} - {feedback.nivelMax}
                            </Styled.Feedback.Pontuacao>
                            <Styled.Feedback.ContentText>{feedback.text}</Styled.Feedback.ContentText>
                            {!component.obrigatory && (
                                <OutlineButton
                                    onClick={() => {
                                        resetQuestions();
                                    }}
                                >
                                    Tentar novamente
                                </OutlineButton>
                            )}
                        </Styled.Feedback.Container>
                    )}
                </>
            )}
        </Styled.Container>
    );
};

export default ReguaAvaliativaPublic;
