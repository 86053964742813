import React from 'react';
import Styled from './ParceirosImplementadores.styled';
import Logo01 from '../../assets/logo_parceiro_01.svg';
import Logo02 from '../../assets/logo_parceiro_02.svg';
import partnersImage from '../../assets/parceirosImage.jpg';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';

type ItemType = {
    titulo: string;
    descricao: string;
    imagem: any;
};

interface IItem {
    dados: ItemType;
}

const Item = ({ dados }: IItem) => {
  const isMobile = IsMobileBiggerWidth();
    return (
        <Styled.Item.Container isMobile={isMobile}>
            <Styled.Item.Imagem src={dados.imagem} />
            <Styled.Item.Content>
                <Styled.Item.Title>{dados.titulo}</Styled.Item.Title>
                <Styled.Item.Description>{dados.descricao}</Styled.Item.Description>
            </Styled.Item.Content>
        </Styled.Item.Container>
    );
};

const ParceirosImplementadores = () => {
  const isMobile = IsMobileBiggerWidth();
    return (
        <Styled.Container>
            <Styled.Content isMobile={isMobile}>
                <Styled.Img src={partnersImage} isMobile={isMobile} />
                <Styled.PartnersContainer isMobile={isMobile}>
                    <Styled.Title>{`Alguns dos nossos parceiros implementadores`.toLocaleUpperCase()}</Styled.Title>
                    <Styled.Description>{`Para tornar a implementação das nossas soluções prática e eficiente, unimos forças com parceiros que compartilham dos nossos sonhos e objetivos. Juntos, garantimos que tudo seja realizado com a máxima atenção e carinho que sua região merece. Conheça alguns deles:`}</Styled.Description>

                    <Item
                        dados={{
                            titulo: 'Instituto FEFIG',
                            descricao:
                                'O Instituto FEFIG faz uma curadoria de projetos educacionais e apoia projetos com resultados comprovados e impacto possível de gerar escala. Ou seja, elevamos a eficácia do projeto: ele alcança o maior número de crianças possíveis e sempre com o objetivo de que cada iniciativa possa futuramente virar política pública',
                            imagem: Logo02,
                        }}
                    />
                    <Item
                        dados={{
                            titulo: 'Instituto Âncora Educação',
                            descricao:
                                'O Instituto Âncora Educação trabalha com as mais diferentes ferramentas e tecnologias atuando no ensino infantil, fundamental, técnico e superior. O objetivo é ser completo em nossas soluções para que todas as áreas da educação e cultura possam contar com nossa atuação. Para ser completo o instituto preza pela grande rede de relacionamento que possui.',
                            imagem: Logo01,
                        }}
                    />
                </Styled.PartnersContainer>
            </Styled.Content>
        </Styled.Container>
    );
};

export default ParceirosImplementadores;
