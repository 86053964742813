import { Image } from 'antd';
import { fetchStorageFile, fetchStorageFileAuthoringTools } from 'hooks/StoreFiles';
import { FadCoursesAnexoModel } from 'models/ferramentaAutoria/FadCourseAnexoModel';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import { FadCoursesPublicModel } from 'models/ferramentaAutoria/public/FadCoursesPublicModel';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FAD_Button_Primary } from '../FadButtons';
import { useHistory, useParams } from 'react-router-dom';
import Colors from 'enums/Colors';
import { TextDangerouslySetInnerHtml } from 'pages/recursos/recursos-conteudo/RecursosConteudoPage.styled';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import moment from 'moment';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        width: 320px;
        min-height: 320px;
        gap: 16px;
        opacity: 0px;
        margin-top: 32px;
        border: 1px solid ${Colors.Grey10};
        border-radius: 0px 0px 8px 8px;
        -webkit-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.31);
        -moz-box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.31);
        box-shadow: 10px 10px 5px -8px rgba(0, 0, 0, 0.31);
        /* border: 1px solid #cacaca; */
        padding-bottom: 6px;
    `,

    Row: styled.div`
        display: flex;
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 12px;
        padding: 6px;
    `,

    Info: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,

    RowBeet: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    NameCourse: styled.h1`
        font-family: 'Barlow';
        font-size: 18px;
        font-weight: 600;
        line-height: 22.4px;
        text-align: left;
        height: fit-content;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,
    Description: styled.p`
        line-height: 22px;
    `,

    Img: styled.img`
        width: 100%;
        height: 140px;
    `,

    Tag: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        background-color: #99e3ff;
        height: Hug 28px;
        padding: 4px 8px 4px 8px;
        gap: 16px;
        border-radius: 8px;
        opacity: 0px;
        width: fit-content;
    `,

    Status: styled.div<{ isPublic: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 69px;
        height: 25px;
        padding: 4px 8px 4px 8px;
        gap: 16px;
        border-radius: 16px;
        opacity: 0px;
        background-color: ${({ isPublic }) => (isPublic ? '#1B7F43' : '#99E3FF')};
        color: ${({ isPublic }) => (isPublic ? Colors.White : '')};
        font-family: 'Barlow';
        font-size: 12px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        text-decoration-skip-ink: none;
        margin-top: 10px;
    `,
};

interface ICardCoursePublic {
    dados: FadCoursesPublicModel;
}

const CardCoursePublic = ({ dados }: ICardCoursePublic) => {
    const { page }: any = useParams();
    const { classSelected, isCourseCloseDate, setCourse } = useContext(FerramentaAutoriaContext.Context);

    const history = useHistory();

    const handleNav = () => {
        history.push(`/cursos/${page}${dados.course.url}`);
    };

    useEffect(() => {
      if (dados && dados.course.url) {
        setCourse(dados.course.url.substring(1));
      } 
    }, [dados]);

    return (
        <Styled.Container>
            <Image
                width={'100%'}
                height={'140px'}
                style={{ objectFit: 'cover' }}
                src={fetchStorageFileAuthoringTools(dados.anexo.filepath)}
                loading="lazy"
                fallback={fetchStorageFile('anexo_storage_files_12734497904244904460.jpg_2024-12-23-1734954777648.jpg')}
            />
            <Styled.Content>
                {classSelected && classSelected.closingDate != null && (
                    <Styled.Tag>
                        <Styled.Tag>
                            {isCourseCloseDate(classSelected.closingDate.toString()) ? (
                                <>
                                    Curso encerrado:
                                    <strong> {moment(classSelected.closingDate.toString()).subtract(1, 'days').format('DD/MM/YYYY')}</strong>
                                </>
                            ) : (
                                <>
                                    Disponível até:{' '}
                                    <strong>
                                        {' '}
                                        {moment(classSelected.closingDate.toString()).subtract(1, 'days').format('DD/MM/YYYY')}
                                    </strong>
                                </>
                            )}
                        </Styled.Tag>
                    </Styled.Tag>
                )}
                <Styled.Info>
                    <Styled.NameCourse>{dados.course.name}</Styled.NameCourse>
                    <TextDangerouslySetInnerHtml dangerouslySetInnerHTML={{ __html: dados.course.description }} style={{ margin: 0 }} />
                </Styled.Info>
                <FAD_Button_Primary text={!dados.haveAccess ? 'Acesso exclusivo' : 'Acessar'} onClick={handleNav} disabled={!dados.haveAccess} />
            </Styled.Content>
        </Styled.Container>
    );
};

export default CardCoursePublic;
