import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledApresentation = {
    Container: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
        align-items: center;
        justify-content: center;
        row-gap: 20px;
        margin-bottom: ${({ isMobile }) => (isMobile ? '20px' : '0px')};
    `,

    ImgBanner: styled.div<{ src: string; isMobile: boolean }>`
        display: flex;
        background: ${({ isMobile, src }) =>
            isMobile ? `url(${src})` : `linear-gradient(180deg, rgba(0, 0, 0, 0) -15.84%, rgba(0, 0, 0, 0.38) 63.15%), url(${src});`};
        background-size: cover;
        min-height: ${({ isMobile }) => (isMobile ? '331px' : '416px')};
        width: 100%;
        background-position: center 30%;
    `,

    Content: styled.div<{ isMobile: boolean }>`
        position: ${({ isMobile }) => (isMobile ? 'unset' : 'absolute')};
        display: flex;
        flex-direction: row;
        justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
        gap: 1rem;
        max-width: 1286px;
    `,

    TextContainer: styled.div<{ isMobile: boolean }>`
        width: ${({ isMobile }) => (isMobile ? '90%' : '50%')};
        display: flex;
        gap: 1rem;
        flex-direction: column;
    `,

    Img: styled.img<{ isMobile: boolean }>`
        width: 100%;
        z-index: -1;
        margin-top: 8%;
        display: ${({ isMobile }) => (isMobile ? 'none' : 'inline')};
    `,

    Title: styled.h1<{ isMobile: boolean }>`
        font-size: 48px;
        color: ${({ isMobile }) => (isMobile ? Colors.Blue : Colors.Yellow)};
        font-weight: 700;
        font-family: 'Barlow';
    `,

    Description: styled.p<{ isMobile: boolean }>`
        font-size: 16px;
        line-height: 150%;
        color: ${({ isMobile }) => (isMobile ? '#000000' : Colors.White)};
        font-family: 'Barlow';
        max-width: ${({ isMobile }) => (isMobile ? '100%' : '85%')};
        width: ${({ isMobile }) => (isMobile ? '100%' : '85%')};
    `,

    TokenButton: styled.div<{ isMobile: boolean }>`
        padding:  ${({ isMobile }) => (isMobile ? '8px 10px' : '8px 40px 8px 10px')};
        color: ${Colors.Blue};
        background-color: ${Colors.Yellow};
        position: absolute;
        border-radius: 7px;
        right: ${({ isMobile }) => (isMobile ? '10px' : '-20px')};
        top: ${({ isMobile }) => (isMobile ? '380px' : '466px')};
        display: flex;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Barlow';
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
    `,
};

export default StyledApresentation;
