import { Input, Radio } from 'antd';
import Colors from 'enums/Colors';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ColorSelected from './subcomponents/ColorSelected';
import { FAD_Button_Outline } from 'pages/ferramentaAutoria/components/FadButtons';
import { MdAdd, MdDeleteForever } from 'react-icons/md';
import ComponentInteractionModel from 'models/componentInteraction/ComponentInteraction';
import { ComponentMark } from 'models/componentInteraction/ComponentMark';
import { ComponentMarkText } from 'models/componentInteraction/ComponentMarkText';
import { ComponentMarksService } from 'core/http/service/componetInteraction/ComponentMarks.service';
import { text } from 'stream/consumers';
import { IoIosArrowUp } from 'react-icons/io';
import TextArea from 'antd/es/input/TextArea';
import ReactQuill from 'react-quill';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 668px;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing: 0%;
        color: ${Colors.Blue};
    `,

    RowBetween: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    SubTitle: styled.h2`
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: 0%;
        color: ${Colors.Blue};
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,

    TextMark: {
        Container: styled.div`
            display: flex;
            align-items: center;
            gap: 16px;
            min-height: 90px;
        `,

        MarkNumber: styled.div<{ color: string }>`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px !important;
            height: 35px !important;
            aspect-ratio: 1/1; /* Mantém a proporção */
            border-radius: 50%; /* Alternativa a 100% */
            background-color: ${({ color }) => (color ? color : Colors.Grey10)};
            font-family: 'Barlow';
            font-weight: 600;
            font-size: 18px;
            color: ${({ color }) => (color !== Colors.Grey10 ? Colors.White : '')};
        `,

        Bullet: styled.div<{ color: string }>`
            background-color: ${({ color }) => (color ? color : Colors.Grey10)};
            width: 35px !important;
            height: 35px !important;
            border-radius: 50%;
            padding: 10px;
            gap: 16px;
        `,
    },
};

interface ITextMark {
    index: number;
    isBullet: boolean;
    textMark: ComponentMarkText;
    color: string;
    handleText: (value: string) => void;
    handleMoveUp: (value: number) => void;
    handleDelete: () => void;
}

const TextMark = ({ index, isBullet, textMark, color, handleText, handleMoveUp, handleDelete }: ITextMark) => {
    return (
        <Styled.TextMark.Container>
            {!isBullet ? (
                <Styled.TextMark.MarkNumber color={color}>{textMark.ordem}</Styled.TextMark.MarkNumber>
            ) : (
                <Styled.TextMark.Bullet color={color} />
            )}
            <ReactQuill
                style={{
                    width: '500px',
                    border: `1px solid ${Colors.Grey6}`,
                    overflow: 'hidden',
                }}
                placeholder="Digite o texto aqui."
                theme="snow"
                modules={editorConfig.modulesSimples}
                formats={editorConfig.formatsSimples}
                value={textMark.text}
                onChange={val => handleText(val)}
            />
            {index !== 0 && (
                <FAD_Button_Outline text="" onClick={() => handleMoveUp(textMark.codigo)} icon={<IoIosArrowUp />} styles={{ minWidth: 40 }} />
            )}

            <MdDeleteForever size={28} color={Colors.DeleteRed} cursor={'pointer'} onClick={handleDelete} />
        </Styled.TextMark.Container>
    );
};

interface ICompontentMarksAdmin {
    dados: ComponentInteractionModel;
    handleColor: (value: string) => void;
    handleUpdateComponent: (value: ComponentMark) => void;
}

const CompontentMarksAdmin = ({ dados, handleColor, handleUpdateComponent }: ICompontentMarksAdmin) => {
    const [color, setColor] = useState<string>('');
    const [mark, setMark] = useState<ComponentMark | null>(dados.marks);
    const [textMarks, setTextMarks] = useState<ComponentMarkText[]>([]);

    //service
    const serviceMark = new ComponentMarksService();

    const updateColor = async (value: string) => {
        handleColor(value);
        setColor(value);
    };

    const handleNewTextMark = async () => {
        if (mark) {
            const obj = new ComponentMarkText().fromJSON({
                codigo: -1,
                codigoComponentMarke: mark.codigo,
                text: '',
                ordem: textMarks.length > 0 ? (textMarks.at(-1)?.ordem ?? 0) + 1 : 1,
            });

            const { data, status } = await serviceMark.novoTextMark(obj);

            if (status === 201) {
                const attMark = mark;
                attMark.marks = [...textMarks, data];

                setTextMarks((prev: ComponentMarkText[]) => [...prev, data]);
                handleUpdateComponent(attMark);
            }
        }
    };

    const handleRadio = (value: number) => {
        let att = mark;

        if (att) {
            att.isBullet = value === 1 ? true : false;
            handleUpdateComponent(att);
        }
    };

    const handleText = (codigo: number, value: string) => {
        if (!mark) return;

        // Atualiza a lista de marcas de texto garantindo instâncias de ComponentMarkText
        const updatedTextMarks = textMarks.map(m =>
            m.codigo === codigo
                ? new ComponentMarkText().fromJSON({
                      ...m,
                      text: value,
                      codigoComponentMarke: m.codigoComponentMarke ?? 0, // Garante um número válido
                  })
                : m
        );

        // Cria um novo objeto de marcação garantindo que seja um ComponentMark válido
        const updatedMark = new ComponentMark().fromJSON({
            ...mark,
            marks: updatedTextMarks,
            codigoComponentInteraction: mark.codigoComponentInteraction ?? undefined, // Evita null
        });

        // Atualiza os estados
        setTextMarks(updatedTextMarks);
        setMark(updatedMark);

        // Chama a função de atualização com um objeto do tipo correto
        handleUpdateComponent(updatedMark);
    };

    const handleDeletMark = async (id: number) => {
        const listMarks = [...textMarks];

        const newList = listMarks.filter(l => l.codigo != id);

        const { status } = await serviceMark.deleteById(id);

        if (status === 204) {
            setTextMarks(newList);
        }
    };

    const handleMoveUp = (codigo: number) => {
        if (!mark) return;

        // Encontrar o índice do item que será movido
        const index = textMarks.findIndex(m => m.codigo === codigo);
        if (index <= 0) return; // Se for o primeiro item, não pode subir mais

        // Clonar a lista para evitar mutações diretas
        const updatedTextMarks = [...textMarks];

        // Trocar a ordem dos elementos
        const tempOrder = updatedTextMarks[index].ordem;
        updatedTextMarks[index].ordem = updatedTextMarks[index - 1].ordem;
        updatedTextMarks[index - 1].ordem = tempOrder;

        // Reordenar para garantir que a ordem seja respeitada
        updatedTextMarks.sort((a, b) => a.ordem - b.ordem);

        // Criar uma nova instância de ComponentMark para atualizar corretamente
        const updatedMark = new ComponentMark().fromJSON({
            ...mark,
            marks: updatedTextMarks,
            codigoComponentInteraction: mark.codigoComponentInteraction ?? undefined, // Corrige o erro de tipagem
        });

        // Atualizar estados
        setTextMarks(updatedTextMarks);
        setMark(updatedMark);

        // Atualizar o componente
        handleUpdateComponent(updatedMark);
    };

    useEffect(() => {
        setColor(dados.cor ?? Colors.Grey10);
        setMark(dados.marks);
        setTextMarks(dados.marks?.marks ?? []);
    }, [dados]);

    return (
        <Styled.Container>
            <Styled.Title>Marcador de texto</Styled.Title>
            <Styled.RowBetween>
                <Styled.Column>
                    <Styled.SubTitle>Tipo de marcador</Styled.SubTitle>
                    <Radio.Group
                        name="radiogroup"
                        defaultValue={0}
                        value={mark?.isBullet ? 1 : 0}
                        onChange={e => handleRadio(e.target.value)}
                        options={[
                            { value: 0, label: 'Número' },
                            { value: 1, label: 'Bullet' },
                        ]}
                    />
                </Styled.Column>
                <Styled.Column>
                    <Styled.SubTitle>Cor do marcador</Styled.SubTitle>
                    <ColorSelected title="" onChangeColor={v => updateColor(v)} color={color} />
                </Styled.Column>
            </Styled.RowBetween>
            <Styled.Column>
                {mark &&
                    textMarks &&
                    textMarks.length > 0 &&
                    textMarks
                        .sort((a, b) => a.ordem - b.ordem)
                        .map((text, i) => (
                            <TextMark
                                key={i}
                                index={i}
                                isBullet={mark.isBullet ?? false}
                                textMark={text}
                                color={color}
                                handleText={v => handleText(text.codigo, v)}
                                handleMoveUp={handleMoveUp}
                                handleDelete={() => handleDeletMark(text.codigo)}
                            />
                        ))}
            </Styled.Column>
            <FAD_Button_Outline text="Adicionar opção" onClick={handleNewTextMark} icon={<MdAdd />} styles={{ width: 200 }} />
        </Styled.Container>
    );
};

export default CompontentMarksAdmin;
