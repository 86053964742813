import { MobileBiggerWidth, MobileMaxWidth, ResizeMediumWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { mobileTopMenuHeight } from 'pages/menu/Menu.styled';
import styled from 'styled-components';

const HeaderSolution = {
    HeaderContainer: styled.div`
        width: 100%;
        margin-top: 86px;
        max-height: 25vh;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            margin-top: ${mobileTopMenuHeight}px;
        }
    `,

    Img: styled.img<{ positionImage?: string }>`
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        object-position: center;
        object-position: ${({positionImage}) => positionImage ? positionImage : '0 25%' };
  

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            max-height: 15vh !important;
        }

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            max-height: 20vh;
        }
    `,

    ContainerDecoration: styled.div`
        z-index: -1;
        position: absolute;
        background-color: #ffca00;
        width: 95%;
        height: 500px;
        max-height: 337px;
        border-bottom-right-radius: 30% 30%;
        border-bottom-left-radius: 50% 40%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            max-height: 17vh !important;
        }

        @media only screen and (max-width: ${ResizeMediumWidth}px) {
            max-height: 23vh;
        }
    `,
};

export default HeaderSolution;
