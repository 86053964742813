import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import AcessoExlusivoProvider from '../context/AcessoExclusivoProvider';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import DialogosSocioemocionaisPrivate from './dialogosSocioemocionais/DialogosSocioemocionaisPrivate';
import CircuitoPrivate from './circuito360/CircuitoPrivate';
import SeLigaPrivate from './seLiga/SeLigaPrivate';
import GestaoPrivate from './gestao/GestaoPrivate';
import AceleraPrivate from './acelera/AceleraPrivate';
import FormulaDaVitoriaMatematicaPrivate from './formulaDaVitoria/FormulaDaVitoriaMatematicaPrivate';
import RealizandoEscolhasPrivate from './realizandoEscolhas/RealizandoEscolhasPrivate';
import FormulaDaVitoriaPortuguesPrivate from './formulaDaVitoria/FormulaDaVitoriaPortuguesPrivate';
import ModalSchool from './components/ModalSchool';

export interface IAcessoExclusivoRoute {}

const AcessoExclusivoRoute = ({}: IAcessoExclusivoRoute) => {
    const location = useLocation();

    return (
        <AcessoExlusivoProvider>
            <DynamicHeader />
            <ModalSchool />
            <Route exact path={'/exclusivo/dialogos-socioemocionais'} component={DialogosSocioemocionaisPrivate} />
            <Route exact path={'/exclusivo/circuito-360'} component={CircuitoPrivate} />
            <Route exact path={'/exclusivo/se-liga'} component={SeLigaPrivate} />
            <Route exact path={'/exclusivo/gestao-nota-10'} component={GestaoPrivate} />
            <Route exact path={'/exclusivo/acelera-brasil'} component={AceleraPrivate} />
            <Route exact path={'/exclusivo/formula-da-vitoria-matematica'} component={FormulaDaVitoriaMatematicaPrivate} />
            <Route exact path={'/exclusivo/formula-da-vitoria-portugues'} component={FormulaDaVitoriaPortuguesPrivate} />
            {/* <Route exact path={'/exclusivo/realizando-escolhas'} component={RealizandoEscolhasPrivate} /> */}
        </AcessoExlusivoProvider>
    );
};

export default AcessoExclusivoRoute;
