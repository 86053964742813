import { blue } from '@ant-design/colors';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledNewOverview = {
    Container: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
        flex-wrap: nowrap;
        margin-top: 40px;
        max-width: 1227px;
        column-gap: 100px;
        align-items: ${props => (props.isMobile ? 'center' : 'normal')};

        @media screen and (max-width: 1350px) and (min-width: 1200px) {
            padding: 0px 5px 0px 15px;
        }
    `,

    ContainerGroupContent: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        width: ${props => (props.isMobile ? '80%' : '50%')};
        justify-content: space-between;
    `,

    ContainerContent: styled.div`
        margin-bottom: 32px;
    `,

    Title: styled.h1`
        font-size: 18px;
        line-height: 32px;
        color: ${Colors.SoftBlue6};
        font-weight: 700;
        font-family: 'Barlow';
    `,

    Description: styled.p`
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
    `,

    Img: styled.img`
        width: 101px;
        border: 1px solid #E9E9E9;
        padding: 2px;
        border-radius: 3px;
        width: 101px;
        height: 100px;
        object-fit: contain;
    `,

    TitleContainer: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: row;
        justify-content: ${props => (props.isMobile ? 'flex-start' : 'normal')};
        justify-self: ${props => (props.isMobile ? 'center' : 'normal')};
        margin-top: ${props => (props.isMobile ? '0px' : '100px')};
        width: 80%;
        gap: ${props => (props.isMobile ? '10px' : '20px')};

        @media only screen and (max-width: 1350px) and (min-width: 1200px) {
            padding-left: 15px;
        }

        @media only screen and (max-width: 450px) {
            flex-direction: column;
            width: 80% !important;
            justify-self: center;
        }

        @media only screen and (max-width: 1200px) and (min-width: 990px) {
            margin-top: 10px;
        }
    `,

    NameContainer: styled.div`
        display: flex;
        flex-direction: column;
        display: flex;
        align-self: center;
    `,

    Name: styled.p`
        font-size: 24px;
        line-height: 32px;
        color: ${Colors.Blue};
        font-weight: 700;
        font-family: 'Barlow';
    `,
    SubName: styled.p<{ hasName: boolean }>`
        font-size: 40px;
        line-height: 32px;
        color: #007bd8;
        font-weight: 700;
        font-family: 'Barlow';
        margin-top: ${({ hasName }) => (hasName ? '0px' : '0px')};
    `,
};

export default StyledNewOverview;
