type IArquivos = {
    arquivo: any;
    nome: string;
};

type IMaterial = {
    isPaste?: boolean;
    titlePaste?: string;
    arquivos: IArquivos[];
};
type TItem = {
    title: string;
    materiais: IMaterial[];
};

export const ListMateriasFVMatematica: TItem[] = [
    {
        title: 'Materiais Didáticos',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Materiais Didáticos',
                arquivos: [
                    {
                        nome: 'FV_Mat_Aluno_001-424-Digital',
                        arquivo: 'Materiais/FV_Mat_Aluno_001-424-Digital_ARQ_FV.pdf',
                    },
                    {
                        nome: 'FV_MAT_ALUNO_TANGRAM_digital',
                        arquivo: 'Materiais/FV_MAT_ALUNO_TANGRAM_digital_ARQ_FV.pdf',
                    },
                    {
                        nome: 'FV_Mat_Professor_001a232-Digital',
                        arquivo: 'Materiais/FV_Mat_Professor_001a232-Digital_ARQ_FV.pdf',
                    },
                    {
                        nome: 'FV_Mat_Professor_Problemateca_Digital',
                        arquivo: 'Materiais/FV_Mat_Professor_Problemateca_Digital_ARQ_FV.pdf',
                    },
                    {
                        nome: 'FV_MAT_MAPA_BNCC_digital',
                        arquivo: 'Materiais/FV_MAT_MAPA_BNCC_digital_ARQ_FV.pdf',
                    },
                ],
            },
        ],
    },

    {
        title: 'Teste Diagnóstico',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Teste Diagnóstico',
                arquivos: [
                    {
                        nome: 'FV_MAT_TESTE_DIAGNOSTICO_ALUNO_digital',
                        arquivo: 'Materiais/FV_MAT_TESTE_DIAGNOSTICO_ALUNO_digital_ARQ_FV.pdf',
                    },
                    {
                        nome: 'FV_MAT_TESTE_DIAGNOSTICO_ORIENTACOES_digital',
                        arquivo: 'Materiais/FV_MAT_TESTE_DIAGNOSTICO_ORIENTACOES_digital_ARQ_FV.pdf',
                    },
                ],
            },
        ],
    },
    {
        title: 'Agendas Formativas',
        materiais: [
            {
                isPaste: true,
                titlePaste: 'Agendas Formativas',
                arquivos: [
                    {
                        nome: 'FV_MAT_AGENDA_FORMACAO_DIA_1_PF',
                        arquivo: 'Materiais/FV_MAT_AGENDA_FORMACAO_DIA_1_PF_ARQ_FV.ppt',
                    },
                    {
                        nome: 'FV_MAT_AGENDA_FORMACAO_DIA_2_PF',
                        arquivo: 'Materiais/FV_MAT_AGENDA_FORMACAO_DIA_2_PF_ARQ_FV.ppt',
                    },
                    {
                        nome: 'FV_MAT_AGENDA_FORMACAO_DIA_3_PF',
                        arquivo: 'Materiais/FV_MAT_AGENDA_FORMACAO_DIA_3_PF_ARQ_FV.ppt',
                    },
                    {
                        nome: 'FV_MAT_AGENDA_FORMATIVA_digital',
                        arquivo: 'Materiais/FV_MAT_AGENDA_FORMATIVA_digital_ARQ_FV.pdf',
                    },
                    {
                        nome: 'Grafico_40_Agenda_1_dia',
                        arquivo: 'Materiais/Grafico_40_Agenda_1_dia_ARQ_FV.png',
                    },
                ],
            },
        ],
    },
];
