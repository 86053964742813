export const FeedBacks = (id: number, percent: number) => {
    switch (id) {
        case 1:
            if (percent >= 0 && percent <= 40) {
                return 'Suas respostas indicam que você ainda precisa se apropriar do programa Se Liga. Recomendamos que refaça o curso, dedicando maior atenção aos temas que sentiu dificuldade e fazendo registros sobre o conteúdo abordado para que possa retomá-los sempre que preciso.';
            } else if (percent >= 41 && percent <= 80) {
                return 'Você já conhece muito do programa Se Liga! Para seguir aprendendo, recomendamos que volte ao curso e retome os temas e conteúdos que apresentou maior dificuldade, para que possa conhecer a solução integralmente e desenvolvê-la com sucesso!';
            } else if (percent >= 81) {
                return 'Parabéns! Você se apropriou de toda a proposta do programa Se Liga! Desejamos um ótimo trabalho e retome esse curso sempre que quiser relembrar ou aprofundar alguma informação pontual.';
            }
            break;
        case 2: {
            if (percent >= 0 && percent <= 40) {
                return 'Suas respostas indicam que você ainda precisa se apropriar do programa Acelera Brasil. Recomendamos que refaça o curso, dedicando maior atenção aos temas que sentiu dificuldade e fazendo registros sobre o conteúdo abordado para que possa retomá-los sempre que achar necessário.';
            } else if (percent >= 41 && percent <= 80) {
                return 'Você já conhece muito do programa Acelera Brasil! Para seguir aprendendo, recomendamos que volte ao curso e retome os temas e conteúdos de maior dificuldade, para que possa conhecer a solução integralmente e desenvolvê-la com sucesso!';
            } else if (percent >= 81) {
                return 'Parabéns! Você se apropriou de toda a proposta do programa Acelera Brasil! Desejamos um ótimo trabalho e retome esse curso sempre que quiser relembrar ou aprofundar alguma informação pontual.'
            }
            break;
        }
    }
    return '';
};

export default FeedBacks;
