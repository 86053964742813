import { TagSolucoesEnum } from 'enums/TagSolucoesEnum';
import { TypeCard } from '../components/solucoes/CartaoMultiSolucoes';
import logoC360 from './../assets/circuito360/logo_circuito_360.svg';
import logoGNOTA10 from './../assets/gestaoNota10/logo.jpg';
import logoSeliga from './../assets/seLiga/logo.png';
import logoAcelera from './../assets/aceleraBrasil/logo.png';
import logoFormula from './../assets/formulaVitoria/logo.jpg';
import logoSocioemocional from './../assets/dialogoSocioemocional/logo_01.png';

const ListCartoes: TypeCard[] = [
    {
        id: [6],
        titulo: 'Diálogos Socioemocionais',
        descricao: `
                      Contribuir com a construção de políticas públicas e competências que garantam a educação integral dos estudantes brasileiros.
                      `,
        smallDescription: `
                      Programa que se soma às políticas de educação integral com o objetivo de promover o desenvolvimento socioemocional dos estudantes, de forma intencional e consistente.
                      `,
        img: logoSocioemocional,
        imgHeight: '100%',
        imgWith: '80%',
        link: '/dialogos-socioemocionais',
        tag: [TagSolucoesEnum.GESTAO_ESCOLAR, TagSolucoesEnum.FORMACAO_LIDERANCA],
        linkExclusivo: '/exclusivo/dialogos-socioemocionais',
    },
    {
        id: [3],
        titulo: 'Se liga',
        descricao: `
              O objetivo é alfabetizar alunos com distorção idade-ano, matriculados nas turmas do 3º ao 5º ano do Ensino Fundamental.
            `,
        smallDescription: `
        Programa desenvolvido para alfabetizar estudantes matriculados nos anos iniciais do Ensino Fundamental e que apresentam distorção idade-ano.
        `,
        img: logoSeliga,
        imgHeight: '100%',
        imgWith: '80%',
        link: '/se-liga',
        tag: [TagSolucoesEnum.ALFABETIZACAO, TagSolucoesEnum.CORRE_DE_FLUXO],
        linkExclusivo: '/exclusivo/se-liga',
    },
    {
        id: [4],
        titulo: 'Acelera Brasil',
        descricao: `
                  Desenvolvimento dos alunos que chegaram nos anos finais do E.F. com deficiências em Língua Portuguesa e Matemática.
                    `,
        smallDescription: `
   Programa com foco em estudantes já alfabetizados dos anos iniciais do Ensino Fundamental, mas com distorção idade-série.
    `,
        img: logoAcelera,
        imgHeight: '100%',
        imgWith: '80%',
        link: '/acelera-brasil',
        tag: [TagSolucoesEnum.CORRE_DE_FLUXO, TagSolucoesEnum.RECUPERACAO_APRENDIZAGEM],
        linkExclusivo: '/exclusivo/acelera-brasil',
    },
    {
        id: [1],
        titulo: 'Circuito 360',
        descricao: `Subsidiar as secretarias de educação na elaboração e na gestão de suas políticas educacionais para o ensino fundamental.`,
        img: logoC360,
        link: '/circuito-360',
        smallDescription: `
         Programa que apoia secretarias de educação na elaboração e gestão de políticas educacionais para os anos iniciais do ensino fundamental, abordando temas como a gestão da alfabetização.
          `,
        tag: [TagSolucoesEnum.ALFABETIZACAO, TagSolucoesEnum.GESTAO_POLITICA_EDUCACIONAL],
        linkExclusivo: '/exclusivo/circuito-360',
    },
    {
        id: [2],
        titulo: 'Gestão Nota 10',
        descricao: 'Desenvolvimento de uma política educacional democrática e inclusiva a partir de uma rede integrada de escolas autônomas.',
        img: logoGNOTA10,
        link: '/gestao-nota-10',
        smallDescription: `
         Focado na Gestão Escolar, o programa busca levar práticas gerenciais ao cotidiano da escola e ao processo educacional, que é acompanhado sistemática e intencionalmente.
          `,
        tag: [TagSolucoesEnum.GESTAO_ESCOLAR, TagSolucoesEnum.FORMACAO_LIDERANCA],
        linkExclusivo: '/exclusivo/gestao-nota-10',
    },

    {
        id: [5, 7],
        titulo: 'Fórmula da Vitória',
        descricao: `
              Qualificar a aprendizagem dos estudantes em Língua Portuguesa e Matemática para reversão do quadro de reprovação
              `,
        smallDescription: `
          Programa que visa a recomposição de aprendizagem para de Língua Portuguesa e Matemática para alunos dos anos finais do Ensino Fundamental.
          `,
        img: logoFormula,
        link: '/formula-da-vitoria',
        tag: [TagSolucoesEnum.RECUPERACAO_APRENDIZAGEM, TagSolucoesEnum.ALFABETIZACAO, TagSolucoesEnum.CORRE_DE_FLUXO],
        linkExclusivo: '',
        listLinksExclusivos: ['/exclusivo/formula-da-vitoria-portugues', '/exclusivo/formula-da-vitoria-matematica'],
    },

    // {
    //     titulo: 'Realizando escolhas',
    //     descricao: `
    //                     A proposta Realizando Escolhas foi desenhada para
    //                     oferecer aos estudantes do 9º Ano do Ensino Fundamental
    //                     e do Ensino Médio oportunidades de aprofundar seu
    //                     autoconhecimento em termos de interesses, articulando os com seu projeto de vida.
    //                     `,
    //     link: '/solucao-realizando-escolhas',
    //     tag: [TagSolucoesEnum.INTERESSES_PROFISSIONAIS],
    // },
];

export default ListCartoes;
