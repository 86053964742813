import React, { useEffect, useState } from 'react';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import useFetch from 'hooks/useFetch';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import HeaderSolution from 'pages/solucoes/components/newPageSolutionsComponents/HeaderSolution';
import NewFormSolucoes from 'pages/solucoes/components/newPageSolutionsComponents/NewFormSolucoes';
import NewInPractice from 'pages/solucoes/components/newPageSolutionsComponents/NewInPractice';
import NewOverview from 'pages/solucoes/components/newPageSolutionsComponents/NewOverview';
import NewSupportMaterial from 'pages/solucoes/components/newPageSolutionsComponents/NewSupportMaterial';
import ResgatedToken from 'pages/solucoes/components/solucoes/ResgatedToken';
import { validAccessExclusivo } from 'pages/solucoes/helpers/FunctionsHelpersSolucoes';

import dialogosBanner from '../../assets/dialogoSocioemocional/dialogos_banner.jpg';
import imagem_inpratice_01 from '../../assets/dialogoSocioemocional/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/dialogoSocioemocional/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/dialogoSocioemocional/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/dialogoSocioemocional/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/dialogoSocioemocional/inPratice/img_05.jpg';
import imagem_inpratice_06 from '../../assets/dialogoSocioemocional/inPratice/img_06.jpg';
import ImagemLogo from '../../assets/dialogoSocioemocional/logo.svg';
import imagem_01 from '../../assets/dialogoSocioemocional/material/supportMaterial.svg';
import GlobalStyled from '../../Solucoes.styled';
import arrow from './../../assets/arrow.svg';

const DialogoSocioemocional = () => {
    const [openModalToken, setOpenModalToken] = useState<boolean>(false);
    const [modalContentExclusive, setModalContentExclusive] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);

    useFetch(async () => {
        const status = await validAccessExclusivo();
        setAccess(status);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <HeaderSolution img={dialogosBanner} />
            <GlobalStyled.Container>
                <NewOverview
                    name={'DIÁLOGOS'}
                    subname={'SOCIOEMOCIONAIS'}
                    dados={{
                        title: 'Diálogos Socioemocionais',
                        descricao: `
                        Em 2016, após muitas pesquisas sobre o tema de competências socioemocionais, a equipe pedagógica do Instituto Ayrton Senna, em parceria com os seus pesquisadores do eduLab21, desenvolveram a solução Diálogos Socioemocionais.
                        A solução passou por atualizações em 2024, trazendo materiais ainda mais completos para as redes de ensino.
                        `,
                        objetivo: `
                          O objetivo do Diálogos Socioemocionais é contribuir com a construção de
                            políticas públicas de desenvolvimento de competências socioemocionais que
                            garantam a educação integral dos estudantes brasileiros (modalidades Projeto de Vida
                            e Duplo Foco).
                         `,
                        publicoAlvo: ' • 6º ao 9º do Ensino Fundamental e Ensino Médio.',
                        avaliacao: 'Instrumento de avaliação socioemocional rubricas e instrumento Senna.',
                        formacao: `
                         Carga horária de 16 horas
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos de acompanhamento e
                            relatórios de devolutiva do instrumento de avaliação.
                        `,
                        atores: `
                           Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem); </br>
                            Coordenador Pedagógico / Diretor (gestão da rotina escolar); </br> Secretaria de Educação
                            (gestão da política educacional).
                        `,
                        elementos: `
                          <ol>
                              <li>1º - Articulação com políticas de educação integral, favorece o desenvolvimento socioemocional dos estudantes, impactando também o desempenho acadêmico e outras esferas da vida;</li>
                              <li>2º - Foco em práticas e vivências, com uso de metodologias ativas e da avaliação formativa;</li>
                              <li>3º - Promoção de letramento e desenvolvimento socioemocional;</li>
                              <li>4º - Base em evidências, matriz de competências abrangente e robusta;</li>
                              <li>5º - Professor como mediador do desenvolvimento integral.</li>
                          </ol>
                        `,
                        img: ImagemLogo,
                        linkExclusivo: '/exclusivo/dialogos-socioemocionais',
                    }}
                />
                <NewInPractice
                    openModalToken={() => setOpenModalToken(true)}
                    linkExclusivo="/exclusivo/dialogos-socioemocionais"
                    items={[
                        {
                            type: TypeCard.VIDEO,
                            url: 'T9LypYkUmZY',
                        },
                        {
                            type: TypeCard.VIDEO,
                            url: 'AAgSVugr4J4',
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_05,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_06,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Aurélio Alves',
                                local: 'Fortaleza',
                                ano: '2022',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Aurélio Alves',
                                local: 'Fortaleza',
                                ano: '2022',
                            },
                        },
                    ]}
                    description={`
                    <strong>Quer conhecer um pouco mais sobre como o Diálogos Socioemocionais acontece na prática?</strong><br/><br/>
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.
                    Sua rede pode ser a próxima a implementar a solução Diálogos.
                    `}
                />
                <NewSupportMaterial
                    description={
                        <>
                            As redes parceiras que implementam a solução educacional Diálogos Socioemocionais têm acesso a diversos materiais
                            pedagógicos, como: <br /> <br />
                            <ul style={{ listStyle: `inside url(${arrow})` }}>
                                <li>
                                    <strong>Rubricas</strong> para avaliação formativa de competências socioemocionais;
                                </li>
                                <li>
                                    <strong>Caderno Modelo Pedagógico</strong>, que organiza e apresenta os fundamentos teóricos e metodológicos
                                    da proposta;
                                </li>
                                <li>
                                    <strong>Banco de planejamentos docentes inspiracionais</strong> de diversos componentes curriculares;
                                </li>
                                <li>
                                    <strong>Caderno de Orientações à Gestão</strong>, que apresenta a proposta Diálogos Socioemocionais;
                                </li>
                                <li>
                                    <strong>Caderno da Sistemática de Monitoramento e Acompanhamento;</strong>
                                </li>
                                <li>
                                    <strong> Caderno de Orientações sobre avaliação Socioemocional</strong>, que apresenta orientações para
                                    avaliação de competências socioemocionais, os instrumentos SENNA e de Rubricas.
                                </li>
                            </ul>
                        </>
                    }
                    img={imagem_01}
                />

                <NewFormSolucoes
                    tipoSolucao={TipoSolucoesEnum.DIALOG_SOCIOEMOCIONAL}
                    preDefinition
                    setOpenModalToken={setOpenModalToken}
                    accessExclusivo={access}
                    linkExclusivo="/exclusivo/dialogos-socioemocionais"
                />
                <ResgatedToken
                    valueModalToken={openModalToken}
                    setHidenModalToken={setOpenModalToken}
                    openModalSolucoesExclusiva={modalContentExclusive}
                    setOpenModalSolucoesExclusiva={setModalContentExclusive}
                />
            </GlobalStyled.Container>
            <ContactContainer />
        </div>
    );
};

export default DialogoSocioemocional;
