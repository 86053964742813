import React, { useEffect, useState } from 'react';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import HeaderSolution from 'pages/solucoes/components/newPageSolutionsComponents/HeaderSolution';
import NewFormSolucoes from 'pages/solucoes/components/newPageSolutionsComponents/NewFormSolucoes';
import NewInPractice from 'pages/solucoes/components/newPageSolutionsComponents/NewInPractice';
import NewOverview from 'pages/solucoes/components/newPageSolutionsComponents/NewOverview';
import NewSupportMaterial from 'pages/solucoes/components/newPageSolutionsComponents/NewSupportMaterial';

import imagem_inpratice_01 from '../../assets/seLiga/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/seLiga/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/seLiga/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/seLiga/inPratice/img_04.jpg';
import imagem_inpratice_06 from '../../assets/seLiga/inPratice/img_06.jpg';
import Imagem from '../../assets/seLiga/logo.png';
import imagem_01 from '../../assets/seLiga/material/imagem_01.png';
import seLigaBanner from '../../assets/seLiga/seLigaBanner.jpg';
import GlobalStyled from '../../Solucoes.styled';
import ResgatedToken from 'pages/solucoes/components/solucoes/ResgatedToken';
import useFetch from 'hooks/useFetch';
import { validAccessExclusivo } from 'pages/solucoes/helpers/FunctionsHelpersSolucoes';

const SeLiga = () => {
    const [openModalToken, setOpenModalToken] = useState<boolean>(false);
    const [modalContentExclusive, setModalContentExclusive] = useState<boolean>(false);

    const [access, setAccess] = useState<boolean>(false);

    useFetch(async () => {
        const status = await validAccessExclusivo();
        setAccess(status);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <DynamicHeader />
            <HeaderSolution img={seLigaBanner} position={'0 45%'} />
            <GlobalStyled.Container>
                <NewOverview
                    // name={'SE'}
                    subname={'<span style="color: #003c78 !important;">SE</span> LIGA'}
                    dados={{
                        title: 'Se Liga',
                        descricao: `
                            O Instituto Ayrton Senna criou o Se Liga em 2001, a partir das experiências vividas com a solução educacional Acelera Brasil, lançada em 1997,
                            para dar conta do desafio de combater a distorção idade-ano e corrigir o fluxo de alunos ainda não alfabetizados. A solução educacional passou por um processo de atualização em 2023,
                            considerando o cenário atual e a integração com a BNCC.
                        `,
                        objetivo: `
                         O objetivo do Se Liga é alfabetizar alunos com distorção idade-ano,
                        matriculados nas turmas do 3º ao 5º ano do EF, oferecendo-lhes condições necessárias
                        para o prosseguimento no fluxo escolar.
                         `,
                        publicoAlvo: '3º ao 5º do Ensino Fundamental.',
                        avaliacao: 'Testes Diagnósticos de Alfabetização (enturmação).',
                        formacao: `
                        Encontros formativos presenciais para professores e mediadores (carga
                        horária – 24 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos, ancorados em indicadores e
                            metas previamente definidas.
                        `,
                        atores: `
                        Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem); </br>
                            Coordenador Pedagógico / Diretor (gestão da rotina escolar); </br> Secretaria de Educação
                            (gestão da política educacional).
                        `,
                        elementos: `
                        <ol>
                            <li>1º - Material didático do aluno e do professor, com fundamentos da
                            proposta e orientações específicas para o trabalho aula a aula, e base em currículo
                            para promover o desenvolvimento integral;</li>
                            <li>2º - Coordenação e gerenciamento;</li>
                            <li>3º -  Formação presencial e a distância (EAD), inicial e continuada;</li>
                            <li>4º - Sistema de monitoramento e avaliação, gerenciamento dos indicadores de aprendizagem;</li>
                            <li>5º - Apoio de agentes técnicos.</li>
                        </ol>
                        `,
                        img: Imagem,
                    }}
                />

                <NewInPractice
                    items={[
                        {
                            type: TypeCard.VIDEO,
                            url: '65_4pMkpYrs',
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_06,
                            credits: {
                                credits: 'Flávio Araújo',
                                local: 'Santarém',
                                ano: '2018',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Se Liga acontece na prática?<br/><br/>
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.<br/><br/>
                    Sua rede pode ser a próxima a implementar a solução Se Liga e ter as imagens compartilhadas aqui na humane. Que tal?
                    `}
                    linkExclusivo="/exclusivo/se-liga"
                    openModalToken={() => setOpenModalToken(true)}
                />
                <NewSupportMaterial
                    description={
                        <>
                            A solução é composta por diferentes materiais de apoio ao trabalho desenvolvido em sala de aula e o trabalho
                            executado pelas escolas e Secretarias de Educação. As resdes parceiras têm acesso a diversos materiais, como:
                            <br />
                            <strong>Livros do aluno e do professor</strong>, materiais de suporte aos professores e aos estudantes com 120 aulas
                            multidisciplinares, que exploram os conteúdos de todas as áreas do conhecimento (cada livro contém 30 aulas
                            estruturadas em 4 unidades).
                        </>
                    }
                    img={imagem_01}
                />

                <NewFormSolucoes
                    tipoSolucao={TipoSolucoesEnum.SE_LIGA}
                    preDefinition
                    linkExclusivo="/exclusivo/se-liga"
                    accessExclusivo={access}
                    setOpenModalToken={() => setOpenModalToken(true)}
                />
            </GlobalStyled.Container>
            <ResgatedToken
                valueModalToken={openModalToken}
                setHidenModalToken={setOpenModalToken}
                openModalSolucoesExclusiva={modalContentExclusive}
                setOpenModalSolucoesExclusiva={setModalContentExclusive}
            />
            <ContactContainer />
        </div>
    );
};

export default SeLiga;
