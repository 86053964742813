import { FadContentsModel } from 'models/ferramentaAutoria/FadContentsModel';
import { GenericService } from '../Generic.service';

export class FadContentsService extends GenericService {
    readonly URI: string = '/v1/fadContents';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    async create(model: FadContentsModel) {
        return await this.post('', model);
    }

    async update(codigo: number, model: FadContentsModel) {
        return await this.put(`${codigo}`, model);
    }

    async updateOrdem(codigo: number, model: FadContentsModel) {
        return await this.put(`ordem/${codigo}`, model);
    }

    async deleteFadContents(codigo: number) {
      return  await this.delete(codigo);
    }

    async findById(codigo: number) {
        return await this.get(`${codigo}`);
    }

    async findAllFadContents() {
        return await this.get();
    }

    async findByFadModules(fadModules: any) {
        return await this.post('byFadModules', fadModules);
    }

    async findByFadModulesCodigo(codigo: number) {
        return await this.get(`byFadModulesCodigo/${codigo}`);
    }

    async findByTitle(title: string) {
        return await this.get(`byTitle/${title}`);
    }

    async findContentsByModule(codigoMudule: number, codigoClasse: number) {
        return await this.get(`public/${codigoMudule}/${codigoClasse}`);
    }

    async findByIsPublic(isPublic: boolean) {
        return await this.get('byIsPublic', { isPublic });
    }

    async findByOrdem(ordem: number) {
        return await this.get(`byOrdem/${ordem}`);
    }
}