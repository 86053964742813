import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledParceirosImplementadores = {
    Container: styled.div`
        display: flex;
        justify-content: center;
        margin-top: 35px;
        margin-bottom: 35px;
    `,

    Content: styled.div<{ isMobile: boolean }>`
        width: ${({ isMobile }) => (isMobile ? '90%' : 'unset')};
        max-width: 1286px;
        display: flex;
        column-gap: 80px;
        align-items: ${({ isMobile }) => (isMobile ? 'center' : 'normal')};;
        flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    `,

    PartnersContainer: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: ${({ isMobile }) => (isMobile ? '100%' : '70%')};
        padding: 30px 0;
    `,

    Title: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: ${Colors.Blue};
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
    `,

    Img: styled.div<{ src: string, isMobile: boolean }>`
        background: url(${({ src }) => src});
        width: ${({ isMobile }) => (isMobile ? '90%' : '50%')};
        height: ${({ isMobile }) => (isMobile ? '432px' : 'unset')};
        border-radius: 8px;
        background-size: cover;
        background-position: center;
        max-height: 632px;
    `,

    Item: {
        Container: styled.div<{ isMobile: boolean }>`
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
            gap: 1rem;
            min-height: 120px;
            margin-bottom: 20px;
        `,

        Imagem: styled.img`
            object-fit: cover;
            height: 148px;
            width: 148px;
        `,

        Content: styled.div`
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            flex-direction: column;
        `,

        Title: styled.h1`
            font-size: 20px;
            font-weight: 700;
        `,

        Description: styled.p`
            line-height: 28px;
            font-size: 16px;
        `,
    },
};

export default StyledParceirosImplementadores;
