import React, { useContext, useEffect } from 'react';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import GlobalStyled from './../../AcessoExlusivo.style';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import OverviewExclusivo from '../components/OverviewExclusivo';
import CicloExclusivo from '../components/CicloExclusivo';
import aceleraLogo from './../../../../images/solutions/logo/aceleraBrasilLogo.png';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import { ListMateriaisAceleraPlanoAnual, ListMateriasAceleraApoioCultural } from './ListMateriaisAcelera';
import ContactContainer from 'pages/home/components/contact/ContactContainer';

const AceleraPrivate = () => {
    const { loading, idsMyCategorias } = useContext(AcessoExclusivoContext.Context);
    const isMobile = IsMobileBiggerWidth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GlobalStyled.GlobalContainer isMobile={isMobile}>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        title="Acelera Brasil"
                        img="ExclusivoAceleraImagem1.JPG"
                        logo={aceleraLogo}
                        arquivo={
                            idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2))
                                ? ''
                                : 'anexo_storage_files_16653777186800945860.pdf_2025-03-31-1743452239560.pdf'
                        }
                        nome_arquivo="Acelera_Visao_Geral"
                        description={`
                            O Acelera Brasil tem como objetivo corrigir ou reduzir a distorção idade-ano com a aceleração no fluxo escolar de estudantes matriculados do 3º ao 5º ano, prioritariamente.
                            </br> </br>
                            O conteúdo programático da coleção abrange o previsto nas definições curriculares nacionais até o 5º ano do Ensino Fundamental para que o aluno salte anos escolares com garantia de aprendizagem e acompanhamento.
                            </br> </br>
                             ${
                                 idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2))
                                     ? ''
                                     : 'Acesse o documento de visão geral para saber mais.'
                             }  
                        `}
                        isButtonHidden={idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2)) ? true : false}
                    />

                    <CicloExclusivo
                        title="Curso Acelera Brasil"
                        description={`
                          O curso Acelera Brasil apresenta os conteúdos da solução educacional de modo que os profissionais
                          (professores, coordenadores pedagógicos, diretores e/ou técnicos da rede pública de ensino) se apropriem dos conteúdos essenciais para a implementação da proposta.
                          <br/> <br/>
                          Inicie agora o curso!
                        `}
                        img="ExclusivoAceleraImagem2.jpg"
                        itens={[
                            {
                                title: 'Curso Acelera Brasil',
                                url: '/cursos/acelera_brasil/acelera_brasil',
                                isBlock: false,
                                titleIsBlock: `Esse curso não está disponível.`,
                            },
                        ]}
                    />
                    <MateriaisExclusivos
                        title="Materiais especiais para download"
                        description={`  Tenha acesso aos materiais exclusivos do Acelera Brasil, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={'anexo_storage_files_10384181528135196819.jpg_2025-03-27-1743101602256.jpg'}
                        items={
                            idsMyCategorias.some(() => idsMyCategorias.includes(1 || 2))
                                ? ListMateriasAceleraApoioCultural
                                : ListMateriaisAceleraPlanoAnual
                        }
                    />
                    <ContactContainer />
                </>
            )}
        </GlobalStyled.GlobalContainer>
    );
};

export default AceleraPrivate;
