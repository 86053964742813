import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledCartaoMultiSolucoes = {
    Container: styled.div<{showDescription?: boolean}>`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 200px;
        height:  200px;
        padding-bottom: 10px;
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid ${Colors.Grey13};

   


      
    `,

    ContentImg: styled.div`
        width: 100%;
        height: 180px ;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;

     
    `,

    Img: styled.img<{ width?: string; height?: string }>`
       width: 50%;
       height: 50%;
    `,

    Title: styled.h1`
        color: ${Colors.Blue};
        font-weight: 500;
    `,

    Description: styled.p`
        font-size: 12px;
        line-height: 14px;
    `,
};

export default StyledCartaoMultiSolucoes;
