import { PrimaryButton } from 'components/UI/Buttons';
import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import { BsArrowDownSquareFill } from 'react-icons/bs';
import { IsCustomMaxWidth, IsCustomMinWidth, IsMobileBiggerWidth } from 'core/util/WindowSize';
import { fetchStorageFile } from 'hooks/StoreFiles';
import downloadButtonIcon from '../assets/downloadButton.png';
import { isUndefined } from 'lodash';

const Styled = {
    Container: styled.div<{ isBlue?: boolean }>`
        display: flex;
        background-color: ${({ isBlue }) => (isBlue ? Colors.Blue : Colors.White)};
        color: ${({ isBlue }) => (isBlue ? Colors.White : Colors.Blue)};
        justify-content: center;
    `,

    ContainerDescription: styled.div<{ isMobile: boolean }>`
        display: flex;
        width: ${({ isMobile }) => (isMobile ? '100%' : '70%')};
        flex-direction: column;
    `,

    ContentHeader: {
        Container: styled.div<{isMobile: boolean}>`
            display: flex;
            flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
            align-items: ${({ isMobile }) => (isMobile ? 'start' : 'center')};
            gap: 22px;
        `,

        Logo: styled.img`
            width: 100px;
            height: 90px;
            border-radius: 10px;
            padding: 8px;
            object-fit: contain;
            background-color: ${Colors.White};
        `,

        Column: styled.div`
            display: flex;
            flex-direction: column;
        `,
    },

    Column: styled.div<{ isMobile: boolean }>`
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
        margin-bottom: ${({ isMobile }) => (isMobile ? '15px' : '0px')};
        width: ${({ isMobile }) => (isMobile ? '100%' : '70%')};
    `,

    Content: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
        gap: 0.5rem;
        max-width: 1440px;
        padding: ${({ isMobile }) => (isMobile ? '32px 16px 32px 16px' : '57px 73px 57px 73px')};
        column-gap: 10px;
        align-items: center;
        justify-content: space-evenly;
    `,

    Img: styled.img<{ isMobile: boolean }>`
        width: 100%;
        max-width: 545px;
        border-radius: ${({ isMobile }) => (isMobile ? '0px' : '10px')};

        object-fit: cover;
    `,

    TextDestaque: styled.h2`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${Colors.SoftBlue};
        text-underline-position: 'from-font';
        text-decoration-skip-ink: none;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 34px;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: -0.019em;
        text-align: left;
        text-transform: uppercase;
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 0%;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 20px;
        max-width: 100%;

        ul {
            list-style-type: disc; /* ou 'circle', 'square', etc. */
            padding-left: 20px; /* Adiciona um recuo à lista */
        }

        li {
            margin-bottom: 10px; /* Adiciona espaçamento entre os itens da lista */
        }
    `,

    Button: styled.button<{ isMobile: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${Colors.White};
        color: ${Colors.Blue};
        width: ${({ isMobile }) => (isMobile ? '100%' : '414px')};
        height: 56px;
        border-radius: 4px;
        margin: 0;
        gap: 1rem;
        border: none;
        margin-top: 28px;
    `,
};

interface IOverviewExclusivo {
    isBlue?: boolean;
    img: string;
    logo: string;
    title: string;
    nome_arquivo: string;
    description: string;
    arquivo?: string;
    isButtonHidden?: boolean;
}

const OverviewExclusivo = ({ isBlue, logo, img, title, description, arquivo, nome_arquivo, isButtonHidden }: IOverviewExclusivo) => {
    const isMobile = IsMobileBiggerWidth();
    const imgStorage = fetchStorageFile(img);

    function sanitizeFileName(fileName: string): string {
        // Aqui, você pode adicionar uma lógica para remover caracteres não permitidos
        return fileName.replace(/[^\w\s\.\-]/gi, '');
    }

    // Mapeamento de tipos MIME para extensões de arquivo
    const mimeToExtension = (mime: string): string => {
        const mimeTypes: { [key: string]: string } = {
            'application/pdf': '.pdf',
            'application/vnd.ms-powerpoint': '.ppt',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
            'application/msword': '.doc',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
            'image/jpeg': '.jpg',
            'image/png': '.png',
            'image/gif': '.gif',
            'text/plain': '.txt',
            'application/zip': '.zip',
            'application/x-rar-compressed': '.rar',
            'application/json': '.json',
            // Adicione outros tipos conforme necessário
        };

        return mimeTypes[mime] || ''; // Retorna uma string vazia caso o tipo não tenha correspondência
    };

    const downloadMaterial = async () => {
        try {
            if (!arquivo) {
                console.error('Arquivo não disponível');
                return;
            }

            const fileUrl = fetchStorageFile(arquivo); // Supondo que fetchStorageFile seja uma função já definida
            const response = await fetch(fileUrl);
            if (!response.ok) throw new Error('Network response was not ok');

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const fileExtension = mimeToExtension(blob.type); // Obter a extensão do arquivo com base no tipo MIME
            const fileName = sanitizeFileName(nome_arquivo) + (fileExtension || '.bin'); // Adiciona .bin se não encontrar uma correspondência

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName; // Nome do arquivo com a extensão correta
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Limpa a URL criada
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    };

    return (
        <Styled.Container isBlue={isBlue}>
            <Styled.Content isMobile={isMobile}>
                <Styled.ContainerDescription isMobile={isMobile}>
                    <Styled.ContentHeader.Container isMobile={isMobile}>
                        <Styled.ContentHeader.Logo src={logo} />
                        <Styled.ContentHeader.Column>
                            <Styled.TextDestaque>Visão Geral sobre a solução</Styled.TextDestaque>
                            <Styled.Title>{title}</Styled.Title>
                        </Styled.ContentHeader.Column>
                    </Styled.ContentHeader.Container>

                    <Styled.Description
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                    {isMobile && !isButtonHidden && (
                        <Styled.Button onClick={downloadMaterial} isMobile={isMobile} disabled={isUndefined(arquivo)}>
                            <img src={downloadButtonIcon} width={24} height={27} /> {isUndefined(arquivo) ? "Arquivo não disponível" : "Baixe o documento de visão geral"}
                        </Styled.Button>
                    )}
                </Styled.ContainerDescription>
                <Styled.Column isMobile={isMobile}>
                    <Styled.Img src={imgStorage} isMobile={isMobile} />
                    {!isMobile && !isButtonHidden &&(
                        <Styled.Button onClick={downloadMaterial} isMobile={isMobile} disabled={isUndefined(arquivo)}>
                            <img src={downloadButtonIcon} width={24} height={27} /> {isUndefined(arquivo) ? "Arquivo não disponível" : "Baixe o documento de visão geral"}
                        </Styled.Button>
                    )}
                </Styled.Column>
            </Styled.Content>
        </Styled.Container>
    );
};

export default OverviewExclusivo;
