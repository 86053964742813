
export enum TagSolucoesEnum {
    CORRE_DE_FLUXO = "CORRE_DE_FLUXO",
    ALFABETIZACAO = "ALFABETIZACAO",
    RECUPERACAO_APRENDIZAGEM = "RECUPERACAO_APRENDIZAGEM",
    GESTAO_ESCOLAR = "GESTAO_ESCOLAR",
    COMPETENCIAS_SOCIEMOCIONAL = "COMPETENCIAS_SOCIEMOCIONAL",
    INTERESSES_PROFISSIONAIS = "INTERESSES_PROFISSIONAIS",
    GESTAO_POLITICA_EDUCACIONAL = "GESTAO_POLITICA_EDUCACIONAL",
    FORMACAO_LIDERANCA = "FORMACAO_LIDERANCA"
}

export const translateEnum = (tag: TagSolucoesEnum) => {
  switch (tag) {
    case TagSolucoesEnum.CORRE_DE_FLUXO: return "Correção de Fluxo"
    case TagSolucoesEnum.ALFABETIZACAO: return "Alfabetização"
    case TagSolucoesEnum.RECUPERACAO_APRENDIZAGEM: return "Recuperação de Aprendizagem"
    case TagSolucoesEnum.GESTAO_ESCOLAR: return "Gestão Escolar"
    case TagSolucoesEnum.COMPETENCIAS_SOCIEMOCIONAL: return "Correção de Fluxo"
    case TagSolucoesEnum.INTERESSES_PROFISSIONAIS: return "Correção de Fluxo"
    case TagSolucoesEnum.GESTAO_POLITICA_EDUCACIONAL: return "Gestão de Políticas Educacionais"
    case TagSolucoesEnum.FORMACAO_LIDERANCA: return "Formação de Lideranças"
  }
}

