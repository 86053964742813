import React, { useContext, useEffect } from 'react';
import Styled from './CirtuitoPrivateStyled';
import OverviewExclusivo from '../components/OverviewExclusivo';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import PartnersExclusivo from '../components/PartnersExclusivo';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';
import { ListMateriaisCircuitoPrivate } from './ListMateriasCircuitoPrivate';
import { IsMobileBiggerWidth } from 'core/util/WindowSize';
import circuitoLogo from './../../../../images/solutions/logo/circuitoLogo.png';
import CicloExclusivo from '../components/CicloExclusivo';

const CircuitoPrivate = () => {
    const { loading } = useContext(AcessoExclusivoContext.Context);
    const isMobile = IsMobileBiggerWidth();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Styled.Container isMobile={isMobile}>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        img={'ExclusivoCircuitoImagem1.JPG'}
                        logo={circuitoLogo}
                        title="CIRCUITO 360"
                        description={`
                        O Circuito 360 tem como objetivo subsidiar as secretarias de educação na elaboração e na gestão de sua política educacional
                        para os anos iniciais do Ensino Fundamental na perspectiva de educação integral.  Visa contribuir para:
                          <ol style="list-style: inside disc;">
                            <li>Instalação de política de educação integral para os anos iniciais do Ensino Fundamental.</li>
                            <li>Desenvolvimento da competência gestora dos profissionais responsáveis pelos anos iniciais nos âmbitos do ensino-aprendizagem, da rotina escolar e da política educacional.</li>
                            <li>Fortalecimento da função do coordenador pedagógico como formador da equipe de professores da unidade escolar.   </li>
                            <li>Constituição de uma equipe de professores alfabetizadores. </li>
                            <li>Incorporação da metodologia de resolução de problemas na prática docente com foco no cotidiano sociocultural do estudante.</li>
                          </ol>
                          Acesse o documento de visão geral para saber mais.
                `}
                        arquivo={'anexo_storage_files_3110028966101304179.pdf_2024-11-27-1732735029980.pdf'}
                        nome_arquivo="Circuito_360_Visao_Geral"
                    />
                    <CicloExclusivo
                        title="Trilha Formativa Circuito 360"
                        description={`
                          A trilha formativa do Circuito 360 apresenta os conteúdos da solução educacional de modo que os profissionais
                          (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                          Inicie agora a sua trilha formativa!
                        `}
                        img="ExclusivoCircuitoImagem2.jpg"
                        itens={[
                            {
                                title: 'Trilha Formativa Circuito 360',
                                url: '',
                                isBlock: true,
                                titleIsBlock: `Essa trilha formativa não está disponível.`,
                            },
                        ]}
                    />

                    <MateriaisExclusivos
                        title="ACESSE OS MATERIAIS DO CIRCUITO 360"
                        description={`Tenha acesso aos materiais exclusivos do Circuito 360, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={'anexo_storage_files_8557788060963491135.jpeg_2024-11-26-1732644557765.jpeg'}
                        items={ListMateriaisCircuitoPrivate}
                    />
                    <ContactContainer />
                </>
            )}
        </Styled.Container>
    );
};

export default CircuitoPrivate;
