import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import './loading.css';

export enum EnumTypeButtonFad {
    'NOT_BORDER_RADIUS' = 1,
    'HAVE_BORDER_RADIUS' = 2,
}

export const Styled_FAD_Button_NextInformation = styled.button<{block: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 146px;
    height: 52px;
    padding: 15px 40px 15px 40px;
    gap: 10px;
    opacity: 0px;
    border-radius: 5px;
    background-color: ${Colors.Blue};
    color: ${({disabled}) => disabled ? Colors.Grey3 : Colors.White};
    border: ${({disabled}) => disabled ? '1px solid' + Colors.Grey3 : "none"} ;
    font-weight: 700;
`;
export const Styled_FAD_Button_Blue = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 146px;
    height: 52px;
    padding: 15px 40px 15px 40px;
    gap: 10px;
    opacity: 0px;
    border-radius: 5px;
    background-color: ${Colors.Blue};
    color: ${Colors.White};
    border: none;
    font-weight: 700;
`;
export const Styled_FAD_Button_Blue2 = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 146px;
    height: 52px;
    padding: 15px 40px 15px 40px;
    gap: 10px;
    border-radius: 15px;
    opacity: 0px;
    background-color: ${Colors.Blue};
    color: ${Colors.White};
    border-radius: 37px;
    border: none;
    font-weight: 700;
`;

export const Styled_FAD_Button_Outline = styled.button<{ remove?: boolean; loading?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-width: 100px;
    height: 52px;
    border: 1px solid ${({ remove }) => (remove ? Colors.DeleteRed : Colors.Blue)};
    border-radius: 5px;
    color: ${({ remove }) => (remove ? Colors.DeleteRed : Colors.Blue)};
    transition: all 0.3s ease-in-out;
    background-color: ${({ loading, remove }) => (loading ? (remove ? Colors.Red : Colors.Blue) : Colors.White)};

    &:hover {
        background-color: ${({ remove }) => (remove ? Colors.DeleteRed : Colors.Blue)};
        color: ${Colors.White};

        .iconButtonFad {
            color: ${Colors.White} !important;
        }
    }
`;

interface IButton {
    onClick: () => void;
    text: string;
    loading?: boolean;
    type?: EnumTypeButtonFad;
    icon?: any;
    styles?: React.CSSProperties;
    remove?: boolean;
    disabled?: boolean;
}

export const FAD_Button_Primary = ({ text, onClick, type = EnumTypeButtonFad.NOT_BORDER_RADIUS, loading, icon, styles, disabled }: IButton) => {
    return (
        <>
            {type === EnumTypeButtonFad.HAVE_BORDER_RADIUS ? (
                <Styled_FAD_Button_Blue2
                    style={{ ...styles, backgroundColor: disabled ? Colors.Grey10 : '' }}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {!loading && icon}
                    {loading ? <div className="loaderFad" /> : text}
                </Styled_FAD_Button_Blue2>
            ) : (
                <Styled_FAD_Button_Blue
                    style={{ ...styles, backgroundColor: disabled ? Colors.Grey10 : '' }}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {!loading && icon}
                    {loading ? <div className="loaderFad" /> : text}
                </Styled_FAD_Button_Blue>
            )}
        </>
    );
};

export const FAD_Button_Outline = ({ text, onClick, loading, icon, styles, remove, disabled }: IButton) => {
    return (
        <Styled_FAD_Button_Outline style={{ ...styles }} onClick={onClick} remove={remove} loading={loading} disabled={disabled}>
            {' '}
            {!loading && icon} {loading ? <div className="loaderFad" /> : text}
        </Styled_FAD_Button_Outline>
    );
};

export const FAD_Button_NextInformation = ({
    text,
    onClick,
    type = EnumTypeButtonFad.NOT_BORDER_RADIUS,
    loading,
    icon,
    styles,
    disabled,
}: IButton) => {
    return (
        <>
            <Styled_FAD_Button_NextInformation style={{ ...styles, backgroundColor: disabled ? Colors.Grey11 : '' }} onClick={onClick} disabled={disabled} block={disabled ?? false}>
                {!loading && icon}
                {loading ? <div className="loaderFad" /> : text}
            </Styled_FAD_Button_NextInformation>
        </>
    );
};
