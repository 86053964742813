import { Input, Modal, Select, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Colors from 'enums/Colors';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import { FAD_Row1 } from 'pages/ferramentaAutoria/AdminFerramentaAutoria.styled';
import { TextDangerouslySetInnerHtml } from 'pages/recursos/recursos-conteudo/RecursosConteudoPage.styled';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        background-color: ${Colors.White};
    `,

    ColumnForm: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,

    LabelForm: styled.h3`
        //styleName: Base/Legenda 2;
        font-family: 'Barlow';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: ${Colors.Blue};
    `,
    LabelForm2: styled.h3`
        //styleName: Base/Legenda 2;
        //styleName: Base/Corpo;
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: #666666;
    `,
};

interface IModalFormCourse {
    open: boolean;
    loading: boolean;
    handleSave: () => void;
    handleEdit: () => void;
    handleHidden: () => void;
    setForm: (value: any) => void;
    form: FadCoursesModel;
    certificados: CertificacoesModel[];
    openEdit?: boolean;
}

const ModalFormCourse = ({ open, loading, form, certificados, openEdit, handleSave, handleEdit, handleHidden, setForm }: IModalFormCourse) => {
    const [inValid, setValid] = useState<boolean>(false);

    useEffect(() => {
        if (form.name.length > 2 && form.description.length > 2 && form.url.length > 2) {
           return setValid(true);
        }

        setValid(false)
    }, [form]);

    return (
        <Modal
            title={<h1 style={{ fontSize: 26 }}> {openEdit ? 'Editar curso' : 'Criar curso'}</h1>}
            open={open || openEdit}
            onOk={inValid && openEdit ? handleEdit : handleSave}
            okText={openEdit ? 'Salvar edição' : 'Criar curso'}
            cancelText={'Cancelar'}
            maskClosable={false}
            confirmLoading={loading}
            okButtonProps={{
                style: {
                    backgroundColor: inValid ? Colors.Blue : Colors.Grey10,
                },
                disabled: !inValid,
            }}
            onCancel={handleHidden}
        >
            <Styled.ColumnForm style={{ borderTop: '1px solid #F0F0F0', paddingTop: 22 }}>
                <Styled.LabelForm>Nome do curso</Styled.LabelForm>
                <Input
                    showCount
                    value={form.name}
                    maxLength={100}
                    onChange={e => {
                        setForm((prev: any) => ({ ...prev, name: e.target.value }));
                    }}
                    placeholder="Digite o nome do curso"
                    size="large"
                />
            </Styled.ColumnForm>
            <Styled.ColumnForm style={{ borderTop: '1px solid #F0F0F0', paddingTop: 22 }}>
                <Styled.LabelForm>Descrição do curso</Styled.LabelForm>
                <ReactQuill
                    value={form.description}
                    onChange={v => {
                        setForm((prev: any) => ({ ...prev, description: v }));
                    }}
                    placeholder="Digite a descrição do curso"
                    style={{
                        height: '140px',
                        overflow: 'scroll',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    }}
                />
            </Styled.ColumnForm>
            <Styled.ColumnForm style={{ paddingTop: 10 }}>
                <Styled.LabelForm>URL</Styled.LabelForm>
                <Input
                    addonBefore="/"
                    value={form.url.toLocaleLowerCase().replace(/\//g, '')}
                    showCount
                    maxLength={100}
                    onChange={e => {
                        setForm((prev: any) => ({ ...prev, url: e.target.value.replace(/\//g, '').trim() }));
                    }}
                    placeholder="Digite a url do curso"
                    size="large"
                />
            </Styled.ColumnForm>
            <Styled.ColumnForm style={{ paddingTop: 10 }}>
                <Styled.LabelForm>Tipo de redirecionamento</Styled.LabelForm>
                <Select
                    placeholder="Selecione"
                    value={form.typeRedirect}
                    onChange={value => {
                        setForm((prev: any) => ({ ...prev, typeRedirect: value }));
                    }}
                    options={[
                        { value: 'L', label: 'Login' },
                        { value: 'C', label: 'Cadastro' },
                        { value: 'I', label: 'Ignorar' },
                    ]}
                    size="large"
                />
            </Styled.ColumnForm>
            <Styled.ColumnForm style={{ paddingTop: 10 }}>
                <Styled.LabelForm>Tipo de certificação</Styled.LabelForm>
                <Select
                    placeholder="Selecione"
                    value={form.codigoCertificado}
                    onChange={value => {
                        setForm((prev: any) => ({ ...prev, codigoCertificado: value }));
                    }}
                    options={certificados.map(certificacao => ({
                        value: certificacao.codigo,
                        label: certificacao.nome,
                    }))}
                    size="large"
                />
            </Styled.ColumnForm>
            <FAD_Row1 style={{ marginTop: 10 }}>
                <Switch
                    value={form.sequentialContents}
                    onChange={c => {
                        setForm((prev: any) => ({ ...prev, sequentialContents: c }));
                    }}
                />
                <Styled.LabelForm2>Conteúdos sequências</Styled.LabelForm2>
            </FAD_Row1>
            <FAD_Row1 style={{ marginTop: 10 }}>
                <Switch
                    value={form.sequentialSteps}
                    onChange={c => {
                        setForm((prev: any) => ({ ...prev, sequentialSteps: c }));
                    }}
                />
                <Styled.LabelForm2>Etapas sequências</Styled.LabelForm2>
            </FAD_Row1>
            <FAD_Row1 style={{ marginTop: 10 }}>
                <Switch
                    value={form.finished}
                    onChange={c => {
                        setForm((prev: any) => ({ ...prev, finished: c }));
                    }}
                />
                <Styled.LabelForm2>O curso está completo</Styled.LabelForm2>
            </FAD_Row1>
        </Modal>
    );
};

export default ModalFormCourse;
