import { bucketS3, caminhoAnexosAuthoringTool, caminhoAnexosComponentsInteraction, caminhoAnexosGeneralFiles } from 'constants/EnvironmentData';

const getS3BaseUrlGeneralFiles = (): string => {
    return bucketS3() + caminhoAnexosGeneralFiles();
};

export const fetchStorageFile = (filepath: string) => {
    return getS3BaseUrlGeneralFiles() + filepath;
};

//ComponentInteraction
const getS3BaseUrlComponentInteraction = (): string => {
    return bucketS3() + caminhoAnexosComponentsInteraction();
};

export const fetchS3ComponentInteraction = (filepath: string) => {
    return getS3BaseUrlComponentInteraction() + filepath;
};

//Curso do FAD
const getS3BaseUrlAnexoAuthoringTools = (): string => {
    return bucketS3() + caminhoAnexosAuthoringTool();
};

export const fetchStorageFileAuthoringTools = (filepath: string) => {
    return getS3BaseUrlAnexoAuthoringTools() + filepath;
};

