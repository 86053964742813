import { Badge, Divider, Modal, Rate } from 'antd';
import Colors from 'enums/Colors';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import './Rater.css';
import TextArea from 'antd/es/input/TextArea';
import { FAD_Button_Outline, FAD_Button_Primary } from '../../../components/FadButtons';
import { FadCommentaryContentService } from 'core/http/service/ferramentaAutoria/FadCommentaryContent.service';
import { FerramentaAutoriaContext } from 'pages/ferramentaAutoria/context/FerramentaAutoriaContext';
import { FadCommentaryContentModel } from 'models/ferramentaAutoria/FadCommentaryContentModel';
import { MdArrowDropDown, MdArrowDropUp, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import moment from 'moment';
import { TbMessage } from 'react-icons/tb';
import { fetchPalavrasProibidas, validatePalavraProibida } from 'helpers/Functions';
import useFetch from 'hooks/useFetch';

const Styled = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fafafa;
        padding: 28px 40px;
        gap: 40px;

        @media screen and (max-width: 720px) {
            padding: 4px 0px;
        }
    `,

    Rater: {
        Container: styled.div`
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 16px;
            width: 328px;
            height: 128.78px;
            padding: 24px 0px 0px 0px;
            gap: 16px;
            border-radius: 16px 0px 0px 0px;
            opacity: 0px;
            background-color: ${Colors.White};
            box-shadow: 0px 2px 10px 0px #00000026;
            border-radius: 16px;
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};

            @media screen and (max-width: 600px){
                font-size: 16px;
            }
        `,
    },

    SpaceBetween: styled.div`
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (max-width: 600px) {
            gap: 6px;
        }
    `,

    Commentarys: {
        Container: styled.div`
            display: flex;
            flex-direction: column;
            background-color: ${Colors.White};
            padding: 24px;
            width: 100%;
            gap: 16px;
            border-radius: 24px;
            border: 1px solid #f0f0f0;
        `,

        Title: styled.h1`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};

            @media screen and (max-width: 600px) {
                font-size: 16px;
                margin-top: 30px;

            }
        `,

        Metrica: styled.h1`
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};
            cursor: pointer;
        `,

        Footer: styled.div`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8px;
        `,

        ListCommentarys: styled.div`
            display: flex;
            flex-direction: column;
            gap: 16px;
        `,
    },

    BodyComentary: styled.div<{ open: boolean }>`
        display: flex;
        flex-direction: column;
        transition: all 0.5s ease-in-out;
        z-index: 1;
        min-height: ${({ open }) => (open ? '200px' : 0)};

        opacity: ${({ open }) => (open ? 1 : 0)};
        pointer-events: ${({ open }) => (open ? 'auto' : 'none')}; /* Opcional, para evitar interação quando oculto */
    `,

    ContentCommentary: {
        Container: styled.div<{ open: boolean }>`
            display: ${({ open }) => (open ? 'flex' : 'none')};
            flex-direction: column;
            padding-bottom: 16px;
            border-bottom: 1px solid ${Colors.Grey10};
        `,

        Title: styled.h1`
            //styleName: Base/Subtitulo;
            font-family: 'Barlow';
            font-size: 18px;
            font-weight: 600;
            line-height: 25.2px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${Colors.Blue};
        `,

        DateCommentary: styled.p`
            //styleName: Base/Legenda;
            font-family: 'Barlow';
            font-size: 12px;
            font-weight: 400;
            line-height: 19.6px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        `,

        Commentary: styled.p`
            //styleName: Base/Corpo;
            font-family: 'Barlow';
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-bottom: 16px;
        `,

        ModalRespostas: {
            Container: styled.div`
                display: flex;
                flex-direction: column;
                gap: 16px;
            `,

            Column: styled.div`
                display: flex;
                flex-direction: column;
                gap: 4px;
            `,
        },
    },
};

interface IFadRaterCommentary {
    titleRate: string;
    setRate: (value: number) => void;
    rate: number;
}

const desc = ['Insatisfatório', 'Regular', 'Bom', 'Muito Bom', 'Excelente'];

interface ICommentarys {
    dados: FadCommentaryContentModel;
    open: boolean;
    palavrasProibidas: string[];
}

const Commentarys = ({ dados, open, palavrasProibidas }: ICommentarys) => {
    const { contentView, classSelected, setCommentaryContent, setRespostasCommentaryContent } = useContext(FerramentaAutoriaContext.Context);
    const [openModal, setOpenModal] = useState(false);
    const [isPalavrasProibidas, setIsPalavrasProibidas] = useState<boolean>(false);
    const [commentary, setCommentary] = useState<string>('');
    const serviceCommentary = new FadCommentaryContentService();

    const validatePalavras = () => {
        setIsPalavrasProibidas(validatePalavraProibida(palavrasProibidas ?? [''], commentary));
    };

    const handPublicCommentary = async () => {
        if (classSelected && contentView) {
            const body = new FadCommentaryContentModel().fromJSON({
                codigo: -1,
                codigoFerramentaAutoriaClasse: classSelected.codigo,
                codigoFerramentaAutoriaContent: contentView.codigo,
                codigoRelation: dados.codigo,
                nome: '',
                commentary: commentary,
                dateCreated: '',
                dateUpdated: '',
                myCommentary: true,
                listRespostas: [],
            });

            const { data, status } = await serviceCommentary.create(body);

            if (status === 201) {
                setRespostasCommentaryContent(dados.codigo, data);
                setCommentary('');
            }
        }
    };

    useEffect(() => {
        validatePalavras();
    }, [commentary]);

    return (
        <Styled.ContentCommentary.Container open={open}>
            <Styled.ContentCommentary.Title>{dados.nome}</Styled.ContentCommentary.Title>
            <Styled.ContentCommentary.DateCommentary>
                {moment(dados.dateUpdated).format('DD/MM/YYYY HH:mm:ss')}
            </Styled.ContentCommentary.DateCommentary>
            <Styled.ContentCommentary.Commentary>{dados.commentary}</Styled.ContentCommentary.Commentary>

            <FAD_Button_Outline
                text="Respostas"
                onClick={() => setOpenModal(true)}
                styles={{ height: 48, width: 200 }}
                icon={
                    <>
                        <Badge count={dados?.listRespostas?.length ?? 0} overflowCount={3} size="small">
                            <TbMessage size={24} className="iconButtonFad" color={Colors.Blue} />
                        </Badge>
                    </>
                }
            />
            <Modal title={''} centered open={openModal} footer={false} onCancel={() => setOpenModal(false)} width={'90%'}>
                <Styled.ContentCommentary.ModalRespostas.Container>
                    <Styled.ContentCommentary.ModalRespostas.Column>
                        <Styled.ContentCommentary.Title>{dados.nome}</Styled.ContentCommentary.Title>
                        <Styled.ContentCommentary.DateCommentary>
                            {moment(dados.dateUpdated).format('DD/MM/YYYY HH:mm:ss')}
                        </Styled.ContentCommentary.DateCommentary>
                        <Styled.ContentCommentary.Commentary>{dados.commentary}</Styled.ContentCommentary.Commentary>
                    </Styled.ContentCommentary.ModalRespostas.Column>
                    <Divider style={{ margin: 0 }} />
                    <div style={{ display: 'flex', gap: '16px', color: Colors.Blue }}>
                        <Badge count={dados.listRespostas && dados.listRespostas.length} overflowCount={3} size="small">
                            <TbMessage size={24} className="iconButtonFad" color={Colors.Blue} />
                        </Badge>
                        Respostas
                    </div>
                    {dados.listRespostas &&
                        dados.listRespostas.length > 0 &&
                        dados.listRespostas.map((r, i) => (
                            <>
                                <Styled.ContentCommentary.ModalRespostas.Column>
                                    <Styled.ContentCommentary.Title>{r.nome}</Styled.ContentCommentary.Title>
                                    <Styled.ContentCommentary.DateCommentary>
                                        {moment(r.dateUpdated).format('DD/MM/YYYY HH:mm:ss')}
                                    </Styled.ContentCommentary.DateCommentary>
                                    <Styled.ContentCommentary.Commentary>{r.commentary}</Styled.ContentCommentary.Commentary>
                                </Styled.ContentCommentary.ModalRespostas.Column>
                            </>
                        ))}
                    <Styled.Commentarys.Title>Deixe uma resposta a este comentário:</Styled.Commentarys.Title>
                    {isPalavrasProibidas && (
                        <p style={{ color: Colors.InfoRed, marginTop: '10px' }}>
                            Para publicar seu comentário, remova todas as palavras proibidas que estão presentes nele.
                        </p>
                    )}
                    <TextArea
                        rows={6}
                        showCount
                        maxLength={200}
                        value={commentary}
                        style={{ marginBottom: 22 }}
                        onChange={e => setCommentary(e.target.value)}
                    />
                    <FAD_Button_Primary disabled={isPalavrasProibidas} text="Publicar" onClick={handPublicCommentary} />
                </Styled.ContentCommentary.ModalRespostas.Container>
            </Modal>
        </Styled.ContentCommentary.Container>
    );
};

const FadRaterCommentary = ({ titleRate, setRate, rate }: IFadRaterCommentary) => {
    const { contentView, classSelected, setCommentaryContent, commentarysContent, loadingCommentary, handlePalavrasProibidas, palavrasProibidas } = useContext(
        FerramentaAutoriaContext.Context
    );
    const serviceCommentary = new FadCommentaryContentService();
    const [isPalavrasProibidas, setIsPalavrasProibidas] = useState<boolean>(false);

    const [commentary, setCommentary] = useState<string>('');
    const [openCommentary, setOpenCommentary] = useState<boolean>(false);

    const validatePalavras = () => {
        setIsPalavrasProibidas(validatePalavraProibida(palavrasProibidas ?? [''], commentary));
    };

    useFetch(async () => {
        if (contentView && contentView.activeCommentary && palavrasProibidas.length === 0) {
            handlePalavrasProibidas();
        }
    }, [contentView]);

    const handPublicCommentary = async () => {
        if (classSelected && contentView) {
            const body = new FadCommentaryContentModel().fromJSON({
                codigo: -1,
                codigoRelation: -1,
                codigoFerramentaAutoriaClasse: classSelected.codigo,
                codigoFerramentaAutoriaContent: contentView.codigo,
                nome: '',
                commentary: commentary,
                dateCreated: '',
                dateUpdated: '',
                myCommentary: true,
                listRespostas: [],
            });

            const { data, status } = await serviceCommentary.create(body);

            if (status === 201) {
                setCommentaryContent(data);
                setCommentary('');
            }
        }
    };

    useEffect(() => {
        validatePalavras();
    }, [commentary]);

    return (
        <Styled.Container>
            {contentView?.activeCommentary && (
                <Styled.Commentarys.Container>
                    <Styled.SpaceBetween>
                        {contentView.activeAvaliations && (
                            <Styled.Column>
                                <Styled.Rater.Title>{titleRate}</Styled.Rater.Title>
                                <Rate className="custom-rate" tooltips={desc} onChange={setRate} value={rate} />
                            </Styled.Column>
                        )}
                        <Styled.Commentarys.Title>Deixe seu comentário!</Styled.Commentarys.Title>
                    </Styled.SpaceBetween>
                    {isPalavrasProibidas && (
                        <p style={{ color: Colors.InfoRed, marginTop: '10px' }}>
                            Para publicar seu comentário, remova todas as palavras proibidas que estão presentes nele.
                        </p>
                    )}
                    <TextArea rows={6} showCount maxLength={200} value={commentary} onChange={e => setCommentary(e.target.value)} />
                    <Styled.Commentarys.Footer>
                        {loadingCommentary ? (
                            <div className="loaderCommmentarys"></div>
                        ) : (
                            <>
                                {commentarysContent.length > 0 && (
                                    <Styled.Commentarys.Metrica onClick={() => setOpenCommentary(!openCommentary)}>
                                        {openCommentary ? <MdKeyboardArrowUp size={18} /> : <MdKeyboardArrowDown size={18} />}{' '}
                                        {commentarysContent.length > 1
                                            ? `Ler todos os ${commentarysContent.length} comentários`
                                            : `Ler comentários`}
                                    </Styled.Commentarys.Metrica>
                                )}
                            </>
                        )}

                        <FAD_Button_Primary disabled={isPalavrasProibidas} text="Publicar" onClick={handPublicCommentary} />
                    </Styled.Commentarys.Footer>

                    <Styled.BodyComentary open={openCommentary}>
                        {commentarysContent.map((c, i) => (
                            <Commentarys key={i} dados={c} open={openCommentary} palavrasProibidas={palavrasProibidas ?? []} />
                        ))}
                    </Styled.BodyComentary>
                </Styled.Commentarys.Container>
            )}

            {!contentView?.activeCommentary && contentView?.activeAvaliations && (
                <>
                    <Styled.Column>
                        <Styled.Rater.Title>{titleRate}</Styled.Rater.Title>
                        <Rate className="custom-rate" tooltips={desc} onChange={setRate} value={rate} />
                    </Styled.Column>
                </>
            )}
        </Styled.Container>
    );
};

export default FadRaterCommentary;
