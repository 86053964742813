import React, { useEffect, useState } from 'react';
import { FerramentaAutoriaContext } from './FerramentaAutoriaContext';
import { useParams } from 'react-router-dom';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import useFetch from 'hooks/useFetch';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import { FadCoursesModel } from 'models/ferramentaAutoria/FadCoursesModel';
import { FadCoursesService } from 'core/http/service/ferramentaAutoria/FadCourses.service';
import { ToastSuccess } from 'components/Toaster';
import CategoriaPE from 'models/acessoExclusivo/CategoriaPE';
import { CategoriaPEService } from 'core/http/service/acessosExclusivos/CategoriaPE.service';
import { FadCoursesPublicModel } from 'models/ferramentaAutoria/public/FadCoursesPublicModel';
import { FadContentsPublicModel } from 'models/ferramentaAutoria/public/FadContentsPublicModel';
import { FadModulesPublicModel } from 'models/ferramentaAutoria/public/FadModulesPublicModel';
import { FadClassesService } from 'core/http/service/ferramentaAutoria/FadClasses.service';
import { FadUserClassesService } from 'core/http/service/ferramentaAutoria/FadUserClasses.service';
import { FadClassesModel } from 'models/ferramentaAutoria/FadClassesModel';
import moment from 'moment';
import { FadLogUserCoursesModel } from 'models/ferramentaAutoria/FadLogUserCoursesModel';
import { FadLogUserCoursesService } from 'core/http/service/ferramentaAutoria/FadLogUserCourses.service';
import { FadLogUserModulesService } from 'core/http/service/ferramentaAutoria/FadLogUserModules.service';
import { FadLogUserModulesModel } from 'models/ferramentaAutoria/FadLogUserModules';
import { FadUserAvaliationsContentsService } from 'core/http/service/ferramentaAutoria/FadUserAvaliationsContents.service';
import { FadUserAvaliationsContentsModel } from 'models/ferramentaAutoria/FadAvaliationsContentModel';
import { FadCommentaryContentModel } from 'models/ferramentaAutoria/FadCommentaryContentModel';
import { FadCommentaryContentService } from 'core/http/service/ferramentaAutoria/FadCommentaryContent.service';
import { UsuariosCertificadosService } from 'core/http/service/UsuariosCertificados.service';
import { notification } from 'antd';
import { FadCommentaryContentPrivateModel } from 'models/ferramentaAutoria/FadCommentaryContentPrivateModel';
import { fetchPalavrasProibidas } from 'helpers/Functions';
import { FadContentsService } from 'core/http/service/ferramentaAutoria/FadContents.service';

export interface IFerramentaAutoriaProvider {}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const FerramentaAutoriaProvider = ({ children }: React.PropsWithChildren<IFerramentaAutoriaProvider>) => {
    // Defina aqui os estados e efeitos que você deseja usar no provider
    const [loading, setLoading] = useState(false);
    const [loadingCommentary, setLoadingCommentary] = useState(false);
    const [certificados, setCertificados] = useState<CertificacoesModel[]>([]);
    const [courseModel, setCourseModel] = useState<FadCoursesModel | null>(null);
    const [categoriasExclusivas, setCategoriasExclusivas] = useState<CategoriaPE[]>([]);
    const [inscrito, setInscrito] = useState<boolean>(false);
    const [classeSelected, setClasseSelected] = useState<FadClassesModel | null>(null);
    const [rateContent, setRateContent] = useState<FadUserAvaliationsContentsModel | null>(null);
    const [commentarysContent, setCommentaryContents] = useState<FadCommentaryContentModel[]>([]);
    const [emissaoRealizada, setEmissaoRealizada] = useState<boolean>(false);

    const [course, setCourse] = useState<string>('');

    const [palavrasProibidas, setPalavrasProibidas] = useState<string[]>([]);

    const [commentarys, setCommentary] = useState<FadCommentaryContentPrivateModel[]>([]);

    const [api, contextHolder] = notification.useNotification();

    //Curso encerrado para acesso ou concluir qualquer coisa relacionada.
    const [courseClose, setCourseClose] = useState<boolean>(false);

    //Conteúdo selecionado para exebir para o usuário na página de conteúdos quando acessar o curso.
    const [contentView, setContentView] = useState<FadContentsPublicModel | null>(null);
    const [moduleView, setModuleView] = useState<FadModulesPublicModel | null>(null);

    //Scrollview ir até algum componente expecifico
    const [scrollViewContent, setScrollViewContent] = useState<boolean>(true);

    //Course
    const [coursePublic, setCoursePublic] = useState<FadCoursesPublicModel>(new FadCoursesPublicModel());

    //logs
    const [registerLogCourse, setRegisterLogCourse] = useState<boolean>(false);
    const [verifyEmitedUser, setVerifyEmitedUser] = useState<boolean>(false);

    //services
    const serviceCertificados = new CertificacoesService();
    const serviceCourses = new FadCoursesService();
    const serviceCategoriasPE = new CategoriaPEService();
    const serviceUserClasse = new FadUserClassesService();
    const serviceLogUserCourse = new FadLogUserCoursesService();
    const serviceLogUserModule = new FadLogUserModulesService();
    const serviceAvaliationContent = new FadUserAvaliationsContentsService();
    const serviceCommentaryContent = new FadCommentaryContentService();
    const usuarioCertificadoService = new UsuariosCertificadosService();
    const serviceFadContents = new FadContentsService();
    const serviceCourse = new FadCoursesService();

    const handleUpdateCourse = async (form: FadCoursesModel) => {
        const novoForm = form;
        novoForm.url = '/' + novoForm.url.replace(/\//g, '');
        try {
            // Chamada ao serviço de atualização
            const { data, status } = await serviceCourses.update(form.codigo, novoForm);

            // Verificação do status da resposta
            if (status === 200) {
                ToastSuccess('Curso atualizado com sucesso!');
                setCourseModel(data);
                return Promise.resolve(data); // Retorna a Promise resolvida com os dados
            } else {
                return Promise.reject(new Error(`Erro ao atualizar curso: Status ${status}`)); // Rejeita a Promise em caso de erro
            }
        } catch (error) {
            // Lida com possíveis erros durante a chamada
            return Promise.reject(error); // Rejeita a Promise com o erro capturado
        }
    };

    const openNotificationWithIcon = (type: NotificationType, title: string, desc: string) => {
        api[type]({
            message: title,
            description: desc,
        });
    };

    const handleUpdateRateContent = async (value: number) => {
        if (contentView && classeSelected) {
            const body = rateContent ? rateContent : new FadUserAvaliationsContentsModel();
            body.codigoFerramentaAutoriaContent = contentView.codigo;
            body.codigoFerramentaAutoriaClasse = classeSelected.codigo;
            body.rate = value;

            const { data, status } = await serviceAvaliationContent.saveOrUpdate(body);

            if (status === 201) {
                setRateContent(data);
            }
        }
    };

    const _isDateInPast = (date: string): boolean => {
        const now = moment(); // Data atual
        return moment(date).isBefore(now); // Retorna true se a data fornecida for anterior à atual
    };

    const _setCoursePublic = (course: FadCoursesPublicModel) => {
        setCoursePublic(course);
    };

    const _setContentView = (content: FadContentsPublicModel) => {
        setContentView(content);
    };

    const _setModuleView = (module: FadModulesPublicModel) => {
        setModuleView(module);
    };

    const _setScrollViewContent = (scroll: boolean) => {
        setScrollViewContent(scroll);
    };

    const _setClasseSelected = (classe: FadClassesModel) => {
        setClasseSelected(classe);
    };
    const _setInscricao = (value: boolean) => {
        setInscrito(value);
    };
    const _setCourseClose = (value: boolean) => {
        setCourseClose(value);
    };

    const _setCommentaryContents = (value: FadCommentaryContentModel) => {
        setCommentaryContents((prev: FadCommentaryContentModel[]) => [...prev, value]);
    };
    const _setCommentary = (value: FadCommentaryContentPrivateModel[]) => {
        setCommentary(value);
    };

    const _setPalavrasProibidas = async () => {
        setPalavrasProibidas(await fetchPalavrasProibidas());
    };

    useFetch(async () => {
        if (course.length === 0) {
            return;
        }

        setLoading(true);
        if (coursePublic.course.url === '/' + course) {
            return;
        }

        const { data, status } = await serviceCourse.findByUrlPublic(course);
        if (status === 200) {
            setCoursePublic(data);
        }

        setLoading(false);
    }, [course]);

    const _setRespostaCommentaryContents = (codigoCommentary: number, value: FadCommentaryContentModel) => {
        setCommentaryContents((prev: any) => {
            // Localiza o comentário pelo código
            const updatedComments = prev.map((commentary: FadCommentaryContentModel) => {
                if (commentary.codigo === codigoCommentary) {
                    // Garante que listRespostas é um array antes de adicionar o valor
                    const respostas = Array.isArray(commentary.listRespostas) ? commentary.listRespostas : [];
                    return {
                        ...commentary,
                        listRespostas: [...respostas, value],
                    };
                }
                return commentary; // Retorna os comentários inalterados
            });

            return updatedComments;
        });
    };

    const handleClickCertificado = async () => {
        if (emissaoRealizada) {
            return window.open(coursePublic.certificacao.link?.toString());
        }

        const response = await usuarioCertificadoService.associarUser(coursePublic.certificacao.codigo ?? -1);

        if (coursePublic.certificacao.precisaSolicitar) {
            if (response.status === 201) {
                openNotificationWithIcon('success', 'Certificado solicitado com sucesso!', '');
            }
        } else {
            window.open(coursePublic.certificacao.link?.toString());
        }

        setEmissaoRealizada(true);
    };

    const fetchContentsByModules = async (idModule: number, idContent?: number) => {
        if (coursePublic && classeSelected) {
            const existeComponents = coursePublic.modulos.find(m => m.codigo === idModule)?.conteudos.every(c => c.componentes);

            if (!existeComponents) {
                const { data, status } = await serviceFadContents.findContentsByModule(idModule, classeSelected.codigo);

                // Crie uma cópia do curso
                const course = { ...coursePublic } as FadCoursesPublicModel;

                // Iterar pelos módulos
                course.modulos = course.modulos.map(m => {
                    // Se o módulo corresponde ao id do módulo, atualiza seus conteúdos
                    if (m.codigo === idModule) {
                        m.conteudos = m.conteudos.map(c => {
                            // Aqui você pode comparar os conteúdos ou fazer uma atualização
                            // Se precisar, pode também fazer uma busca dentro de 'data' para encontrar o conteúdo correspondente e atualizar
                            const updatedContent = data.find((d: FadContentsPublicModel) => d.codigo === c.codigo);
                            if (updatedContent) {
                                return { ...c, ...updatedContent }; // Atualiza o conteúdo com os dados recebidos
                            }
                            return c; // Caso não encontre, retorna o conteúdo original
                        });
                    }
                    return m; // Retorna o módulo sem alteração, se não for o que estamos procurando
                });

                if (idContent) {
                    const selectedContent = course.modulos
                        .find(m => m.codigo === idModule)
                        ?.conteudos.find(c => c.codigo === idContent) as FadContentsPublicModel;

                    setContentView(selectedContent);
                }

                // Agora você pode usar a variável 'course' atualizada como precisar
                setCoursePublic(course);
            }
        }
    };

    useFetch(async () => {
        if (contentView && classeSelected && contentView.activeAvaliations) {
            // Finaliza o método de retornar a avaliação de acordo com a classe e o conteúdo.
            const { data, status } = await serviceAvaliationContent.findByCodigoFerramentaAutoriaContent(
                contentView.codigo,
                classeSelected.codigo
            );

            if (status === 200 && data !== '') {
                setRateContent(data);
            } else {
                setRateContent(null);
            }
        }
    }, [contentView, classeSelected]);

    useFetch(async () => {
        if (contentView && contentView.activeCommentary) {
            setLoadingCommentary(true);
            // Finaliza o método de retornar a avaliação de acordo com a classe e o conteúdo.
            const { data, status } = await serviceCommentaryContent.findByFerramentaAutoriaContent(contentView.codigo);

            if (status === 200 && data !== '') {
                setCommentaryContents(data);
            } else {
                setCommentaryContents([]);
            }

            setLoadingCommentary(false);
        }
    }, [contentView]);

    useFetch(async () => {
        if (moduleView != null) {
            const body = new FadLogUserModulesModel().fromJSON({
                codigo: -1,
                codigoFerramentaAutoriaModule: moduleView.codigo,
                codigoPerfilUsuario: '',
                dateCreated: '',
                dateUpdate: '',
            });

            const { status } = await serviceLogUserModule.create(body);

            if (status == 201) {
                console.log('Log Module - usuario efetuado com sucesso!');
            }
        }
    }, [moduleView]);

    useFetch(async () => {
        if (coursePublic.course != null && !registerLogCourse) {
            const body = new FadLogUserCoursesModel().fromJSON({
                codigo: -1,
                codigoFerramentaAutoriaCourse: coursePublic.course.codigo,
                codigoPerfilUsuario: '',
                dateCreated: '',
                dateUpdate: '',
            });

            const { status } = await serviceLogUserCourse.create(body);

            if (status == 201) {
                console.log('Log usuario efetuado com sucesso!');
                setRegisterLogCourse(true);
            }
        }
    }, [coursePublic]);

    useFetch(async () => {
        if (coursePublic && !inscrito && coursePublic.course.codigo != -1) {
            const { data, status } = await serviceUserClasse.findByInscrito(coursePublic.course.codigo);

            if (status === 200) {
                setClasseSelected(data);
                setInscrito(true);
            }
        }
    }, [coursePublic, inscrito]);

    useFetch(async () => {
        const { data, status } = await serviceCertificados.listarAll();

        if (status === 200) {
            const listCertificado = [{ codigo: -1, nome: 'Nenhum' }, ...data];
            setCertificados(listCertificado);
        }
    }, []);

    useFetch(async () => {
        const { data, status } = await serviceCategoriasPE.listAll();

        if (status === 200) {
            const listCategorias = [{ id: -1, nome: 'Nenhum' }, ...data];
            setCategoriasExclusivas(listCategorias);
        }
    }, []);

    useFetch(async () => {
        if (
            coursePublic.certificacao &&
            coursePublic.certificacao.codigo !== -1 &&
            coursePublic.certificacao.codigo !== null &&
            !verifyEmitedUser
        ) {
            const response = await usuarioCertificadoService.verificarEmissao(coursePublic.certificacao.codigo);
            setEmissaoRealizada(response.data);
            setVerifyEmitedUser(true);
        }
    }, [coursePublic]);

    useEffect(() => {
        if (classeSelected && classeSelected.closingDate) {
            if (_isDateInPast(classeSelected.closingDate.toString())) {
                setCourseClose(true);
            }
        }
    }, [classeSelected]);

    const context: FerramentaAutoriaContext.IContext = {
        // Passe aqui as variáveis e funções que você definiu no contexto
        loading: loading,
        certificados: certificados,
        updateCourse: handleUpdateCourse,
        courseModel: courseModel,
        categoriasExclusivas: categoriasExclusivas,
        setCoursePublic: _setCoursePublic,
        coursePublic: coursePublic,
        contentView: contentView,
        setContentView: _setContentView,
        moduleView: moduleView,
        setModuleView: _setModuleView,
        setScrollViewContent: _setScrollViewContent,
        scrollViewContent: scrollViewContent,
        inscrito: inscrito,
        setInscricao: _setInscricao,
        setClassSelected: _setClasseSelected,
        classSelected: classeSelected,
        isCourseCloseDate: _isDateInPast,
        setCourseClose: _setCourseClose,
        courseClose: courseClose,
        avaliationContent: rateContent,
        handleAvaliationContent: handleUpdateRateContent,
        commentarysContent: commentarysContent,
        setCommentaryContent: _setCommentaryContents,
        setRespostasCommentaryContent: _setRespostaCommentaryContents,
        emissaoRealizada: emissaoRealizada,
        handleClickCertificado: handleClickCertificado,
        commentarys: commentarys,
        setCommentary: _setCommentary,
        loadingCommentary: loadingCommentary,
        palavrasProibidas: palavrasProibidas,
        handlePalavrasProibidas: _setPalavrasProibidas,
        attComponentsByModules: fetchContentsByModules,
        setCourse: setCourse,
    };

    return <FerramentaAutoriaContext.Context.Provider value={context}>{children}</FerramentaAutoriaContext.Context.Provider>;
};

export default FerramentaAutoriaProvider;
