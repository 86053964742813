import { jsonProperty, Serializable, SerializationSettings } from 'ts-serializable';

interface IFadAvaliationsComponentModel {
    codigo: number;
    codigoComponentInteraction: number;
    codigoFerramentaAutoriaClasse: number;
    codigoQuizAlternativa: number | null;
    codigoQuizPontuacaoAlternativa: number | null;
    codigoCorrespondentOption: number | null;
    codigoReguaAvaliativaOption: number | null;
}

export class FadAvaliationsComponentModel extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoComponentInteraction: number = -1;

    @jsonProperty(Number)
    codigoFerramentaAutoriaClasse: number = -1;

    @jsonProperty(Number)
    codigoQuizAlternativa: number | null = null;

    @jsonProperty(Number)
    codigoQuizPontuacaoAlternativa: number | null = null;

    @jsonProperty(Number)
    codigoCorrespondentOption: number | null = null;

    @jsonProperty(Number)
    codigoReguaAvaliativaOption: number | null = null;



    fromJSON(json: IFadAvaliationsComponentModel, settings?: Partial<SerializationSettings>): this {
        this.codigo = json.codigo;
        this.codigoComponentInteraction = json.codigoComponentInteraction;
        this.codigoFerramentaAutoriaClasse = json.codigoFerramentaAutoriaClasse;
        this.codigoQuizAlternativa = json.codigoQuizAlternativa;
        this.codigoQuizPontuacaoAlternativa = json.codigoQuizPontuacaoAlternativa;
        this.codigoCorrespondentOption = json.codigoCorrespondentOption;
        this.codigoReguaAvaliativaOption = json.codigoReguaAvaliativaOption;
        return this;
    }
}
