import Colors from 'enums/Colors';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const StyleAcessoExclusivo = {
    Container: styled.div`
        margin-left: 100px;
        padding-left: 40px;
    `,

    H1: styled.h1`
        font-size: 26px;
        color: ${Colors.Blue};
        font-weight: 600;
    `,

    Content: styled.div`
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        margin-top: 10px;
    `,

    LabelForm: styled.label`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        margin-bottom: 5px;
        margin-top: 20px;
    `,

    InputFormSelect: styled(Form.Control)`
        height: 50px;
        border: 1px solid #ced4da;
        border-radius: 5px;

        &:focus {
            outline: none;
        }
    `,

    Button: styled.button`
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 0.2rem;
        background-color: ${Colors.LightGreen};
        color: ${Colors.White};
        border-radius: 10px;
        padding: 7px;
        border: none;
        transition: 0.2s all;

        &:hover {
            box-shadow: 2px 2px 6px ${Colors.Grey3};
        }
    `,

    ButtonFilterReset: styled.button`
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: 2px;
        gap: 0.5rem;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        background-color: ${Colors.White};

        &:hover {
            border: 1px solid #4096ff;
        }
    `,

    GlobalContainer: styled.div<{ isMobile: boolean }>`
        display: flex;
        flex-direction: column;
        margin-top: ${({ isMobile }) => (isMobile ? '60px' : '85px')};
    `,
};

export default StyleAcessoExclusivo;
